const webUrl = 'https://api.projectloop.com/';
const baseUrl = 'https://api.projectloop.com/api/';
const ngrok = 'https://api.projectloop.com/';

module.exports = {
  TOKEN: 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxfQ.SsVEk90qOIl79fezXTs-XsMPNI9PlMfM8_G9cHImHj0',
  COMPANY_PROJECTS_API: `${baseUrl}projects`,
  COMPANY_LIST: `${baseUrl}companies`,
  COMPANY_PROJECTS_DETAILS: `${baseUrl}projects`,
  LOAD_PROJECT: `${baseUrl}drawings/load-annotations`,
  PLAN_LIST: `${baseUrl}projects`,
  DRAWING_VERSION: `${baseUrl}drawing_versions`,
  LOGIN: `${baseUrl}auth/login`,
  TASK_LIST: `${baseUrl}drawing_versions`,
  BASE_URL: `${baseUrl}`,
  WEB_URL: `${webUrl}`,
  NGROK: ngrok
};
