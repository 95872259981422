/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ACTIONS from '../../actions';
import { API_SERVICE } from '../../services/CommonApi';
import services from '../../services/ProjectDoucment';
import { MOVE_FOLDER } from '../../actions';

const initialState = {
  documentList: { folders: [], documents: [], sub_folders: [] },
  successMessage: '',
  folderData: {},
  pageType: 'companies',
  currentFolderValue: {
    id: 0,
    name: '',
    parent_id: 0,
    parent_type: 'Folder'
  }
};

export const getDocumentList = createAsyncThunk(
  ACTIONS.GET_DOCUMENT_LIST,
  async (arg, thunkAPI) => {
    try {
      const { pageType } = thunkAPI.getState().root.projectDocument;
      const module = arg?.module || pageType;
      const baseObj = {
        page: arg?.filterState?.page,
        per_page: arg?.filterState?.per_page,
        filters: arg?.filterData
      };
      return API_SERVICE.get(`${services.getDocumentList(arg.commonId, module)}`, { ...baseObj });
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const showFolderList = createAsyncThunk(ACTIONS.SHOW_FOLDER, async (arg, thunkAPI) => {
  try {
    const { pageType } = thunkAPI.getState().root.projectDocument;
    const module = arg?.module || pageType;
    const baseObj = {
      page: arg?.filterState?.page,
      per_page: arg?.filterState?.per_page,
      filters: arg?.filterData
    };
    return API_SERVICE.get(`${services.showFolder(arg.commonId, arg.id, module)}`, { ...baseObj });
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const MoveDocument = createAsyncThunk(ACTIONS.MOVE_FOLDER, async (arg, thunkAPI) => {
  try {
    const { pageType } = thunkAPI.getState().root.projectDocument;
    const module = arg?.module || pageType;
    return API_SERVICE.put(`${services.moveDocument(arg.commonId, arg.id, module)}`, {});
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const createFolder = createAsyncThunk(ACTIONS.CREATE_FOLDER, async (arg, thunkAPI) => {
  try {
    const { pageType } = thunkAPI.getState().root.projectDocument;
    const module = arg?.module || pageType;
    return API_SERVICE.post(`${services.createFolder(arg.commonId, module)}`, arg.payload);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const updateFolder = createAsyncThunk(ACTIONS.UPDATE_FOLDER, async (arg, thunkAPI) => {
  try {
    const { folderData, pageType } = thunkAPI.getState().root.projectDocument;
    const module = arg?.module || pageType;
    return API_SERVICE.put(
      `${services.createFolder(arg.commonId, module)}/${folderData.id}`,
      arg.payload
    );
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const deleteFolder = createAsyncThunk(ACTIONS.DELETE_FOLDER, async (arg, thunkAPI) => {
  try {
    const { folderData, pageType } = thunkAPI.getState().root.projectDocument;
    const module = arg?.module || pageType;
    const url = folderData?.document_type
      ? `${services.uploadDocument(arg.commonId, module)}/${folderData.id}`
      : `${services.createFolder(arg.commonId, module)}/${folderData.id}`;
    return API_SERVICE.delete(url, {});
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const uploadDocument = createAsyncThunk(ACTIONS.UPLOAD_DOCUMENT, async (arg, thunkAPI) => {
  try {
    const { pageType } = thunkAPI.getState().root.projectDocument;
    const module = arg?.module || pageType;
    return API_SERVICE.post(`${services.uploadDocument(arg.commonId, module)}`, arg.formData, true);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const uploadDirectory = createAsyncThunk(ACTIONS.UPLOAD_DIRECTORY, async (arg, thunkAPI) => {
  try {
    const { pageType } = thunkAPI.getState().root.projectDocument;
    const module = arg?.module || pageType;
    return API_SERVICE.post(
      `${services.uploadDirectory(arg.commonId, module)}`,
      arg.formData,
      true
    );
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const projectDocumentSlice = createSlice({
  name: 'projectDocument',
  initialState,
  reducers: {
    resetDocumentStatus: (state, { payload }) => {
      state.successMessage = '';
      state.folderData = {};
    },
    setFolderData: (state, { payload }) => {
      state.folderData = payload;
    },
    currentFolderData: (state, { payload }) => {
      state.currentFolderValue = payload;
    },
    setType: (state, { payload }) => {
      state.pageType = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDocumentList.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.documentList = payload.data;
      }
    });
    builder.addCase(showFolderList.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.documentList = {
          folders: payload.data.sub_folders || [],
          documents: payload.data.documents || [],
          ...payload.data
        };
      }
    });
    builder.addCase(createFolder.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201)
        state.successMessage = 'Folder Created Successfully';
    });
    builder.addCase(updateFolder.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201)
        state.successMessage = 'Folder Updated Successfully';
    });
    builder.addCase(deleteFolder.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201)
        state.successMessage = 'Folder Deleted Successfully';
    });
    builder.addCase(uploadDocument.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201)
        state.successMessage = 'Files Uploaded Successfully';
    });
    builder.addCase(uploadDirectory.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201)
        state.successMessage = 'Directory Uploaded Successfully';
    });
  }
});
export const { resetDocumentStatus, setFolderData, currentFolderData, setType } =
  projectDocumentSlice.actions;
export default projectDocumentSlice.reducer;
