/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { Modal, Input, Form } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useModifyExternalFolder } from '../../../services/ProjectDoucment/hooks';
import showNotification from '../../../components/ShowNotification';
import { DocIcons } from '../DocumentIcons';

const DocumentModel = styled.div`
  .ant-form-item-label {
    padding-bottom: 4px;

    label {
      color: #909090;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

function CreateEditFolder({
  isUpdateFolder,
  setUpdateFolder,
  parentId,
  refetch,
  initialValues = null
}) {
  const isEdit = !!initialValues;
  const { id } = useParams();
  const [form] = Form.useForm();
  const { mutate, isLoading } = useModifyExternalFolder(id, parentId);
  console.log(parentId);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        name: initialValues.name
      });
    }
  }, [initialValues]);
  const handleCancel = () => {
    form.resetFields();
    setUpdateFolder(false);
  };
  const onFinish = (values) => {
    const payload = {
      folder: {
        name: values?.name
      }
    };
    if (!isEdit) {
      payload.parent_id = parentId;
    }
    mutate(
      { payload: payload, id: initialValues?.id, action: isEdit ? 'update' : 'create' },
      {
        // eslint-disable-next-line consistent-return
        onSuccess: (res) => {
          if (res.status === 200 || res.status === 201) {
            return Promise.all([
              showNotification({
                message: `Record ${isEdit ? 'Updated' : 'Created'} Successfully`
              }),
              setUpdateFolder(false),
              form.resetFields(),
              refetch()
            ]);
          }
        }
      }
    );
  };
  return (
    <>
      <Modal
        title={
          <div className="d-flex align-items-center">
            {DocIcons[isEdit ? 'edit' : 'plus']}
            <span
              className="font-weight-700"
              style={{ margin: '0 5px', fontSize: '20px', color: '#080808' }}>{`${
              isEdit ? 'Rename' : 'Create'
            } Folder`}</span>
          </div>
        }
        open={isUpdateFolder}
        onCancel={handleCancel}
        okText={isEdit ? 'Save' : 'Create'}
        cancelText="Close"
        cancelButtonProps={{
          className: 'cancel-btn'
        }}
        confirmLoading={isLoading}
        okButtonProps={{
          disabled: isLoading,
          loading: isLoading,
          className: 'ant-btn btn-primary'
        }}
        onOk={form.submit}>
        <DocumentModel>
          <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
            <Form.Item
              name="name"
              label="Folder Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter folder name'
                },
                {
                  max: 200,
                  message: 'Value should be less than 200 character'
                }
              ]}>
              <Input placeholder="Enter folder name" />
            </Form.Item>
          </Form>
        </DocumentModel>
      </Modal>
    </>
  );
}

export default CreateEditFolder;
