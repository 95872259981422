import Cookies from 'js-cookie';
import client from '../client';

const getRFIs = (projectId) => () =>
  client.get(`/projects/${projectId}/rfis`, {
    headers: { Authorization: `Bearer ${Cookies.get('auth-token')}` }
  });
const getDraftRFIs = (projectId) => () =>
  client.get(`/projects/${projectId}/rfis?draft=true`, {
    headers: { Authorization: `Bearer ${Cookies.get('auth-token')}` }
  });
const getRFIFilters = (projectId) => () =>
  client.get(`/projects/${projectId}/rfis/filters`, {
    headers: { Authorization: `Bearer ${Cookies.get('auth-token')}` }
  });
const getRFIMeta = (projectId) => () =>
  client.get(`/projects/${projectId}/rfis/filters?create_rfi=true`, {
    headers: { Authorization: `Bearer ${Cookies.get('auth-token')}` }
  });
const createRFI = (projectId, bodyFormData) =>
  client({
    method: `post`,
    url: `/projects/${projectId}/rfis`,
    data: bodyFormData,
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${Cookies.get('auth-token')}`
    }
  });
const editRFI = (projectId, rfiId, bodyFormData) =>
  client({
    method: `put`,
    url: `/projects/${projectId}/rfis/${rfiId}`,
    data: bodyFormData,
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${Cookies.get('auth-token')}`
    }
  });
const createRFIs = (projectId) => `projects/${projectId}/rfis`;
const updateRFIs = (projectId, rfiId) => `projects/${projectId}/rfis/${rfiId}`;
const viewRFIs = (projectId) => `/projects/${projectId}/rfis`;
const markAsOfficial = (projectId, rfiId, id) =>
  `/projects/${projectId}/rfis/${rfiId}/rfi_responses/${id}/mark_as_official`;
const addResponse = (projectId, rfiId) => `/projects/${projectId}/rfis/${rfiId}/add_response`;
const forwardReview = (projectId, rfiId) => `/projects/${projectId}/rfis/${rfiId}/forward_review`;
const markAsClosed = (projectId, rfiId) => `/projects/${projectId}/rfis/${rfiId}/mark_as_closed`;
const markAsDeleted = (projectId, rfiId) => `/projects/${projectId}/rfis/${rfiId}/mark_as_deleted`;
const markAsOpen = (projectId, rfiId) => `/projects/${projectId}/rfis/${rfiId}/mark_as_open`;
const markAsRejected = (projectId, rfiId) =>
  `/projects/${projectId}/rfis/${rfiId}/mark_as_rejected`;
const restoreDeleted = (projectId, rfiId) =>
  `/projects/${projectId}/rfis/${rfiId}/retrieve_from_recycle_bin`;
const reopenRFI = (projectId, rfiId) => `/projects/${projectId}/rfis/${rfiId}/reopen_rfi`;
const requestReopenRFI = (projectId, rfiId) =>
  `/projects/${projectId}/rfis/${rfiId}/request_for_reopen`;
const getRFIVIEWFilters = (projectId) => `/projects/${projectId}/rfis/filters`;
const addAssignee = (projectId, rfiId) => `/projects/${projectId}/rfis/${rfiId}/add_assignees`;
const updateResponse = (projectId, rfiId) => `projects/${projectId}/rfi_responses/${rfiId}`;
const deleteResponse = (projectId, responseId, rfiId) =>
  `projects/${projectId}/rfi_responses/${responseId}?rfi_id=${rfiId}`;
const getSettings = (projectId, id) => `projects/${projectId}/rfi_settings/${id}`;
const updateSettings = (projectId, id) => `projects/${projectId}/rfi_settings/${id}`;
const saveCustomSettings = (projectId) => `projects/${projectId}/rfi_custom_fields`;
const updateCustomSettings = (projectId, id) => `projects/${projectId}/rfi_custom_fields/${id}`;
const rfiHistoryLog = (projectId, id) => `/projects/${projectId}/rfis/${id}/rfi_logs`;
const rfiHistoryLogView = (projectId, rfiId, id) =>
  `/projects/${projectId}/rfis/${rfiId}/rfi_logs/${id}`;

export default {
  getRFIs,
  getDraftRFIs,
  viewRFIs,
  markAsOfficial,
  addResponse,
  forwardReview,
  getRFIFilters,
  getRFIMeta,
  createRFI,
  editRFI,
  markAsClosed,
  markAsDeleted,
  reopenRFI,
  getRFIVIEWFilters,
  addAssignee,
  updateResponse,
  deleteResponse,
  createRFIs,
  updateRFIs,
  getSettings,
  updateSettings,
  saveCustomSettings,
  updateCustomSettings,
  rfiHistoryLog,
  rfiHistoryLogView,
  restoreDeleted,
  requestReopenRFI,
  markAsRejected,
  markAsOpen
};
