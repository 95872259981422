import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Menu, Dropdown, Modal } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Logo from '../../fonts/logo.svg';
import Bbanner from '../../images/bbanner.png';
import HPBanner from '../../images/banner.svg';

export default function Home() {
  const [size, setSize] = useState('large');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate();

  const redirectLogin = () => {
    navigate('/login');
  };

  const redirectSignup = () => {
    navigate('/signup');
  };

  const menu = (
      <Menu
          items={[
            {
              key: '1',
              label: ' 1st menu item'
            },
            {
              key: '2',
              label: ' 2nd menu item'
            },
            {
              key: '3',
              label: ' 3rd menu item'
            }
          ]}
      />
  );

  const items = [
    {
      label: (
          <div>
            <Dropdown overlay={menu} placement="bottom" arrow>
              <Button>
                Products <DownOutlined />
              </Button>
            </Dropdown>
          </div>
      )
    },
    {
      label: (
          <div>
            <Dropdown overlay={menu} placement="bottom" arrow>
              <Button>
                Solutions <DownOutlined />
              </Button>
            </Dropdown>
          </div>
      )
    },
    { label: 'About  Us', key: 'item-1' },
    { label: 'Our Team', key: 'item-2' },
    { label: 'Careers', key: 'item-3' },
    { label: 'contact Us', key: 'item-4' }
  ];

  return (
      <div className="HomePage">
        {/* navbar */}
        <div className="navbar">
          <Row>
            {/* logo */}
            <div>
              <img src={Logo} alt="" />
            </div>
            {/* menu */}
            <div className="menu-items">
              <Menu items={items} />
            </div>
            {/* button */}
            <div className="nav-options">
              <Button onClick={redirectSignup} type="secondary" className="sign-btn">
                sign up
              </Button>
              <Button onClick={redirectLogin} type="primary" className="login-btn">
                Log in
              </Button>
            </div>
          </Row>
        </div>
        {/* banner */}
        <div className="HomeBanner">
          <div className="banner-section">
            <img src={HPBanner} alt="..." className="hbanner" />
            <div className="banner-content">
              <h1>Construction Project Management Software</h1>
              <p>
                Project Management connects your teams and project information so you can make better
                decision faster
              </p>
              <Button shape="round" size={size} style={{ background: '#3B3B3C', color: 'white' }}>
                Contact Us
              </Button>

              <Button
                  shape="round"
                  type="primary"
                  size={size}
                  style={{ background: 'white', color: '#FC8543' }}>
                Learn More
              </Button>
            </div>
          </div>
        </div>
        {/* Last */}
        <div className="industrySection">
          <div className="descriptions">
            <h2 className="leader">ProjectLoop Works With Industry Leaders</h2>
            <h1 className="place semiBold">Everything is easily accessible in one place</h1>
            <h3 className="device">
              Access to the right tools to manage your projects, available on any device.
            </h3>
          </div>
          <div className="Bbanner-img">
            <img src={Bbanner} alt="..." className="Bbanner-img" />
          </div>
        </div>
      </div>
  );
}
