import React from 'react'
import DemoSection from '../DemoSection'
import PricingPlans from './PricingPlans'
import "./styles.scss";

export default function Pricing() {
    return (
        <div className='pricing-page-wrapper'>
            <PricingPlans />
            <DemoSection mergeTop={false} />
        </div>
    )
}
