import React from 'react';
import PlaceholderImage from '../../../../images/placeholder_image.png';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

import "./styles.scss";
import { Button } from 'antd';
import { Link } from 'react-router-dom';

export default function FeaturesSection({ headerText, subText, ctaLink = "#", ctaButtonTitle = "Learn More", featureHighlights = [], featureImageSrc = PlaceholderImage, sectionAlign = "image-left", sectionBackground = "white" }) {

    const imageSection = <div className="section-image-container"> 
        <img src={featureImageSrc} alt="" />
    </div>

    const textSection = <div className="section-text-container">
        <h2><b>{headerText}</b></h2>
        <h4>{subText}</h4>
        <ul style={{listStyleType:'none'}}>
            {featureHighlights.map(x => (<li>{x}</li>))}
        </ul>
        <Link to={ctaLink}>
            <Button type="primary" size='large' shape='round' className="section-cta-btn">{ctaButtonTitle}</Button>
        </Link>
    </div>

    const UIElements = [];

    if (sectionAlign == "image-left") {
        UIElements.push(imageSection);
        UIElements.push(textSection);
    } else {
        UIElements.push(textSection);
        UIElements.push(imageSection);
    }

    return (
        <div className='features-section' style={(sectionBackground == "white" ? { backgroundColor: "#fff" } : { backgroundColor: "#fcf2ec" })}>
            <div className='section-container'>
                {UIElements.map((item, key) => {
                    return <ScrollAnimation
                        key={key}
                        animateIn="animate__fadeIn"
                        duration={0.75}
                        animateOnce={true}
                        delay={0}
                        style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center"
                        }}
                        className='animator-container'
                        children={item} />
                })}
            </div>
        </div>
    )
}
