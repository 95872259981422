/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { remove } from 'lodash';
import * as ACTIONS from '../actions';
import { API_SERVICE } from '../services/CommonApi';
import projectAPI from '../services/Project';

const initialState = {
  projectDetails: {},
  project: {},
  projectEditDetails: {},
  editData: {},
  crewBaseData: {},
  selectedKeys: {}
};

export const getProjectDetails = createAsyncThunk(ACTIONS.VIEW_PROJECT, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.get(projectAPI.getProjectDetails(arg.projectId));
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const createProject = createAsyncThunk(ACTIONS.CREATE_PROJECT, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.post(projectAPI.createProject(), arg.payload, true);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const updateProject = createAsyncThunk(ACTIONS.UPDATE_PROJECT, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.put(projectAPI.updateProject(arg.id), arg.payload, true);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getProjectEditDetails = createAsyncThunk(
  ACTIONS.EDIT_PROJECT,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.get(projectAPI.getProjectEditDetails(arg.projectId));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    resetStatus: () => initialState,
    setEditData: (state, { payload }) => {
      state.editData = payload;
    },
    setCrewBaseData: (state, { payload }) => {
      state.crewBaseData = payload;
    },
    setSelectedKeys: (state, { payload }) => {
      const { selectedKeysValue, removeKeys = [], customAccessName } = payload;
      const selectNames = state.selectedKeys?.[customAccessName] || [];

      // Create a merged array with unique values
      const mergedArray = [...new Set([...selectNames, ...selectedKeysValue])];

      // Update state only if the arrays are different
      const isDifferent =
        mergedArray.length !== selectNames.length ||
        !mergedArray.every((value, index) => value === selectNames[index]);

      if (isDifferent) {
        state.selectedKeys[customAccessName] = mergedArray;
      }

      if (removeKeys.length > 0) {
        const filteredArray = state.selectedKeys[customAccessName].filter(
          (item) => !removeKeys.includes(item)
        );
        state.selectedKeys[customAccessName] = filteredArray;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectDetails.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.projectDetails = payload.data.data;
      }
    });
    builder.addCase(createProject.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.project = payload.data.data;
      }
    });
    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.project = payload.data.data;
      }
    });
    builder.addCase(getProjectEditDetails.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.projectEditDetails = payload.data.data;
      }
    });
  }
});

export const { resetStatus, setEditData, setCrewBaseData, setSelectedKeys } = projectSlice.actions;
export default projectSlice.reducer;
