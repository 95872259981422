import React from 'react'

export default function SolutionsPage() {
    return (
        <>
            <h1>SolutionsPage</h1>
            <p>Coming Soon!</p>
        </>
    )
}
