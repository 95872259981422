import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import FeaturesSection from '../FeaturesSection'
import FeatureHighlightSection from '../FeatureHighlightSection'
import DemoSection from '../DemoSection'

export default function AboutUsPage() {
  return (
    <div className='features-section' >
    <div className='section-container'>
    <div className="about-us">
      <section className="about-mission">
        <h2>Our Mission</h2>
        <p>
        ProjectLoop is dedicated to revolutionizing the construction and project management industries by providing an intuitive, comprehensive platform designed to streamline operations, enhance collaboration, and drive efficiency from the ground up. As a direct competitor to Procore and Fieldwire, our mission is to empower contractors, project managers, and construction teams with a suite of tools that not only matches but exceeds the capabilities of existing solutions. We are committed to innovation, user-friendliness, and adaptability, ensuring that our platform not only meets the evolving needs of the construction sector but also sets new benchmarks for excellence. By focusing on seamless integration, real-time communication, and advanced project tracking features, ProjectLoop aims to minimize delays, reduce costs, and improve the overall quality of construction projects, making it the go-to choice for industry professionals seeking reliability and performance.
        </p>
        <p>
        In our pursuit of redefining project management and construction processes, ProjectLoop places a strong emphasis on customer feedback, agile development, and continuous improvement. Our vision extends beyond just competing; we aim to lead the market by offering solutions that are not just about managing projects but enhancing the way teams collaborate, make decisions, and achieve project milestones. Through fostering a culture of innovation, embracing cutting-edge technologies, and prioritizing the security and privacy of our users, ProjectLoop is dedicated to building a sustainable, user-centric platform. Whether it's a small renovation or a large-scale construction project, our goal is to provide a platform that adapts to your needs, ensures project success, and transforms the way the construction industry operates, today and into the future.
        </p>
      </section>
      <section className="about-story">
        <h2>Our Story</h2>
        <p>
          In the bustling hubs of Ontario and Colorado, where the construction and tech industries intersect, a groundbreaking idea took root. It began as a series of conversations among industry leaders—veterans of construction management, seasoned architects, and innovative tech entrepreneurs who shared a common frustration. They lamented the limitations of existing project management tools like Procore and Fieldwire, which, despite their strengths, fell short in addressing the unique, evolving demands of modern construction projects. These conversations, fueled by a shared vision for a more integrated, adaptable solution, sparked the genesis of ProjectLoop.
        </p>
        <p>The founding team comprised a diverse group of experts. From Colorado, a tech-savvy entrepreneur with a track record of successful startups, passionate about leveraging technology to solve real-world problems. From Toronto, a group of construction industry veterans with decades of experience managing large-scale projects and an intimate understanding of the field's complexities. Together, they saw an opportunity to blend cutting-edge technology with deep industry knowledge to create a platform that would not just compete with, but significantly outperform existing tools. They envisioned a solution that would be as dynamic and resilient as the projects it aimed to manage, capable of adapting to the varied and complex nature of construction work today.</p>
        <p>
          The journey to bring ProjectLoop to life was marked by collaboration across borders. The team leveraged Ontario's tech innovation ecosystem and Colorado's booming construction industry to develop, test, and refine their platform. They focused on creating a user-friendly interface that could bring together project managers, contractors, architects, and stakeholders in a seamless, collaborative environment. By incorporating feedback from real users in both locations, ProjectLoop was designed to address the specific pain points of project management, such as communication breakdowns, inefficiencies in resource allocation, and the need for real-time updates.
        </p>
        <p>
          Today, ProjectLoop stands as a testament to what can be achieved when industry leaders unite with a common goal. It exists as a beacon for innovation in construction and project management, a platform conceived in the spirit of collaboration and improved by the diverse expertise of its users. In Ontario and Colorado, where the idea first took shape, ProjectLoop is now not just a tool but a symbol of progress, an example of how barriers can be broken down and industries transformed when visionaries come together to challenge the status quo.
        </p>
      </section>
      {/* <section className="about-team">
        <h2>Meet the Team</h2>
        <div className="team-members">
          
          <div className="team-member">
            
            <h3>[Team Member's Name]</h3>
            <p>[Short Bio]</p>
          </div>
          
        </div>
      </section> */}
    </div>
    </div>
    </div>
  )
}
