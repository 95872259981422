import React from "react";
import { StyledHeader } from "./styles";
import { Col, Button, Row, Dropdown, Menu } from "antd";
import logo from "../../../../images/Group.svg"
import { MenuOutlined, UserAddOutlined, LoginOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const HeaderMenuItems = [
	{
		key: "features",
		label: <Link to="/features">Features</Link>
	},
	// {
	// 	key: "solutions",
	// 	label: <Link to="/solutions">Solutions</Link>
	// },
	{
		key: "pricing",
		label: <Link to="/pricing">Pricing</Link>
	},
	{
		key: "about-us",
		label: <Link to="/about_us">About Us</Link>
	},
	{
		key: "contact-us",
		label: <Link to="/contact_us">Contact Us</Link>
	},
	{
		type: 'divider',
	},
	// {
	// 	key: "sign-up",
	// 	label: <Link to="/signup">Sign Up</Link>,
	// 	icon: <UserAddOutlined />
	// },
	{
		key: "login",
		label: <Link to="/login">Login</Link>,
		icon: <LoginOutlined />
	}
]

export default function Header({ showHeaderMenu = true }) {
	return (
		<StyledHeader>
			<Row gutter={16} justify="space-between" align="middle" style={{ height: "80px", maxWidth: "1400px", margin: "0 auto" }}>
				<Col span={6}>
					<div className="logo">
						<Link to={"/"} >
							<img src={logo} style={{ maxWidth: 200 }} />
						</Link>
					</div>
				</Col>
				{showHeaderMenu && <Col span={18} style={{ textAlign: "right" }}>
					<Dropdown
						className="is-mobile"
						menu={{
							items: HeaderMenuItems,
							onClick: (e) => {
								console.log('click', e);
							}
						}}
						overlayStyle={{
							width: "160px"
						}}
						trigger={['click']}
						placement="bottomRight"
					>
						<Button type="primary" icon={<MenuOutlined />} />
					</Dropdown>
					<div className="is-not-mobile">
						<Menu
							mode="horizontal"
							items={HeaderMenuItems}
							// items={HeaderMenuItems.filter(menu => menu.label)}
							style={{ backgroundColor: "transparent", justifyContent: "flex-end" }}
							selectable={false}
						/>
					</div>
				</Col>
				}
			</Row>
		</StyledHeader >
	)
}
