/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API_SERVICE } from '../CommonApi';

// eslint-disable-next-line import/prefer-default-export
export const useGetProjectsDropdownData = (companyId, type) => {
  return useQuery({
    queryKey: [`projects_base_data`, companyId, type],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/filter_options`, {
        company_id: companyId,
        ...type
      });
      return data;
    },
    enabled: !!companyId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetProjectData = (projectId) => {
  return useQuery({
    queryKey: ['projects', projectId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${projectId}?type=form`);
      return data;
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetProjectDetailsForView = (projectId) => {
  return useQuery({
    queryKey: ['projects-detail-for-view', projectId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${projectId}`);
      return data;
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false
  });
};

export const useGetProjectDetails = (projectId, payload = {}) => {
  return useQuery({
    queryKey: ['projects-detail', projectId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${projectId}`, payload);
      return data;
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    refetchOnReconnect: false,
    refetchOnMount: false
  });
};

export const useGetAllProjectsData = (page, filters, companyId, type, sort, per_page) => {
  return useQuery({
    queryKey: ['projects_list', page, filters, sort, companyId, per_page],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects`, {
        page,
        per_page,
        filters,
        sort,
        company_id: companyId,
        type
      });
      return data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useModifyProject = (companyId, compId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, id, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.putRequest(`projects/${id}`, payload, true);
      }
      return API_SERVICE.postRequest(`projects?company_id=${companyId}`, payload, true);
    },
    onSuccess: (_, variables) => {
      if (variables.action === 'update') {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['projects-detail-for-view', variables.id]
          }),
          queryClient.invalidateQueries({
            queryKey: ['projects', variables.id]
          }),
          queryClient.invalidateQueries({
            queryKey: ['company_project_list_dropdown', compId]
          })
        ]);
      }
      if (variables.action === 'create') {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['company_project_list', companyId]
          }),
          queryClient.invalidateQueries({
            queryKey: ['company_project_list_dropdown', companyId]
          })
        ]);
      }
    }
  });
};

export const useGetLocationDropdownData = (projectId, payload) => {
  return useQuery({
    queryKey: ['location-field-dropdown', projectId, payload],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/project_locations/load_project_locations`,
        payload
      );
      return data;
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    refetchOnReconnect: false,
    refetchOnMount: false
  });
};
