import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetProjectLocationQRCodeData } from '../../services/Project/hooks';
import { ViewLocation } from './location';

const ViewLocationContainer = () => {
  const { locationId, qrId } = useParams();
  const { data } = useGetProjectLocationQRCodeData(locationId, qrId);
  const locationData = data?.data?.data;
  return <ViewLocation viewOnly location={locationData} />;
};

export default ViewLocationContainer;
