import React, { useState } from 'react';
import DemoSection from '../DemoSection';

export default function ContactUs() {
  // Form state
  const [formData, setFormData] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });

  // Errors state
  const [errors, setErrors] = useState({});

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate form
  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (!formData.companyName) {
      errors.companyName = 'Company name is required';
      formIsValid = false;
    }

    if (!formData.firstName) {
      errors.firstName = 'First name is required';
      formIsValid = false;
    }

    if (!formData.lastName) {
      errors.lastName = 'Last name is required';
      formIsValid = false;
    }

    setErrors(errors);
    return formIsValid;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form data:', formData);
      alert('Thank you for contacting us!');
      // Reset form (optional)
      setFormData({
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      });
    }
  };

  return (
    <div className='contact-us-container'>
      <div>
        <h1 className='contact-title'>CONTACT US</h1>
        <p className='contact-subtext'>Want to ask a specific question?<br />You are in the right place.</p>
      </div>

      <div className='address-text'>
        <p>Questions or suggestions for our team?</p>
        <p>Whatever you need, our door is always open.</p>
        <p>833-444-9876<br />361 Applewood<br />Concord, ON, L4K4J3</p>
      </div>

      {/* Contact Form */}
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <label>Company Name*</label>
          <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} className='form-input' />
          {errors.companyName && <p className='error-text'>{errors.companyName}</p>}
        </div>
        <div className='form-group'>
          <label>First Name*</label>
          <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} className='form-input' />
          {errors.firstName && <p className='error-text'>{errors.firstName}</p>}
        </div>
        <div className='form-group'>
          <label>Last Name*</label>
          <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} className='form-input' />
          {errors.lastName && <p className='error-text'>{errors.lastName}</p>}
        </div>
        <div className='form-group'>
          <label>Email</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} className='form-input' />
        </div>
        <div className='form-group'>
          <label>Phone Number</label>
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} className='form-input' />
        </div>
        <button type="submit" className='submit-button'>Submit</button>
      </form>

      <DemoSection mergeTop={false} />

      {/* Inline CSS */}
      <style jsx>{`
        .contact-us-container {
          max-width: 100%;
          margin: auto;
          padding: 20px;
          // box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        .form-group {
          margin-bottom: 10px;
        }
        .form-input {
          width: 100%;
          padding: 8px;
          box-sizing: border-box;
        }
        .error-text {
          color: red;
        }
        .submit-button {
          background-color: rgb(252, 133, 67);
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
}
