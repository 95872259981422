import styled from 'styled-components';

export const SignupWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 20px !important;
  }
  .signup-btn {
    margin-bottom: 5px !important;
  }
  .login {
    .login-content {
      padding: 22px 30px 10px;
    }
  }
`;
