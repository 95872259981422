import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import ParallaxSection from '../Components/ParallaxSection'
import DemoSection from '../Components/DemoSection'
import FeaturesSection from '../Components/FeaturesSection'

export default function RFI() {
    return (
        <>
            <HeroSection heroText={"RFI"} subText={"Streamline the tracking and execution of safety actions with ProjectLoop's user-friendly tools. Take immediate control of your construction management system."} />
            <FeaturesSection
                headerText={"Simplify Action Creation"}
                subText={"Empower your team to take immediate and effective action with ProjectLoop's streamlined action creation tools."}
                featureHighlights={["Create actions effortlessly, ensuring timely response to safety concerns.",
                    "Attach relevant documentation and information to actions for clarity.",
                    "Assign actions to responsible parties with ease, improving accountability.",
                    "Monitor action progress and status in real-time.",
                    "Enhance communication and collaboration for swift issue resolution."]}
                sectionBackground='dark'
                sectionAlign="image-right"
            />
            <FeaturesSection
                headerText={"Customized Action Types"}
                subText={"Tailor your action types to match your organization's specific needs and safety protocols."}
                featureHighlights={["Define and categorize action types based on your safety requirements.",
                    "Create action templates with predefined tasks and checklists.",
                    "Standardize actions for consistent reporting and analysis.",
                    "Easily identify the nature and urgency of each action type.",
                    "Foster a proactive safety culture by classifying actions effectively."]}
            />
            <FeaturesSection
                headerText={"Comprehensive Sub Category Types and Asset Actions"}
                subText={"Enhance your safety management system by utilizing sub category types and asset actions for detailed tracking and control."}
                featureHighlights={["Create sub category types to further classify actions within categories.",
                    "Link actions directly to specific assets or equipment for targeted management.",
                    "Improve asset maintenance and safety compliance through asset actions.",
                    "Streamline asset-related tasks, such as inspections and maintenance schedules.",
                    "Achieve a more granular view of safety data through sub categories and asset actions."]}
                sectionBackground='dark'
                sectionAlign="image-right"
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <DemoSection mergeTop={false} />
            </div>
        </>
    )
}
