import { createAsyncThunk, createSlice, nanoid, current } from '@reduxjs/toolkit';
import * as ACTIONS from '../../actions';
import rfis from '../../services/RFIs/rfis';
import { API_SERVICE } from '../../services/CommonApi';

const initialState = {
  view_rfi: {},
  rfiDetails: {},
  selectedVal: [],
  historyLogs: [],
  historyView: {},
  distributionList: {
    distributionUsers: []
  },
  selectAssignees: {
    filterOptions: [],
    selectedIDs: [],
    requiredValues: [],
    selectedNames: [],
    selectedValues: []
  },
  view_rfi_filters: {},
  assigneeFilters: [],
  errors: '',
  reopen: false,
  mark_as_closed: false,
  mark_as_deleted: false,
  restoreRFI: false,
  add_assignee: false,
  forward_review: false,
  mark_as_official: false,
  add_edit_response: false,
  create_rfi: false,
  update_rfi: false,
  rfi_settings_update: false,
  save_custom_settings: false,
  view_rfi_settings: {},
  custom_fields: [],
  seqNum: ''
};
export const getViewRFI = createAsyncThunk(ACTIONS.VIEW_RFI, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.get(`${rfis.viewRFIs(arg.projectId)}/${arg.rfiId}`);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const getHistoryRFI = createAsyncThunk(ACTIONS.HISTORY_RFI, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.get(`${rfis.rfiHistoryLog(arg.projectId, arg.rfiId)}`);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const getHistoryViewRFI = createAsyncThunk(
  ACTIONS.HISTORY_RFI_VIEW,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.get(`${rfis.rfiHistoryLogView(arg.projectId, arg.rfiId, arg.id)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getRFIDetails = createAsyncThunk(ACTIONS.GET_RFI, async (arg, thunkAPI) => {
  try {
    const payload = arg.draft ? { draft: true } : {};
    return API_SERVICE.get(rfis.createRFIs(arg.projectId), payload);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const getDraftRFIsAPI = createAsyncThunk(ACTIONS.DRAFT_RFI, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.get(rfis.getDraftRFIs(arg.projectId));
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const getViewRFIFilters = createAsyncThunk(
  ACTIONS.VIEW_RFI_FILTER,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.get(`${rfis.getRFIVIEWFilters(arg.projectId)}`, { ...arg.obj });
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const reOpenRFI = createAsyncThunk(ACTIONS.REOPEN_RFI, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.patch(`${rfis.reopenRFI(arg.projectId, arg.rfiId)}`, {}, '');
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const requestReOpenRFI = createAsyncThunk(
  ACTIONS.REQUEST_REOPEN_RFI,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.patch(
        `${rfis.requestReopenRFI(arg.projectId, arg.rfiId)}`,
        { request_for_reopen: arg.comments },
        ''
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const markAsClosed = createAsyncThunk(ACTIONS.MARK_AS_CLOSED_RFI, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.patch(`${rfis.markAsClosed(arg.projectId, arg.rfiId)}`, {}, '');
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const markAsDeleted = createAsyncThunk(
  ACTIONS.MARK_AS_DELETED_RFI,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.patch(`${rfis.markAsDeleted(arg.projectId, arg.rfiId)}`, {}, '');
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const markAsRejected = createAsyncThunk(
  ACTIONS.MARK_AS_REJECTED_RFI,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.patch(
        `${rfis.markAsRejected(arg.projectId, arg.rfiId)}`,
        { reason_for_rejection: arg.comments },
        ''
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const markAsOpen = createAsyncThunk(ACTIONS.MARK_AS_OPEN_RFI, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.patch(`${rfis.markAsOpen(arg.projectId, arg.rfiId)}`, {}, '');
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const restoreRFIData = createAsyncThunk(
  ACTIONS.RESTORE_DELETED_RFI,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.patch(`${rfis.restoreDeleted(arg.projectId, arg.id)}`, {}, '');
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const addAssignee = createAsyncThunk(ACTIONS.ADD_ASSIGNEE, async (arg, thunkAPI) => {
  try {
    const payload = { rfi: { assignees: arg.payload } };
    return API_SERVICE.post(`${rfis.addAssignee(arg.projectId, arg.rfiId)}`, payload, '');
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const forwardReview = createAsyncThunk(ACTIONS.FORWARD_REVIEW, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.post(`${rfis.forwardReview(arg.projectId, arg.rfiId)}`, arg.payload, '');
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const markAsOfficial = createAsyncThunk(ACTIONS.MARK_AS_OFFICIAL, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.patch(
      `${rfis.markAsOfficial(arg.projectId, arg.rfiId, arg.response_id)}`,
      arg.payload,
      ''
    );
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const addEditResponse = createAsyncThunk(
  ACTIONS.ADD_EDIT_RESPONSE,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE[arg.responseId ? 'put' : 'post'](
        `${
          arg.responseId
            ? rfis.updateResponse(arg.projectId, arg.responseId)
            : rfis.addResponse(arg.projectId, arg.rfiId)
        }`,
        arg.bodyFormData,
        true
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getRFISetting = createAsyncThunk(ACTIONS.SETTINGS_RESPONSE, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.get(rfis.getSettings(arg.projectId, arg.rfi_setting_id), {});
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const onCreateRFI = createAsyncThunk(ACTIONS.ADD_RFI_ACTION, async (arg, thunkAPI) => {
  try {
    return API_SERVICE[arg.rfiId ? 'put' : 'post'](
      arg.rfiId ? rfis.updateRFIs(arg.projectId, arg.rfiId) : rfis.createRFIs(arg.projectId),
      arg.payload,
      true
    );
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const onUpdateRFI = createAsyncThunk(ACTIONS.UPDATE_RFI_ACTION, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.put(rfis.updateRFIs(arg.projectId, arg.rfiId), arg.payload, true);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const onUpdateRFISettings = createAsyncThunk(
  ACTIONS.UPDATE_RFI_SETTINGS,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.put(rfis.updateSettings(arg.projectId, arg.rfi_setting_id), arg.payload);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchCustomField = createAsyncThunk(
  ACTIONS.GET_CUSTOM_SETTING,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.get(rfis.saveCustomSettings(arg.projectId), {});
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const onSaveCustomSettings = createAsyncThunk(
  ACTIONS.CREATE_CUSTOM_SETTING,
  async (arg, thunkAPI) => {
    try {
      const response = API_SERVICE[arg.isEdit ? 'put' : 'post'](
        arg.isEdit
          ? rfis.updateCustomSettings(arg.projectId, arg.payload.id)
          : rfis.saveCustomSettings(arg.projectId),
        arg.payload
      );
      thunkAPI.dispatch(fetchCustomField());
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deleteCustomSettings = createAsyncThunk(
  ACTIONS.DELETE_CUSTOM_SETTING,
  async (arg, thunkAPI) => {
    try {
      const response = API_SERVICE.delete(rfis.updateCustomSettings(arg.projectId, arg.payload));
      thunkAPI.dispatch(fetchCustomField());
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const rfiViewSlice = createSlice({
  name: 'rfi',
  initialState,
  reducers: {
    resetStatus: () => initialState,
    getAddAssignee: (state, { payload }) => {
      state.selectAssignees = {
        ...state.selectAssignees,
        selectedIDs: [...new Set(payload)]
      };
    },
    closeAddAssignee: (state, action) => {
      state.selectAssignees = {
        selectedIDs: [],
        requiredValues: [],
        selectedNames: [],
        selectedValues: []
      };
    },
    onChangeSettings: (state, { payload }) => {
      if (payload.key === 'enable_distribution_list') {
        state.view_rfi_settings = { ...state.view_rfi_settings, [payload.key]: payload.value };
        if (!payload.value) state.view_rfi_settings.default_distribution_ids = [];
      } else if (payload.key === 'sequence_number') {
        state.view_rfi_settings = { ...state.view_rfi_settings, [payload.key]: payload.value };
      } else if (payload.key === 'default_distribution_ids') {
        const ids = state.view_rfi_settings.default_distribution_ids;
        ids.push(payload.value.toString());
        state.view_rfi_settings = { ...state.view_rfi_settings, [payload.key]: [...new Set(ids)] };
      } else state.view_rfi_settings = { ...state.view_rfi_settings, [payload.key]: payload.value };
    },
    removeUserFromDistribution: (state, { payload }) => {
      const ids = state.view_rfi_settings.default_distribution_ids;
      const newArray = ids.filter((obj) => obj !== payload);
      state.view_rfi_settings = { ...state.view_rfi_settings, default_distribution_ids: newArray };
    },
    setSelectedObj: (state, { payload }) => {
      state.selectAssignees = {
        ...state.selectAssignees,
        selectedValues: payload || [],
        selectedIDs: [...new Set(payload.map((v) => v.user_id))]
      };
    },
    resetRFIStatus: (state, action) => {
      state.reopen = false;
      state.mark_as_closed = false;
      state.add_assignee = false;
      state.forward_review = false;
      state.mark_as_official = false;
      state.add_edit_response = false;
      state.create_rfi = false;
      state.update_rfi = false;
      state.rfi_settings_update = false;
      state.save_custom_settings = false;
      state.mark_as_deleted = false;
      state.restoreRFI = false;
      state.errors = '';
    },
    setSelectedField: (state, action) => {
      const payload = [];
      action.payload.map((el) =>
        state.custom_fields.map((x) => x.key === el.key && payload.push(x))
      );
      state.selectedVal = [...payload];
    },
    removeSelectedField: (state, action) => {
      state.selectedVal = state.selectedVal.filter((x) => x.key !== action.payload.key);
    },
    resetFormFields: (state, action) => {
      state.view_rfi = {};
    },
    onChangeDistributionList: (state, { payload }) => {
      state.distributionList = { ...state.distributionList, ...payload };
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getViewRFI.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.view_rfi = payload.data.data;
        }
      })
      .addCase(getHistoryRFI.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.historyLogs = payload.data.data;
        }
      })
      .addCase(getHistoryViewRFI.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.historyView = payload.data.data;
        }
      })
      .addCase(getRFIDetails.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.rfiDetails = payload.data.data;
        }
      })
      .addCase(getDraftRFIsAPI.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.rfiDraftDetails = payload.data.data;
        }
      })
      .addCase(getViewRFIFilters.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.view_rfi_filters = payload.data;
          state.assigneeFilters =
            payload.data?.assignees?.length > 0
              ? payload.data.assignees?.map((v) => {
                  const check =
                    state.selectAssignees?.selectedValues.length > 0
                      ? state.selectAssignees?.selectedValues.filter((e) => e.user_id === v.value)
                      : [];
                  return {
                    id: check?.length > 0 ? check[0].id : 0,
                    name: v.key,
                    checked: check?.length > 0,
                    response_required: check?.length > 0 ? check[0].response_required : false,
                    user_id: v.value,
                    _destroy: v?._destroy ? v?._destroy : false
                  };
                })
              : [];
        }
      })
      .addCase(addAssignee.fulfilled, (state, { payload }) => {
        state.add_assignee = state.reopen = payload.status === 200 || payload.status === 201;
      })
      .addCase(reOpenRFI.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.reopen = true;
        } else {
          state.errors = payload.response.data.base[0];
        }
      })
      .addCase(requestReOpenRFI.fulfilled, (state, { payload }) => {
        state.reopen = payload.status === 200 || payload.status === 201;
      })
      .addCase(markAsClosed.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.mark_as_closed = true;
        } else {
          state.errors = payload.response.data.error;
        }
      })
      .addCase(markAsDeleted.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.mark_as_deleted = true;
        } else {
          state.errors = payload.response.data.base[0];
        }
      })
      .addCase(markAsRejected.fulfilled, (state, { payload }) => {
        console.log(payload);
        if (payload.status === 200 || payload.status === 201) {
          state.mark_as_deleted = true;
        } else {
          state.errors = payload.response.data.base[0];
        }
      })
      .addCase(markAsOpen.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.mark_as_deleted = true;
        } else {
          state.errors = payload.response.data.base[0];
        }
      })
      .addCase(restoreRFIData.fulfilled, (state, { payload }) => {
        state.restoreRFI = payload.status === 200 || payload.status === 201;
      })
      .addCase(forwardReview.fulfilled, (state, { payload }) => {
        state.forward_review = payload.status === 200 || payload.status === 201;
      })
      .addCase(markAsOfficial.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.mark_as_official = true;
        } else {
          state.errors = payload?.response?.data?.base[0];
        }
      })
      .addCase(addEditResponse.fulfilled, (state, { payload }) => {
        state.add_edit_response = payload.status === 200 || payload.status === 201;
      })
      .addCase(onCreateRFI.fulfilled, (state, { payload }) => {
        state.create_rfi = payload.status === 200 || payload.status === 201;
      })
      .addCase(onUpdateRFI.fulfilled, (state, { payload }) => {
        state.update_rfi = payload.status === 200 || payload.status === 201;
      })
      .addCase(onSaveCustomSettings.fulfilled, (state, { payload }) => {
        state.save_custom_settings = payload.status === 200 || payload.status === 201;
      })
      .addCase(getRFISetting.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.view_rfi_settings = payload.data.data;
          state.seqNum = payload.data.data.sequence_number;
        }
      })
      .addCase(onUpdateRFISettings.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.view_rfi_settings = payload.data.data;
          state.seqNum = payload.data.data.sequence_number;
          state.rfi_settings_update = true;
        }
      })
      .addCase(fetchCustomField.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          const obj = payload.data;
          obj.data = obj.data.map((el) => ({
            ...el,
            key: nanoid(),
            value: el.name,
            filedValue: '',
            label: el.name,
            title: el.input_type,
            type: el.input_type
          }));
          state.custom_fields = obj.data;
        }
      })
      .addCase(deleteCustomSettings.fulfilled, (state, { payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.delete_fieldList = payload.data.data;
        }
      });
  }
});

export const {
  resetStatus,
  resetRFIStatus,
  setSelectedField,
  removeSelectedField,
  removeUserFromDistribution,
  getAddAssignee,
  closeAddAssignee,
  setSelectedObj,
  onChangeSettings,
  resetFormFields,
  onChangeDistributionList
} = rfiViewSlice.actions;
export default rfiViewSlice.reducer;
