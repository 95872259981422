import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';
import * as ACTIONS from '../../actions';
import { API_SERVICE } from '../../services/CommonApi';
import submittalService from '../../services/Submittals';

const initialState = {
  createSubmittalSuccess: false,
  workflowSuccess: false,
  deleteSuccess: false,
  selectedValues: [],
  formData: {
    workFlow: [],
    lead_time_day: 0,
    on_site_date: '',
    distributionUsers: []
  },
  submittalFilters: {
    custom_fields: []
  },
  getWorkflowData: [],
  existing: false
};
export const onSaveCreateSubmittals = createAsyncThunk(
  ACTIONS.CREATE_SUBMITTALS,
  async (arg, thunkAPI) => {
    try {
      const url = arg.submittalId
        ? submittalService.viewSubmittals(arg.projectId, arg.submittalId)
        : `${submittalService.saveCreateSubmittal(arg.projectId)}`;
      return API_SERVICE[arg.submittalId ? 'put' : 'post'](url, arg.payload, '');
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const saveWorkflow = createAsyncThunk(ACTIONS.SAVE_WORKFLOW, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.post(`${submittalService.saveWorkflow(arg.projectId)}`, arg.payload, '');
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const deleteWorkflowRecord = createAsyncThunk(
  ACTIONS.DELETE_WORKFLOW,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.delete(
        `${submittalService.getSubmittalWorkFlow(arg.projectId, arg.id)}`,
        '',
        ''
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getSubmittalFilters = createAsyncThunk(
  ACTIONS.SUBMITTAL_FILTERS,
  async (arg, thunkAPI) => {
    const url = arg.create_submittal
      ? `${submittalService.submittalFilters(arg.projectId)}?create_submittal=${
          arg.create_submittal
        }`
      : submittalService.submittalFilters(arg.projectId);
    try {
      return API_SERVICE.get(url, '');
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getSubmittalWorkFlow = createAsyncThunk(
  ACTIONS.GET_SUBMITTAL_WORKFLOW,
  async (arg, thunkAPI) => {
    const url = submittalService.getSubmittalWorkFlow(arg.projectId, arg.id);
    try {
      return API_SERVICE.get(url, '');
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const CreateSubmittal = createSlice({
  name: 'createSubmittal',
  initialState,
  reducers: {
    resetFormFields: (state, { payload }) => {
      return {
        ...state,
        formData: {
          ...initialState.formData,
          workFlow: [],
          lead_time_day: 0,
          on_site_date: '',
          distributionUsers: []
        }
      };
    },
    onChangeSubmittalField: (state, { payload }) => {
      state.formData = { ...state.formData, ...payload };
    },
    onChangeWorkFlowField: (state, { payload }) => {
      const { receivedData, isDelete } = payload;
      const { workFlow } = state.formData;
      const workFlowArry = workFlow.length > 0 ? [...workFlow] : [];
      const data = [];
      if (isDelete) {
        workFlow.map((v) => {
          if (v.id === receivedData.id) {
            data.push({ ...v, _destroy: true });
          } else {
            data.push(v);
          }
        });
      } else workFlowArry.push(receivedData);

      return {
        ...state,
        formData: {
          ...state.formData,
          workFlow: isDelete ? data : workFlowArry
        }
      };
    },
    setSelectedField: (state, { payload }) => {
      const { customFieldsOptions, value } = payload;
      const data = [];
      value.map((e) => {
        customFieldsOptions.find((v) => v.id === e && data.push(v));
      });
      state.selectedValues = [...data];
    },
    setEdiCustomSelectedField: (state, { payload }) => {
      const { data } = payload;
      state.selectedValues = [...data];
    },
    removeSelectedField: (state, { payload }) => {
      const { e } = payload;
      const data = state.selectedValues;
      state.selectedValues = data.map((object) => {
        return object.id === e.id ? { ...object, _destroy: true } : object;
      });
    },
    setCustomFields: (state, { payload }) => {
      const { value, obj } = payload;
      const data = state.selectedValues;
      data.forEach((element, index) => {
        if (element.id === obj.id) {
          data[index] = { ...obj, response_value: value };
        }
      });
      state.selectedValues = data;
    },
    onUpdateWorkflow: (state, { payload }) => {
      const { receivedData } = payload;
      const { workFlow } = state.formData;
      const updatedData = workFlow.map((x) =>
        x.user_id == receivedData.user_id ? { ...x, due_date: receivedData.due_date } : x
      );
      return {
        ...state,
        formData: {
          ...state.formData,
          workFlow: updatedData
        }
      };
    },
    resetCreateSubmittalStatus: (state, { payload }) => {
      return {
        ...state,
        createSubmittalSuccess: false,
        workflowSuccess: false,
        deleteSuccess: false
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(onSaveCreateSubmittals.fulfilled, (state, { meta, payload }) => {
        state.createSubmittalSuccess = payload.status === 200 || payload.status === 201;
      })
      .addCase(saveWorkflow.fulfilled, (state, { meta, payload }) => {
        state.workflowSuccess = payload.status === 200 || payload.status === 201;
      })
      .addCase(deleteWorkflowRecord.fulfilled, (state, { meta, payload }) => {
        state.deleteSuccess = payload.status === 200 || payload.status === 201;
      })
      .addCase(getSubmittalFilters.fulfilled, (state, { meta, payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          const obj = payload.data;
          obj.custom_fields = obj?.custom_fields
            ? obj?.custom_fields.map((el) => ({
                ...el,
                key: nanoid(),
                value: el.title,
                filedValue: '',
                label: el.title,
                title: el.input_type,
                type: el.input_type
              }))
            : [];
          state.submittalFilters = obj;
        }
      })
      .addCase(getSubmittalWorkFlow.fulfilled, (state, { meta, payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          const { assignees, submittal_users_list_options } = payload.data.data;
          state.getWorkflowData = payload.data.data;
          state.formData = { ...state.formData, workFlow: assignees };
          state.existing = true;
          state.submittalFilters = {
            ...state.submittalFilters,
            assignees: submittal_users_list_options
          };
        }
      });
  }
});

export const {
  resetFormFields,
  onChangeSubmittalField,
  onChangeWorkFlowField,
  setSelectedField,
  removeSelectedField,
  onUpdateWorkflow,
  resetCreateSubmittalStatus,
  setCustomFields,
  setEdiCustomSelectedField
} = CreateSubmittal.actions;
export default CreateSubmittal.reducer;
