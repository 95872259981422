/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Spin,
  Upload,
  notification
} from 'antd';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  LoadingOutlined,
  PlusCircleFilled,
  PrinterOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { SectionTitle } from '../../components/SettingsTab/CommonComponents';
import { useGetProjectLocationData, useModifyProjectLocation } from '../../services/Project/hooks';
import { ReactComponent as ViewIcon } from '../../fonts/view.svg';
import { ReactComponent as DeleteIcon } from '../../fonts/delete-custom.svg';
import { ReactComponent as EditIcon } from '../../fonts/Edit.svg';
import { ReactComponent as LocationIcon } from '../../images/Location.svg';
import { Wrapper } from '../Pipeline/style';
import formRules from '../DailyLogsNew/components/Forms/formRules';
import { fallbackImage } from '../DailyLogsNew/components/Preview/commonStyles';
import { AttachmentList } from '../Schedule/componants/UploadForm';

const FieldsWrapper = styled.div`
  display: flex;
  padding: 16px;
  border: 1px solid #fc8543;
  border-radius: 4px;
  align-items: center;
  width: 100%;
  .ant-form-item-label {
    > label {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #909090;
    }
  }
`;

export const SettingPageWrapper = styled.div`
  padding-top: 8px;
  padding-left: 24px;
  .accordion {
    margin-bottom: 24px;
    &.parent {
      background: #f6f6f6;
      border: 0;
      font-weight: 700;
      .ant-collapse-content {
        border-top: 0;
      }
      > .ant-collapse-item {
        border-bottom: 0;
      }
    }
    &.child {
      background: transparent;
      font-weight: 700;
    }
    .acc-extra {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    .title-span {
      padding: 0px 6px;
      border-radius: 8px;
      background: #ececec;
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
      color: #909090;
      margin-right: 10px;
    }
  }
`;

const LocationFields = ({ onSubmit, onCancel, parentId = null, initialValues, setEdit }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const isEdit = !!initialValues;
  const handleOnFinish = (values) => {
    const formData = new FormData();
    formData.append('project_location[name]', values.name);
    if (values.code) {
      formData.append('project_location[code]', values.code);
    }
    if (values.description) {
      formData.append('project_location[description]', values.description);
    }
    if (parentId) {
      formData.append('project_location[parent_id]', parentId);
    }
    if (fileList?.length) {
      fileList.forEach((file, i) => {
        formData.append(`project_location[files][${i}]`, file);
      });
    }
    const action = isEdit ? 'update' : 'create';
    if (onSubmit) onSubmit(formData, action, initialValues?.id);
    if (setEdit) setEdit(false);
  };
  const UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList
  };
  return (
    <Form
      layout="vertical"
      onFinish={handleOnFinish}
      initialValues={initialValues}
      form={form}
      name="locationForm">
      <Row gutter={12} style={{ width: '100%' }}>
        <Col span={15}>
          <Form.Item
            label="Location Name"
            name="name"
            rules={formRules.setRequired('Location Name')}>
            <Input placeholder="Enter Location Name" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item label="Location Code" name="code">
            <Input placeholder="Enter Code" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Description" name="description">
            <Input.TextArea placeholder="Describe location" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="files" label="Attachment">
            <Upload.Dragger
              itemRender={(_originNode, _file, list, action) => (
                <AttachmentList list={list} action={action} />
              )}
              {...UploadProps}
              accept="image/*,.pdf">
              <Row gutter={16} className="p-12 no-margin-lr flex-row item-center">
                <Col span={11} className="drag" style={{ height: 32 }}>
                  <Button size="medium" type="dashed" className="w-100 bg-grey">
                    Drag File Here
                  </Button>
                </Col>
                <Col span={2} className="fc-grey fs-14">
                  Or
                </Col>
                <Col span={11} style={{ height: 32 }} className="bg-lite-primary text-center">
                  <Button icon={<UploadOutlined />} size="medium" type="link">
                    Add New Attachment
                  </Button>
                </Col>
              </Row>
            </Upload.Dragger>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row className="gap-16" justify="end" align="middle">
            <Button onClick={onCancel} htmlType="button" type="ghost">
              Close
            </Button>
            <Button type="primary" htmlType="submit">
              {isEdit ? 'Update' : 'Create'}
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const AddSubLocation = ({ onSubmit, parentId, label }) => {
  const [isEdit, setEdit] = useState(false);
  return (
    <>
      <Button icon={<PlusCircleFilled />} shape="link" size="large" onClick={() => setEdit(true)}>
        {`Add Sub Location For ${label}`}
      </Button>
      <Modal
        title="Add Location"
        open={isEdit}
        footer={null}
        destroyOnClose
        closable
        closeIcon={<CloseOutlined onClick={() => setEdit(false)} />}>
        <Wrapper>
          <LocationFields
            setEdit={setEdit}
            onSubmit={onSubmit}
            onCancel={() => setEdit(false)}
            parentId={parentId}
          />
        </Wrapper>
      </Modal>
    </>
  );
};

const AddLocation = ({ onSubmit }) => {
  const [isEdit, setEdit] = useState(false);
  return (
    <>
      <Button
        icon={<PlusCircleFilled />}
        shape="link"
        size="large"
        onClick={() => setEdit(!isEdit)}>
        Add Location
      </Button>
      {isEdit && (
        <Modal
          title="Add Location"
          open={isEdit}
          footer={null}
          destroyOnClose
          closable
          closeIcon={<CloseOutlined onClick={() => setEdit(false)} />}>
          <Wrapper>
            <LocationFields setEdit={setEdit} onSubmit={onSubmit} onCancel={() => setEdit(false)} />
          </Wrapper>
        </Modal>
      )}
    </>
  );
};

export const ViewLocation = ({ location = {}, viewOnly = false }) => {
  const { name, code, description, attachments = [], qr_code_url: qrCodeUrl } = location;
  console.log(attachments, 'attachments');
  const handleCopy = async () => {
    const image = document.getElementById('imageToCopy');
    // if (!image) return;
    // try {
    //   const blob = await fetch(image.src).then((response) => response.blob());
    //   await navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]);
    //
    // } catch (error) {
    //   notification.open({
    //     message: 'Error copying QR code, Please try later!',
    //     placement: 'bottomLeft',
    //     description: <></>,
    //     className: 'toast-class error'
    //   });
    // }
    navigator.clipboard
      .writeText(qrCodeUrl)
      .then(() => {
        notification.open({
          message: 'QR code copied successfully',
          placement: 'bottomLeft',
          description: <></>,
          className: 'toast-class success'
        });
      })
      .catch(() => {
        notification.open({
          message: 'Error copying QR code, Please try later!',
          placement: 'bottomLeft',
          description: <></>,
          className: 'toast-class error'
        });
      });
  };
  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const image = document.getElementById('imageToCopy');
    if (!image) return;
    const imgHTML = `<img src="${image.src}" alt="Printed Image">`;
    printWindow.document.write(imgHTML);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  const humanFileSize = (size) => {
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / 1024 ** i).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
  };
  return (
    <Wrapper>
      <Row gutter={24} className={`${viewOnly ? 'p-12 no-margin-lr scanner' : 'scanner'}`}>
        <Col span={viewOnly ? 24 : 16}>
          <div className="loc-info-wrap">
            <LocationIcon />
            <div className="loc-info">
              <h3>
                {name} &nbsp; {code && <label>{code}</label>}
              </h3>

              <p className="loc-desc">{description}</p>
            </div>
          </div>
          <Divider />
          <h4>
            Attachments
            <span className="count-label" style={{ marginLeft: '10px' }}>
              {attachments?.length}
            </span>
          </h4>
          <Row className="loc-attach-wrap" wrap gutter={16}>
            {attachments?.length ? (
              attachments.map((img) => (
                <Col span={8} className="no-border">
                  {img?.file_name?.endsWith('pdf') ? (
                    <a target="_blank" href={img.download_url} rel="noreferrer">
                      <Image
                        className="attach"
                        src={img.preview_url}
                        fallback={fallbackImage}
                        preview={{ visible: false, src: img.preview_url }}
                      />
                    </a>
                  ) : (
                    <Image
                      className="attach"
                      src={img.preview_url}
                      fallback={fallbackImage}
                      preview={{
                        src: img.download_url
                      }}
                    />
                  )}

                  <h2 className="file-name">{img.file_name}</h2>
                  <div className="file-info">
                    <h3>{humanFileSize(img.size)}</h3>
                    <a target="_blank" href={img.download_url} rel="noreferrer">
                      download
                    </a>
                  </div>
                </Col>
              ))
            ) : (
              <p className="p-12">No Image</p>
            )}
          </Row>
        </Col>
        {!viewOnly && (
          <Col span={8}>
            <Row className="bg-grey p-12 br-5" wrap justify="start">
              <p className="fc-grey w-100 fw-700" style={{ marginBottom: 10 }}>
                QR Code
              </p>
              <img
                id="imageToCopy"
                height="235px"
                width="100%"
                src={qrCodeUrl || fallbackImage}
                style={{ marginBottom: 10 }}
              />
              <Row justify="space-between" className="w-100">
                <Button
                  type="primary"
                  icon={<CopyOutlined />}
                  disabled={!qrCodeUrl}
                  onClick={handleCopy}
                  style={{ background: 'transparent', color: '#FC8543' }}>
                  Copy QR Code
                </Button>
                <Button
                  type="primary"
                  onClick={handlePrint}
                  disabled={!qrCodeUrl}
                  icon={<PrinterOutlined />}
                  style={{ background: 'transparent', color: '#FC8543' }}>
                  Print
                </Button>
              </Row>
            </Row>
          </Col>
        )}
      </Row>
    </Wrapper>
  );
};

const LocationCollapse = ({ location, onSubmit, onDelete, parentId = null }) => {
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);
  return (
    <>
      <Collapse className={`accordion ${!parentId ? 'parent' : 'child'}`} destroyInactivePanel>
        <Collapse.Panel
          key={location.id}
          collapsible="icon"
          header={
            <>
              <span className="title-span">{location.code}</span>
              {location.name}
            </>
          }
          extra={
            <div className="acc-extra">
              <Button
                type="primary"
                style={{ background: 'transparent', color: '#FC8543' }}
                onClick={() => setView(true)}>
                View
              </Button>
              <EditIcon
                style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                onClick={() => setEdit(true)}
              />
              <ViewIcon style={{ cursor: 'pointer' }} />
              <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => onDelete(location.id)} />
            </div>
          }>
          <AddSubLocation
            key={location.name}
            label={location.name}
            onSubmit={onSubmit}
            parentId={location.id}
          />
          {location?.sub_locations?.length > 0 &&
            location.sub_locations.map((sub) => {
              return (
                <LocationCollapse
                  key={`${sub.name}-${sub.id}`}
                  onSubmit={onSubmit}
                  location={sub}
                  parentId={location.id}
                  onDelete={onDelete}
                />
              );
            })}
        </Collapse.Panel>
      </Collapse>
      {isEdit && (
        <Modal
          title="Add Location"
          open={isEdit}
          footer={null}
          destroyOnClose
          closable
          closeIcon={<CloseOutlined onClick={() => setEdit(false)} />}>
          <Wrapper>
            <LocationFields
              initialValues={location}
              onSubmit={onSubmit}
              onCancel={() => setEdit(false)}
              parentId={parentId}
              setEdit={setEdit}
            />
          </Wrapper>
        </Modal>
      )}
      {isView && (
        <Modal
          title="View Location"
          open={isView}
          footer={null}
          destroyOnClose
          closable
          width="80%"
          closeIcon={<CloseOutlined onClick={() => setView(false)} />}>
          <ViewLocation location={location} />
        </Modal>
      )}
    </>
  );
};

const Location = () => {
  const { projectId } = useParams();
  const {
    data: locationData,
    isFetching,
    isLoading: isDataLoading
  } = useGetProjectLocationData(projectId);
  const { mutate, isLoading } = useModifyProjectLocation(projectId);
  const updateSuccess = (content) => {
    notification.open({
      message: content || 'Updated Successfully',
      placement: 'bottomLeft',
      description: <></>,
      className: 'toast-class success'
    });
  };
  const handleOnSubmit = (values, action, id) => {
    mutate(
      { payload: values, action, id },
      {
        onSuccess: () => {
          updateSuccess('Location Updated Successfully');
        }
      }
    );
  };
  const handleOnDelete = (id) => {
    mutate(
      { payload: { id }, action: 'delete' },
      {
        onSuccess: () => {
          updateSuccess('Location Deleted Successfully');
        }
      }
    );
  };
  return (
    <SettingPageWrapper>
      <Spin spinning={isLoading || isFetching || isDataLoading} indicator={<LoadingOutlined />}>
        <SectionTitle title="Location" enableAction={false} />
        <Divider />
        <AddLocation onSubmit={handleOnSubmit} />
        <Divider />
        {locationData?.data?.data?.map((location) => {
          return (
            <LocationCollapse
              key={`${location.name}-${location.id}`}
              location={location}
              onSubmit={handleOnSubmit}
              onDelete={handleOnDelete}
            />
          );
        })}
      </Spin>
    </SettingPageWrapper>
  );
};

export default Location;
