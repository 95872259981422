/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  DownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { ReactComponent as Filter } from '../../fonts/filter.svg';
import { ReactComponent as DocPDF } from '../../images/doc_pdf.svg';
import { ReactComponent as DocImg } from '../../images/doc-img.svg';
import { ReactComponent as Logo } from '../../fonts/logo.svg';
import { DocIcons } from './DocumentIcons';
import useFilterChange from '../../utils/useFilterChange';
import { ImageComponent } from './List';
import { ActionItem } from '../Schedule/componants/List';
import PunchListWrapper from '../PunchList/List/styles';
import {
  useGetExternalDocumentsData,
  useModifyExternalFileUpload,
  useModifyExternalFolder
} from '../../services/ProjectDoucment/hooks';
import NoData from '../DailyLogsNew/components/NoData';
import NoSharedData from './NoData';
import ExternalFileUpload from './components/ExternalFileUpload';
import ExternalUpload from './components/ExternalUpload';
import CreateEditFolder from './components/CreateEditFolder';
import ParentsHistory from '../LocationsNew/components/ParentsHistory';
import showNotification from '../../components/ShowNotification';
import { API_SERVICE } from '../../services/CommonApi';
import DeleteSharedLink from './DeleteSharedLink';
import ExternalEditDocument from './components/ExternalEditDocument';

const dateFormat = 'MM/DD/YYYY';

const ExternalDocuments = () => {
  const navigate = useNavigate();
  const [isFileUpload, setFileUpload] = useState(false);
  const [isFolderUpload, setFolderUpload] = useState(false);
  const [isCreateFolder, setCreateFolder] = useState(false);
  const [updateFolder, setUpdateFolder] = useState(false);
  const [updateDocument, setUpdateDocument] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [filterHistory, setFilterHistory] = useState({});
  const [parents, setParents] = useState([]);
  const [modal, contextHolderModal] = Modal.useModal();
  const { id } = useParams();
  const { mutate: mutateFile, isLoading: isFileMutate } = useModifyExternalFileUpload(id);
  const { mutate: mutateFolder, isLoading: isFolderMutate } = useModifyExternalFolder(id);
  const [sortState, setSortState] = useState({});
  const sortData = { ...sortState };
  const initalState = {
    page: 1,
    per_page: 10,
    extraState: {
      selected: { parentId: undefined, parentName: '' }
    }
  };
  const { handleOnFilterChange, filterData, filterState, setFilterState } =
    useFilterChange(initalState);
  const {
    data = {},
    isLoading,
    isFetching,
    refetch
  } = useGetExternalDocumentsData(
    filterState,
    filterData,
    id,
    filterState?.extraState?.selected?.parentId,
    sortData
  );
  const pageNotFound = data?.response?.status === 404 || false;

  const { totalCount } = data?.pagination || {};
  const {
    documents = [],
    sub_folders = [],
    folders = [],
    external_user_link_info = {},
    id: parentId,
    name: document_name,
    document_type,
    is_external = true
  } = data?.data || [];
  const dataSource = [...folders, ...sub_folders, ...documents];
  const { creator, reason, recipient_email, recipient_name } = external_user_link_info;
  useEffect(() => {
    if (parents.length) {
      const lastParent = parents[parents.length - 1];
      setFilterState((prev) => ({
        ...prev,
        ...(filterHistory?.[lastParent.id] || initalState),
        extraState: {
          ...prev.extraState,
          selected: { parentId: lastParent.id, parentName: lastParent.name }
        }
      }));
    }
  }, [parents]);
  const handleHomeClick = () => {
    const historyState = filterHistory?.[undefined] || initalState;
    setFilterHistory({});
    setFilterState(historyState);
  };
  if (isLoading) {
    return <NoData onlySpinner />;
  }
  const items = [
    {
      label: 'Upload File',
      key: 'file',
      icon: <PlusOutlined />
    }
  ];

  if (!document_type) {
    items.push({
      label: 'Upload Folder',
      key: 'folder',
      icon: <PlusOutlined />
    });
  }

  const handleMenuClick = ({ key }) => {
    if (key === 'file') setFileUpload(true);
    if (key === 'folder') setFolderUpload(true);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick
  };

  const onClickFolder = (record) => {
    setFilterHistory((prev) => ({
      ...prev,
      [filterState?.extraState?.selected?.parentId]: filterState
    }));
    setParents((prev) => [...prev, record]);
  };

  const onConfirm = (record) => {
    if (!record?.document_type) {
      mutateFolder(
        { payload: { id: record.id }, action: 'delete' },
        {
          onSuccess: () =>
            Promise.all([
              refetch(),
              showNotification({ message: `${record.name} deleted Successfully` })
            ])
        }
      );
    } else {
      mutateFile(
        { payload: { id: record.id }, action: 'delete' },
        {
          onSuccess: () =>
            Promise.all([
              refetch(),
              showNotification({ message: `${record.name} deleted Successfully` })
            ])
        }
      );
    }
  };

  const onDeleteData = (record) => {
    modal.confirm({
      title: 'Delete Confirmation',
      bodyStyle: {
        textAlign: 'center'
      },
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none">
          <path
            d="M30 52.5C42.4264 52.5 52.5 42.4264 52.5 30C52.5 17.5736 42.4264 7.5 30 7.5C17.5736 7.5 7.5 17.5736 7.5 30C7.5 42.4264 17.5736 52.5 30 52.5Z"
            stroke="#D92929"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M37.5 22.5176L22.5 37.4827M22.5 22.5176L37.5 37.4827L22.5 22.5176Z"
            stroke="#D92929"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      content: `Do you want to delete
                “${record.name}” ${record?.document_type ? '' : 'Folder'}
                This was shared with external users and their access will be lost upon deletion.`,
      okText: 'Yes, Delete',
      okButtonProps: {
        loading: isFileMutate || isFolderMutate,
        style: {
          background: '#D92929',
          borderColor: '#D92929'
        },
        size: 'middle'
      },
      cancelButtonProps: {
        style: {
          background: '#ECECEC',
          borderColor: '#ECECEC',
          color: '#5C5C5C'
        }
      },
      onOk: () => {
        onConfirm(record);
      },
      cancelText: 'Cancel'
    });
  };

  const rowOptions = (item, record) => {
    const options = [];
    if (record?.allow_edit) {
      options.push({
        label: (
          <ActionItem
            onClick={(e) => {
              record?.document_type
                ? setUpdateDocument({ id: record.id, name: record.name })
                : setUpdateFolder({ id: record.id, name: record.name });
              e.preventDefault();
            }}>
            {DocIcons.rename}
            Rename
          </ActionItem>
        ),
        key: '1'
      });
    }
    if (record?.allow_delete) {
      options.push({
        label: (
          <ActionItem
            style={{ color: '#D92929' }}
            onClick={(e) => {
              e.preventDefault();
              onDeleteData(record);
            }}>
            {DocIcons.delete}
            Delete
          </ActionItem>
        ),
        key: '5'
      });
    }
    return options;
  };

  const handleDownload = (record = {}) => {
    if (record.document_type) {
      API_SERVICE.downloadDocument(record?.download_url, record?.name);
    } else {
      setDownloading(true);
      API_SERVICE.downloadZip(
        `external_documents/${id}/download_as_zip?parent_id=${parentId}`,
        record.name,
        setDownloading
      );
    }
  };

  const columns = [
    {
      title: (
        <>
          <Row align="middle" justify="space-between" wrap={false}>
            <div>Name</div>
            <Filter />
          </Row>
          <Input.Search
            id="name"
            style={{ width: '100%' }}
            onSearch={(value) => handleOnFilterChange(value, 'name')}
            placeholder="Search"
            loading={isFetching}
            allowClear
            value={filterState?.name}
            onClick={(e) => e.stopPropagation()}
          />
        </>
      ),
      filteredValue: filterState?.name,
      dataIndex: 'name',
      key: 'name',
      sortOrder: sortState.attribute !== 'name' ? 'asc' : sortState.direction,
      onHeaderCell: (col) => ({
        onClick: () => {
          setSortState((pre) => ({
            ...pre,
            attribute: 'name',
            direction: pre.attribute !== 'name' ? 'asc' : col.sortOrder === 'asc' ? 'desc' : 'asc'
          }));
        }
      }),
      sortDirections: ['asc', 'desc', 'asc'],
      render: (name, record) => (
        <div
          onClick={() => (record?.document_type ? '' : onClickFolder(record))}
          className={`document ${
            record?.document_type ? '' : 'ps-auto'
          } d-flex align-items-center`}>
          {record?.document_type ? (
            <>{record?.name?.endsWith('.pdf') ? <DocPDF /> : <DocImg />}</>
          ) : (
            DocIcons.folder
          )}{' '}
          <div className="font-weight-500 folder-title">
            {record?.document_type ? (
              record?.name.endsWith('.pdf') ? (
                <a
                  style={{ color: '#080808' }}
                  target="_blank"
                  href={record.download_url}
                  rel="noreferrer">
                  {name}
                </a>
              ) : (
                <>
                  <ImageComponent record={record} isLink />
                </>
              )
            ) : (
              name
            )}
          </div>
          {record?.is_private ? DocIcons.lock : null}
        </div>
      )
    },
    {
      title: (
        <>
          <Row align="middle" justify="space-between" wrap={false}>
            <div>Created By</div>
            {/* <Filter /> */}
          </Row>
          <Row>
            <Input.Search
              id="created_by"
              style={{ width: '100%' }}
              onSearch={(value) => handleOnFilterChange(value, 'created_by')}
              placeholder="Search User"
              loading={isFetching}
              allowClear
              value={filterState?.created_by}
              onClick={(e) => e.stopPropagation()}
            />
          </Row>
        </>
      ),
      filteredValue: filterState?.created_by,
      dataIndex: 'created_by',
      key: 'created_by',
      // sortOrder: sortState.attribute !== 'created_by' ? 'asc' : sortState.direction,
      // onHeaderCell: (col) => ({
      //   onClick: () => {
      //     setSortState((pre) => ({
      //       ...pre,
      //       attribute: 'created_by',
      //       direction:
      //         pre.attribute !== 'created_by' ? 'asc' : col.sortOrder === 'asc' ? 'desc' : 'asc'
      //     }));
      //   }
      // }),
      // sortDirections: ['asc', 'desc', 'asc'],
      render: (value) => (
        <>
          <p className="document">
            <span className="title"> {value || '-'}</span>
          </p>
        </>
      )
    },
    {
      title: (
        <>
          <Row align="middle" justify="space-between" wrap={false}>
            <div>Created On</div>
            <Filter />
          </Row>
          <Row>
            <DatePicker.RangePicker
              id="created_at"
              separator={<span style={{ color: '#909090' }}>-</span>}
              style={{ minWidth: 252, maxWidth: 252 }}
              value={
                filterState?.created_at
                  ? [
                      moment(filterState.created_at.start, dateFormat),
                      moment(filterState.created_at.end, dateFormat)
                    ]
                  : undefined
              }
              format={dateFormat}
              onChange={(_, date) => handleOnFilterChange(date, 'created_at')}
              onClick={(e) => e.stopPropagation()}
            />
          </Row>
        </>
      ),
      filteredValue: filterState?.created_at,
      dataIndex: 'created_at',
      key: 'created_at',
      sortOrder: sortState.attribute !== 'created_at' ? 'asc' : sortState.direction,
      onHeaderCell: (col) => ({
        onClick: () => {
          setSortState((pre) => ({
            ...pre,
            attribute: 'created_at',
            direction:
              pre.attribute !== 'created_at' ? 'asc' : col.sortOrder === 'asc' ? 'desc' : 'asc'
          }));
        }
      }),
      sortDirections: ['asc', 'desc', 'asc'],
      render: (value, record) => (
        <>
          <p className="document">
            <span className="title"> {record?.created_at || '-'}</span>
          </p>
        </>
      )
    },
    {
      title: 'Action',
      width: '180px',
      key: 'action',
      render: (_, record) => (
        <>
          <Space align="end">
            <div className="">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleDownload(record);
                }}
                style={{
                  fontSize: '14px',
                  background: '#f6f6f6',
                  color: '#5C5C5C',
                  border: 'none',
                  height: '32px',
                  padding: '8px 16px'
                }}>
                Download
              </Button>
            </div>
            <div>
              {record?.document_type ? (
                record?.name.endsWith('.pdf') ? (
                  <a target="_blank" href={record.download_url} rel="noreferrer">
                    <Button
                      // onClick={() => setSelected({ parentId: record.id, parentName: record.name })}
                      className="btn-light font-weight-700"
                      type="primary"
                      style={{ fontSize: '14px', height: '32px' }}>
                      Open
                    </Button>
                  </a>
                ) : (
                  <>
                    <ImageComponent record={record} />
                  </>
                )
              ) : (
                <>
                  <Button
                    className="btn-light font-weight-700"
                    type="primary"
                    onClick={() => onClickFolder(record)}
                    style={{ fontSize: '14px', height: '32px' }}>
                    Open
                  </Button>
                </>
              )}
            </div>
            <Dropdown
              overlayClassName="more-dropdown-wrap"
              placement="bottomRight"
              menu={{
                items: rowOptions(_, record)
              }}>
              <Button
                className="d-flex align-items-center"
                style={{
                  borderRadius: '4px',
                  background: '#ECECEC',
                  border: 'none',
                  justifyContent: 'space-evenly'
                }}
                icon={DocIcons.action}
              />
            </Dropdown>
          </Space>
        </>
      )
    }
  ];

  const handleOnChange = (pagination) => {
    setFilterState((prev) => ({
      ...prev,
      page: pagination.current,
      per_page: pagination.pageSize || 10
    }));
  };

  return (
    <>
      {contextHolderModal}
      {pageNotFound ? (
        <DeleteSharedLink />
      ) : (
        <>
          <Row
            style={{ margin: 0, padding: '16px 24px', borderBottom: '1px solid #D3D3D3' }}
            align="middle">
            <Row align="middle" style={{ margin: 0, gap: 18, flex: 1 }}>
              <Logo onClick={() => navigate('/')} />
              <Card
                bodyStyle={{ padding: 0, paddingRight: 10 }}
                style={{ background: '#F6F6F6', borderRadius: 8, overflow: 'hidden' }}>
                <Row align="middle" style={{ margin: 0, gap: 10 }}>
                  <Col
                    style={{
                      background: '#ECECEC',
                      padding: '10px 8px',
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none">
                      <g clipPath="url(#clip0_13562_80880)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7ZM12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2ZM7.87868 16.8787C8.44129 16.3161 9.20435 16 10 16H14C14.7956 16 15.5587 16.3161 16.1213 16.8787C16.6839 17.4413 17 18.2044 17 19V21C17 21.5523 17.4477 22 18 22C18.5523 22 19 21.5523 19 21V19C19 17.6739 18.4732 16.4021 17.5355 15.4645C16.5979 14.5268 15.3261 14 14 14H10C8.67392 14 7.40215 14.5268 6.46447 15.4645C5.52678 16.4021 5 17.6739 5 19V21C5 21.5523 5.44772 22 6 22C6.55228 22 7 21.5523 7 21V19C7 18.2043 7.31607 17.4413 7.87868 16.8787Z"
                          fill="#FC8543"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_13562_80880">
                          <rect width="24" height="24" rx="4" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Col>
                  <Col>
                    <h3 style={{ margin: 0, fontSize: 10, fontWeight: 400, color: '#5C5C5C' }}>
                      Shared By
                    </h3>
                    <h4 style={{ margin: 0, fontSize: 12, fontWeight: 700, color: '#3B3B3C' }}>
                      {creator || ''}
                    </h4>
                  </Col>
                </Row>
              </Card>
              <Card
                bodyStyle={{ padding: '7px 16px' }}
                style={{ background: '#EBF3FF', borderRadius: 8 }}>
                <Col style={{ color: '#3284FF', fontSize: 10, fontWeight: 500 }}>
                  Reason for Sharing :
                </Col>
                <Col
                  title={reason || ''}
                  style={{
                    maxWidth: 250,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#3284FF',
                    fontWeight: 700,
                    fontSize: 12,
                    whiteSpace: 'nowrap'
                  }}>
                  {reason || ''}
                </Col>
              </Card>
            </Row>
            <Card
              bordered={false}
              bodyStyle={{ padding: 0 }}
              style={{ background: '##fff', border: 0, borderRadius: 8, textAlign: 'right' }}>
              <Row style={{ gap: 10 }}>
                <Col>
                  <h3
                    style={{
                      textAlign: 'right',
                      margin: 0,
                      fontSize: 14,
                      fontWeight: 500,
                      color: '#080808'
                    }}>
                    {recipient_name || ''}
                  </h3>
                  <h4 style={{ margin: 0, fontSize: 10, fontWeight: 400, color: '#5C5C5C' }}>
                    {recipient_email || ''}
                  </h4>
                </Col>
                <Col>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none">
                    <rect y="-0.000976562" width="36" height="36" rx="8" fill="#ECECEC" />
                    <path
                      d="M27.2223 20V22.7491C22.4737 23.4557 20.7079 27.4144 20.0001 30.9965C19.9734 31.1381 23.8885 26.8989 27.2223 26.8728V29.622L33 24.811L27.2223 20Z"
                      fill="#5C5C5C"
                    />
                    <path
                      d="M13 11.3024C13 8.3739 15.374 6 18.3024 6C21.2308 6 23.6048 8.37399 23.6048 11.3024C23.6048 14.2308 21.2308 16.6048 18.3024 16.6048C15.374 16.6048 13 14.2308 13 11.3024Z"
                      fill="#909090"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18 18.3945C15.1896 18.3945 12.6342 19.2646 10.6519 20.2361C9.17807 20.9584 8.38867 22.3057 8.38867 23.697C8.38867 25.0884 9.17807 26.4356 10.6519 27.1579C12.6344 28.1293 15.1896 28.9995 18 28.9995C18.485 28.9995 18.9625 28.9736 19.4308 28.9257C20.3722 25.7052 22.3268 22.7983 26.3012 21.807L26.2087 20.7878C25.9547 20.5785 25.6675 20.3926 25.3481 20.2361C23.3655 19.2647 20.8103 18.3945 18 18.3945Z"
                      fill="#909090"
                    />
                  </svg>
                </Col>
              </Row>
            </Card>
          </Row>
          <Row
            align="middle"
            style={{ margin: 0, padding: '12px 24px', borderBottom: '1px solid #D3D3D3' }}
            justify="space-between">
            <Row align="middle" style={{ gap: 6 }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none">
                <g clipPath="url(#clip0_13808_12193)">
                  <path
                    d="M6.66667 5.33301H12L16 9.33301H25.3333C26.0406 9.33301 26.7189 9.61396 27.219 10.1141C27.719 10.6142 28 11.2924 28 11.9997V22.6663C28 23.3736 27.719 24.0519 27.219 24.552C26.7189 25.0521 26.0406 25.333 25.3333 25.333H6.66667C5.95942 25.333 5.28115 25.0521 4.78105 24.552C4.28095 24.0519 4 23.3736 4 22.6663V7.99967C4 7.29243 4.28095 6.61415 4.78105 6.11406C5.28115 5.61396 5.95942 5.33301 6.66667 5.33301"
                    stroke="#080808"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_13808_12193">
                    <rect width="32" height="32" rx="4" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <h2 style={{ margin: 0, fontSize: 24, fontWeight: 600 }}>Document List</h2>
            </Row>
            {is_external && (
              <Space>
                {!document_type && (
                  <Button
                    type="ghost"
                    icon={<PlusOutlined />}
                    onClick={() => setCreateFolder(true)}
                    style={{ color: '#3B3B3C', borderColor: '#3B3B3C', fontWeight: 600 }}>
                    Create Folder
                  </Button>
                )}
                <Dropdown menu={menuProps}>
                  <Button type="primary" icon={<UploadOutlined />}>
                    <Space style={{ margin: '0 5px' }}>
                      Upload <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            )}
          </Row>
          <Row
            style={{ gap: 8, padding: '16px 32px 0 32px', background: '#F6F6F6' }}
            align="middle">
            {!parents?.length && (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none">
                  <g clipPath="url(#clip0_13671_207564)">
                    <path
                      d="M3.75 3H6.75L9 5.25H14.25C14.6478 5.25 15.0294 5.40804 15.3107 5.68934C15.592 5.97064 15.75 6.35218 15.75 6.75V12.75C15.75 13.1478 15.592 13.5294 15.3107 13.8107C15.0294 14.092 14.6478 14.25 14.25 14.25H3.75C3.35218 14.25 2.97064 14.092 2.68934 13.8107C2.40804 13.5294 2.25 13.1478 2.25 12.75V4.5C2.25 4.10218 2.40804 3.72064 2.68934 3.43934C2.97064 3.15804 3.35218 3 3.75 3"
                      stroke="#5C5C5C"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_13671_207564">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h5 style={{ color: '#5C5C5C', fontSize: 14, margin: 0 }}>
                  {filterState?.extraState?.selected?.parentName || document_name || ''}
                </h5>
              </>
            )}
            <ParentsHistory
              parents={parents}
              setParents={setParents}
              handleHomeClick={handleHomeClick}
            />
          </Row>
          <PunchListWrapper>
            <div className="rfi-table">
              <Table
                dataSource={dataSource}
                columns={columns}
                onChange={handleOnChange}
                loading={isFetching || isDownloading}
                pagination={{
                  current: filterState.page,
                  pageSize: filterState.per_page,
                  total: totalCount,
                  showSizeChanger: true
                }}
              />
            </div>
          </PunchListWrapper>
          {isFileUpload && (
            <ExternalFileUpload
              parentId={filterState?.extraState?.selected?.parentId || parentId}
              isFileUpload={isFileUpload}
              setFileUpload={setFileUpload}
              refetch={refetch}
            />
          )}
          {isFolderUpload && (
            <ExternalUpload
              parentId={filterState?.extraState?.selected?.parentId || parentId}
              isFolderUpload={isFolderUpload}
              setFolderUpload={setFolderUpload}
              refetch={refetch}
            />
          )}
          {isCreateFolder && (
            <CreateEditFolder
              parentId={filterState?.extraState?.selected?.parentId || parentId}
              isUpdateFolder={isCreateFolder}
              setUpdateFolder={setCreateFolder}
              refetch={refetch}
            />
          )}
          {!!updateFolder?.id && (
            <CreateEditFolder
              parentId={filterState?.extraState?.selected?.parentId || parentId}
              isUpdateFolder={!!updateFolder?.id}
              setUpdateFolder={setUpdateFolder}
              refetch={refetch}
              initialValues={updateFolder}
            />
          )}
          {!!updateDocument?.id && (
            <ExternalEditDocument
              initialValues={updateDocument}
              isUpdateDocument={!!updateDocument?.id}
              setUpdateDocument={setUpdateDocument}
              refetch={refetch}
            />
          )}
        </>
      )}
    </>
  );
};

export default ExternalDocuments;
