/* eslint-disable no-else-return */
import { notification } from 'antd';

const showNotification = ({ type, message }) => {
  if (type === 'error') {
    if (Array.isArray(message)) {
      return message?.map((msg) =>
        notification.open({
          message: msg,
          placement: 'bottomLeft',
          description: <></>,
          className: 'toast-class error'
        })
      );
    } else {
      return notification.open({
        message: message || 'Something went wrong, please try later!',
        placement: 'bottomLeft',
        description: <></>,
        className: 'toast-class error'
      });
    }
  }
  return notification.open({
    message: message || 'Updated Successfully',
    placement: 'bottomLeft',
    description: <></>,
    className: 'toast-class success'
  });
};

export default showNotification;
