import React, { useState } from 'react';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import CommonHeader from '../Header/commonHeader';
import LayoutWrapper from './styles';
import SuspenseWrapper from '../SuspenseWrapper';

const SurveyLayout = ({ children, onlyUserInfo, isSurvey }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [formChanged, setFormChanged] = useState(false);
  const [isBack, setBack] = useState(false);
  console.log(formChanged, 'isFormChanged');
  return (
    <LayoutWrapper>
      <CommonHeader
        setBack={setBack}
        formChanged={formChanged}
        isSurvey={isSurvey}
        onlyUserInfo={onlyUserInfo}
      />
      <SuspenseWrapper>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { setFormChanged });
          }
          return child;
        })}
      </SuspenseWrapper>
      {isBack && (
        <Modal
          title="You have Unsaved Changes"
          open={isBack}
          bodyStyle={{ padding: '0px 16px 16px 16px' }}
          destroyOnClose
          okText="Yes"
          onOk={() => navigate(`/project-templates/${projectId}`)}
          closable
          onCancel={() => setBack(false)}
          closeIcon={<CloseOutlined onClick={() => setBack(false)} />}>
          <p>Are you sure, want to go back...?</p>
        </Modal>
      )}
    </LayoutWrapper>
  );
};

export default SurveyLayout;
