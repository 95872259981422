/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Modal, Input, Form } from 'antd';
import { useParams } from 'react-router-dom';
import { useModifyDocumentName } from '../../../services/ProjectDoucment/hooks';
import showNotification from '../../../components/ShowNotification';
import { DocIcons } from '../DocumentIcons';
import { DocumentModel } from '../EditDocument';

function ExternalEditDocument({
  isUpdateDocument,
  setUpdateDocument,
  refetch,
  initialValues = null
}) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const { mutate, isLoading } = useModifyDocumentName(id, true);
  const isEdit = !!initialValues;

  useEffect(() => {
    if (isEdit) {
      const name = initialValues?.name.split('.')[0];
      form.setFieldsValue({
        name: name
      });
    }
  }, [initialValues]);
  const handleCancel = () => {
    setUpdateDocument(false);
    form.resetFields();
  };
  const onFinish = (e) => {
    const payload = {
      document: {
        name: `${e?.name}.${initialValues?.name.split('.')[1]}`
      }
    };
    mutate(
      { payload: payload, id: initialValues?.id },
      {
        onSuccess: (res) => {
          if (res.status === 200 || res.status === 201) {
            showNotification({
              message: `Record Updated Successfully`
            });
            setSubmitting(false);
            setUpdateDocument(false);
            form.resetFields();
            refetch();
          }
        }
      }
    );
  };
  return (
    <>
      <Modal
        title={
          <div className="d-flex align-items-center">
            {DocIcons.edit}
            <span
              className="font-weight-700"
              style={{
                margin: '0 5px',
                fontSize: '20px',
                color: '#080808'
              }}>
              Rename File
            </span>
          </div>
        }
        open={isUpdateDocument}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Close"
        cancelButtonProps={{
          className: 'cancel-btn'
        }}
        confirmLoading={submitting}
        okButtonProps={{
          disabled: submitting,
          loading: submitting,
          className: 'ant-btn btn-primary'
        }}
        onOk={form.submit}>
        <DocumentModel>
          <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
            <Form.Item
              name="name"
              label="File Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter file name'
                },
                {
                  max: 200,
                  message: 'Value should be less than 200 character'
                }
              ]}>
              <Input placeholder="Enter file name" />
            </Form.Item>
          </Form>
        </DocumentModel>
      </Modal>
    </>
  );
}

export default ExternalEditDocument;
