import React from 'react';
import "./styles.scss";

import logo from "../../../../images/pl-logo-white.svg"
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div className='pl-footer'>
            <div>
                <Link to="/terms">Terms & Conditions</Link>
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="/disclaimer">Disclaimer</Link>
            </div>
            <img src={logo} alt="footer-image" style={{marginTop:"10px"}} />
            <div style={{ display: "flex", alignItems: "center" }}>
                <a href="https://apps.apple.com/us/app/projectloop/id6447693686" target="_blank" style={{ display: "inline-block" }}>
                    <img src="https://www.safetyloop.com/packs/media/assets/images/applestore-6d9d1d7667ef6d514ad425b461750f9b.svg" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.safetyloop&pcampaignid=web_share" target="_blank"  style={{ display: "inline-block", marginLeft: "10px" }}>
                    <img src="https://www.safetyloop.com/packs/media/assets/fonts/play-e064ca65025b33905a35985b5c7f4798.svg" />
                </a>
            </div>
            <div>Copyright 2024 © ProjectLoop, Inc. All rights reserved.</div>
        </div>
    )
}
