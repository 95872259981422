import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import mockColumns from '../data/columns';
// import data from '../data/sample.json';
import * as ACTIONS from '../../../actions';
import BoardService from '../../../services/Board';
import { API_SERVICE } from '../../../services/CommonApi';
import { generateBoardList } from '../../../utils/helper';

const initialState = {
  columns: [],
  boardView: {},
  page: 1,
  per_page: 10,
  currentPage: 'punch_lists'
};
export const getBoardView = createAsyncThunk(ACTIONS.GET_BOARD_VIEW, async (arg, thunkAPI) => {
  try {
    thunkAPI.dispatch(resetBoard());
    return API_SERVICE.get(`${BoardService.boardView(arg.projectId, arg.page)}`, {});
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getBoardViewByPage = createAsyncThunk(
  ACTIONS.GET_BOARD_VIEW_PAGE,
  async (arg, thunkAPI) => {
    const url = `${BoardService.boardView(arg.projectId)}?punch_list_status=${
      arg.punch_list_status
    }&page=${arg.page}&per_page=${arg.per_page}`;
    try {
      return API_SERVICE.get(url, {});
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    changePagination: (state, { payload }) => {
      const currentState = state.boardView;
      currentState.state[payload.status] = {
        ...currentState.state[payload.status],
        'current-page': payload.page
      };
      state.boardView = {
        ...currentState
      };
    },
    resetBoard: (state, { payload }) => {
      state.boardView = {};
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoardView.fulfilled, (state, { meta, payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.boardView = generateBoardList(payload.data, state.currentPage);
        }
      })
      .addCase(getBoardViewByPage.fulfilled, (state, { meta, payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.boardView = { ...state.boardView };
        }
      });
  }
});

export const { changePagination, resetBoard, setCurrentPage } = boardSlice.actions;
export default boardSlice.reducer;
