import React from "react";
import {
    faTruckMoving,
    faFileLines,
    faAddressCard,
    faPersonRunning,
    faPenToSquare,
    faFileCircleCheck,
    faThumbTack,
    faLocationDot,
    faClock
} from "@fortawesome/free-solid-svg-icons";

import FeatureListItem from "./CommonComponents/FeatureListItem";

import "../Components/styles.scss";
import "./styles.scss";
import DemoSection from "../Components/DemoSection";
import ParallaxSection from "../Components/ParallaxSection";

const FeaturesHomePage = () => {
    return (
        <div className="features-homepage-wrapper">
            <div className="fh-container">
                <h1 className="hero-text">
                    <span className="blue-highlight">Empower</span> your Construction Management with ProjectLoop.
                </h1>
                <p className="sub-text">
                    ProjectLoop transforms extensive hours of construction tasks into mere seconds. Here's a compilation of the features that contribute to ProjectLoop's exceptional performance.
                </p>
            </div>
            <div className="feature-list-container">
                <FeatureListItem
                    featureURL="/features/drawings"
                    featureIcon={faPenToSquare}
                    headerText="Drawings"
                    subText="Enables efficient organization, version control, access control, and collaboration on project drawings, ensuring accurate documentation, streamlined communication, and adherence to project design requirements.  "
                />
                <FeatureListItem
                    featureURL="/features/rfi"
                    featureIcon={faFileLines}
                    headerText="RFIs"
                    subText="Facilitate clear communication, timely resolution of queries, and effective documentation exchange between project stakeholders, ensuring clarity, reducing delays, and enhancing project progress.   "
                />
                <FeatureListItem
                    featureURL="/features/submittals"
                    featureIcon={faFileCircleCheck}
                    headerText="Submittals"
                    subText="Streamline the process of submitting, reviewing, and tracking project-related documents and materials, ensuring compliance, transparency, and efficient communication among project team members and stakeholders.    "
                />
                <FeatureListItem
                    featureURL="/features/daily_log"
                    featureIcon={faAddressCard}
                    headerText="Daily Log"
                    subText="Gain a comprehensive record of daily project activities, progress, and incidents, facilitating communication, accountability, and documentation for project stakeholders    "
                />
                <FeatureListItem
                    featureURL="/features/punchlists"
                    featureIcon={faThumbTack}
                    headerText="Punch Lists"
                    subText="Systematically identify, track, and address outstanding tasks, deficiencies, or quality issues, ensuring that projects are completed to the required standards and specifications before final delivery or handover."
                />
                <FeatureListItem
                    featureURL="/features/locations"
                    featureIcon={faLocationDot}
                    headerText="Locations"
                    subText="ProjectLoop grants you the capability to rapidly generate and tailor emergency plans, guaranteeing your organization's readiness for unforeseen circumstances. With our tools, you're equipped for any unexpected situation that may arise."
                />
                <FeatureListItem
                    featureURL="/features/asset_tracking"
                    featureIcon={faTruckMoving}
                    headerText="Asset Tracking"
                    subText=" Enables effective tracking, maintenance, and optimization of project resources, ensuring their proper utilization, longevity, and alignment with project goals for enhanced productivity and cost-efficiency."
                />
                <FeatureListItem
                    featureURL="/features/time_tracking"
                    featureIcon={faClock}
                    headerText="Time Tracking"
                    subText="Accurately monitorin project progress, resource utilization, and budget adherence, enabling informed decision-making, efficient resource allocation, and timely project delivery."
                />
                <FeatureListItem
                    featureURL="/features/actions"
                    featureIcon={faPersonRunning}
                    headerText="Actions"
                    subText="Streamline task management, decision-making, and issue resolution processes, enhancing efficiency, accountability, and project progress tracking for improved project outcomes.    "
                />
                <FeatureListItem
                    featureURL="/features/forms"
                    featureIcon={faFileLines}
                    headerText="Forms"
                    subText=" Software streamline data collection, ensure consistency, and enhance efficiency by enabling quick and accurate capture of project-related information while supporting compliance and facilitating real-time collaboration"
                />
            </div>
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <DemoSection mergeTop={false} />
            </div>
        </div>
    )
}

export default FeaturesHomePage;
