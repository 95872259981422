import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterState: {}
};

export const DirectorySlice = createSlice({
  name: 'direcotry',
  initialState,
  reducers: {
    setDirctoryExportData: (state, { payload }) => {
      state.filterState = payload;
    }
  },
  extraReducers: (builder) => {}
});
export const { setDirctoryExportData } = DirectorySlice.actions;
export default DirectorySlice.reducer;
