import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ACTIONS from '../../actions';
import { API_SERVICE } from '../../services/CommonApi';
import submittalService from '../../services/Submittals';
import { resetFormFields } from './CreateSlice';

const initialState = {
  viewSubmittalResponse: false,
  updateSuccess: false,
  formData: {},
  viewFiles: [],
  viewData: {},
  submittalData: {},
  submittal_forward_review: false,
  closedSubmittal: false
};
export const onSaveViewSubmittals = createAsyncThunk(
  ACTIONS.SAVE_VIEW_SUBMITTALS,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.post(
        `${submittalService.saveViewSubmittalResponse(arg.projectId)}`,
        arg.payload,
        ''
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const onViewSubmittal = createAsyncThunk(
  ACTIONS.GET_VIEW_SUBMITTALS,
  async (arg, thunkAPI) => {
    try {
      thunkAPI.dispatch(resetFormFields());
      return API_SERVICE.get(
        `${submittalService.viewSubmittals(arg.projectId, arg.id)}`,
        arg.payload,
        ''
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const closeSubmittals = createAsyncThunk(
  ACTIONS.CLOSED_SUBMITTALS,
  async (arg, thunkAPI) => {
    console.log(arg);
    try {
      return API_SERVICE.patch(
        `${submittalService.closedSubmittal(arg.projectId, arg.id)}`,
        arg.payload,
        ''
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const onUpdateViewSubmittal = createAsyncThunk(
  ACTIONS.UPDATE_VIEW_SUBMITTALS,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.put(
        `${submittalService.updateViewSubmittal(arg.projectId, arg.id, arg.response_id)}`,
        arg.payload,
        true
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getSubmittalSettings = createAsyncThunk(
  ACTIONS.VIEW_SUBMITTALS_SETTINGS_ACTION,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.get(
        `${submittalService.viewSubmittalsSettings(arg.projectId, arg.submittalId)}`,
        arg.payload,
        ''
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const submittalForwardReview = createAsyncThunk(
  ACTIONS.SUBMITTAL_FORWARD_REVIEW,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.post(
        `${submittalService.submittalForwardReview(arg.projectId, arg.id)}`,
        arg.payload,
        ''
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const ViewSubmittal = createSlice({
  name: 'viewSubmittal',
  initialState,
  reducers: {
    resetStatus: () => initialState,
    resetAllStatus: (state, { payload }) => {
      state.viewSubmittalResponse = false;
      state.updateSuccess = false;
      state.submittal_forward_review = false;
      state.closedSubmittal = false;
    },
    onChangeSubmittalField: (state, { payload }) => {
      state.formData = { ...state.formData, ...payload };
    },
    onViewFileChange: (state, { payload }) => {
      const seen = new Set();
      const localFiles = [...state.viewFiles, ...payload.files];
      // remove duplicates from an array
      const filteredArr = localFiles.filter((el) => {
        const duplicate = seen.has(el.uid);
        seen.add(el.uid);
        return !duplicate;
      });
      return {
        ...state,
        viewFiles: filteredArr,
        viewSubmittalResponse: false
      };
    },
    onChangeResponseStatus: (state, { payload }) => {
      const response = [];
      const data = state.viewData;
      data.submittal_responses.length > 0 &&
        data.submittal_responses.map((v, i) => {
          if (v.id === payload.id) {
            response.push({ ...v, status: payload.status });
          } else response.push(v);
        });
      data.submittal_responses = response;
      state.viewData = data;
    },
    onChangeEditorValues: (state, { payload }) => {
      const response = [];
      const data = state.viewData;
      data.submittal_responses.length > 0 &&
        data.submittal_responses.map((v, i) => {
          if (v.id === payload.response_id) {
            response.push({ ...v, notes: payload.content, rich_text_notes: payload.content });
          } else response.push(v);
        });
      data.submittal_responses = response;
      state.viewData = data;
    },
    onViewFileRemove: (state, { payload }) => {
      const remains = state.viewFiles.filter((v) => v.uid !== payload.files.uid);
      return {
        ...state,
        viewFiles: remains
      };
    },
    resetAllFiles: (state, { payload }) => {
      return {
        ...state,
        viewFiles: []
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(onSaveViewSubmittals.fulfilled, (state, { meta, payload }) => {
        state.viewSubmittalResponse = payload.status === 200 || payload.status === 201;
      })
      .addCase(onViewSubmittal.fulfilled, (state, { meta, payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.viewData = payload.data.data;
          state.updateSuccess = false;
        }
      })
      .addCase(getSubmittalSettings.fulfilled, (state, { meta, payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.submittalData = payload.data.data;
        }
      })
      .addCase(onUpdateViewSubmittal.fulfilled, (state, { meta, payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.updateSubmittalData = payload.data.data;
          state.updateSuccess = true;
        }
      })
      .addCase(closeSubmittals.fulfilled, (state, { payload }) => {
        state.closedSubmittal = payload.status === 200 || payload.status === 201;
      })
      .addCase(submittalForwardReview.fulfilled, (state, { meta, payload }) => {
        state.submittal_forward_review = payload.status === 200 || payload.status === 201;
      });
  }
});

export const {
  resetStatus,
  onChangeSubmittalField,
  resetAllStatus,
  onChangeEditorValues,
  onViewFileChange,
  onViewFileRemove,
  onChangeResponseStatus,
  resetAllFiles
} = ViewSubmittal.actions;
export default ViewSubmittal.reducer;
