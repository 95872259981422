/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_SERVICE } from '../CommonApi';

export const useGetMasterListData = (pagination, filters, id) => {
  return useQuery({
    queryKey: ['master_list', id, pagination, filters],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${id}/master_schedules`, {
        page: pagination?.page,
        per_page: pagination?.per_page,
        filters
      });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useGetLookaheadListData = (pagination, filters, id) => {
  return useQuery({
    queryKey: ['lookahead_list', id, pagination, filters],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${id}/lookahead_schedules`, {
        page: pagination?.page,
        per_page: pagination?.per_page,
        filters
      });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};
export const useGetLookaheadArchiveData = (id, pagination) => {
  return useQuery({
    queryKey: ['lookahead_archive_list', id, pagination],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${id}/lookahead_schedules/archive_list`, {
        page: pagination?.page,
        per_page: pagination?.per_page
      });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};
export const useGetMasterArchiveData = (id, page = 1) => {
  return useQuery({
    queryKey: ['master_archive_list', id, page],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${id}/master_schedules/archive_list`, {
        page,
        per_page: 10
      });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useDeleteLookaheadList = (projectId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.delete(
        `projects/${projectId}/lookahead_schedules/${payload.id}`,
        payload,
        false
      );
    }
  });
};

export const useGetMasterScheduleDropdownData = (id) => {
  return useQuery({
    queryKey: [`master_schedule_base_data`, id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${id}/master_schedules/base_data`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetLookaheadViewData = (projectId, id) => {
  return useQuery({
    queryKey: [`lookahead_view_data`, projectId, id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${projectId}/lookahead_schedules/${id}`);
      return data;
    },
    enabled: !!projectId && !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetScheduleSettingsData = (projectId, settingId) => {
  return useQuery({
    queryKey: [`schedule_settings_data`, projectId, settingId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${projectId}/schedule_settings/${settingId}`);
      return data;
    },
    enabled: !!projectId && !!settingId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useUpdateScheduleSettings = (projectId, settingId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.putRequest(
        `projects/${projectId}/schedule_settings/${settingId}`,
        payload,
        false
      );
    }
  });
};

export const useUploadMasterRevision = (projectId, scheduleId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(
        `projects/${projectId}/master_schedules/${scheduleId}/upload_revision`,
        payload,
        true
      );
    }
  });
};

export const useUploadLookaheadRevision = (projectId, scheduleId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(
        `projects/${projectId}/master_schedules/${scheduleId}/upload_revision`,
        payload,
        true
      );
    }
  });
};

export const useGetMasterViewData = (projectId, id) => {
  return useQuery({
    queryKey: [`master_schedules_view_data`, projectId, id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${projectId}/master_schedules/${id}`);
      return data;
    },
    enabled: !!projectId && !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetLookaheadDropdownData = (id) => {
  return useQuery({
    queryKey: [`lookahead_base_data`, id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${id}/lookahead_schedules/base_data`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useDeleteMasterList = (projectId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.delete(
        `projects/${projectId}/master_schedules/${payload.id}`,
        payload,
        false
      );
    }
  });
};

export const useChangePermissionMasterList = (projectId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.put(
        `projects/${projectId}/master_schedules/${payload.id}/mark_as_private_or_public`,
        payload,
        false
      );
    }
  });
};
export const useDeleteMasterRevision = (projectId, scheduleId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.patchRequest(
        `projects/${projectId}/master_schedules/${scheduleId}/delete_revision`,
        payload,
        false
      );
    }
  });
};
export const useDeleteLookaheadRevision = (projectId, scheduleId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.patchRequest(
        `projects/${projectId}/master_schedules/${scheduleId}/delete_revision`,
        payload,
        false
      );
    }
  });
};

export const useUploadMaster = (projectId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(`projects/${projectId}/master_schedules`, payload, true);
    }
  });
};
export const useUploadLookahead = (projectId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(`projects/${projectId}/lookahead_schedules`, payload, true);
    }
  });
};

export const useAddCommentsMaster = (projectId, scheduleId, name = '') => {
  const pathname = name === 'Lookahead' ? 'lookahead_schedules' : 'master_schedules';
  return useMutation({
    mutationFn: ({ payload, id, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.put(
          `projects/${projectId}/${pathname}/${scheduleId}/comments/${id}`,
          payload,
          true
        );
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`projects/${projectId}/${pathname}/${scheduleId}/comments/${id}`);
      }
      return API_SERVICE.post(
        `projects/${projectId}/${pathname}/${scheduleId}/comments`,
        payload,
        true
      );
    }
  });
};

export const useGetScheduleActivityLogsData = (projectId, id, name = '', value = '') => {
  const pathname = name === 'Lookahead' ? 'lookahead_schedules' : 'master_schedules';
  return useQuery({
    queryKey: ['schedule_activity_list', projectId, id],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/${pathname}/${id}/schedule_activity_logs`
      );
      return data;
    },
    enabled: !!projectId && !!id && !!value,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useDeleteScheduleComments = (projectId, scheduleId, name = '') => {
  const pathname = name === 'Lookahead' ? 'lookahead_schedules' : 'master_schedules';
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.delete(
        `projects/${projectId}/${pathname}/${scheduleId}/comments/${payload?.id}`
      );
    }
  });
};

export const useGetScheduleCommentsData = (projectId, scheduleId, name = '', value = '') => {
  return useQuery({
    queryKey: ['schedule_comments', scheduleId, projectId],
    queryFn: async () => {
      const pathname = name === 'Lookahead' ? 'lookahead_schedules' : 'master_schedules';
      const data = await API_SERVICE.get(
        `projects/${projectId}/${pathname}/${scheduleId}/comments`
      );
      return data;
    },
    enabled: !!projectId && !!scheduleId && !!value,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useGetScheduleActivityData = (projectId, scheduleId, id, name = '') => {
  return useQuery({
    queryKey: ['schedule_activity_data', projectId, scheduleId, id],
    queryFn: async () => {
      const pathname = name === 'Lookahead' ? 'lookahead_schedules' : 'master_schedules';
      const data = await API_SERVICE.get(
        `projects/${projectId}/${pathname}/${scheduleId}/schedule_activity_logs/${id}`
      );
      return data;
    },
    enabled: !!projectId && !!scheduleId && !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
