import React, { useEffect } from 'react';

export default function Privacy() {
  useEffect(() => {
    // Function to dynamically load the script
    const loadScript = () => {
      const script = document.createElement('script');
      script.src = "https://app.termly.io/embed-policy.min.js";
      script.async = true;
      script.id = 'termly-jssdk';
      document.body.appendChild(script);
    };

    // Check if the script is already loaded to avoid duplicates
    if (!document.getElementById('termly-jssdk')) {
      loadScript();
    }

    // Optional: Clean up function to remove the script when the component unmounts
    return () => {
      const script = document.getElementById('termly-jssdk');
      if (script) {
        script.remove();
      }
    };
  }, []); // Empty array means this effect runs only once on mount

  return (
    <div>
      {/* Render the div for Termly embed */}
      <div data-id="ba197801-6bd2-48cf-a1e2-8db103488b8e" name="termly-embed" ></div>
    </div>
  );
}