/* eslint-disable consistent-return */
/* eslint-disable default-param-last */
/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_SERVICE } from '../CommonApi';

export const useGetDocumentDropdownData = (projectId, payload) => {
  return useQuery({
    queryKey: ['project-documents-dropdown', projectId, payload],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${projectId}/documents/base_data`, payload);
      return data;
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    refetchOnReconnect: false,
    refetchOnMount: false
  });
};
export const useGetProjectDocumentsListData = (
  projectId,
  pagination,
  filters,
  sort,
  parentCall
) => {
  return useQuery({
    queryKey: ['document_list', projectId, pagination, filters, sort, parentCall],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/list_folders_and_files`,
        pagination
          ? {
              page: pagination.page,
              per_page: pagination.per_page,
              filters,
              sort
            }
          : {}
      );
      return data;
    },
    enabled: !!projectId && !!parentCall,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetProjectFoldersListData = (
  projectId,
  pagination,
  filters,
  selectId = null,
  sort
) => {
  return useQuery({
    queryKey: ['folders_list', projectId, selectId, pagination, filters, sort],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/folders/${selectId}`,
        pagination
          ? {
              page: pagination.page,
              per_page: pagination.per_page,
              filters,
              sort
            }
          : {}
      );
      return data;
    },
    enabled: !!projectId && !!selectId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetSharedListData = (projectId, id, pagination, filters, sort, type) => {
  return useQuery({
    queryKey: ['shared_list', projectId, id, pagination, filters, sort, type],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/${type}/${id}/external_document_shares`,
        {
          page: pagination.page,
          per_page: pagination.per_page,
          filters,
          sort
        }
      );
      return data;
    },
    enabled: !!projectId && !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useGetActivityLogsData = (projectId, id, pagination, filters, type) => {
  return useQuery({
    queryKey: ['activity_list', projectId, id, pagination, filters, type],
    queryFn: async () => {
      const searchParam = type === 'folders' ? { folder_id: id } : { document_id: id };
      const data = await API_SERVICE.get(`projects/${projectId}/doc_folder_activity_logs`, {
        page: pagination.page,
        per_page: pagination.per_page,
        filters,
        ...searchParam
      });
      return data;
    },
    enabled: !!projectId && !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};
export const useModifyFolder = (projectId) => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, id, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.put(`projects/${projectId}/folders/${id}`, payload);
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`projects/${projectId}/folders/${payload.id}`);
      }
      return API_SERVICE.post(`projects/${projectId}/folders`, payload);
    }
    // onSuccess: () => {
    //   queryClient.invalidateQueries({ queryKey: ['document_list', projectId] });
    // }
  });
};

export const useModifyMarkAsPrivatePublic = (projectId) => {
  return useMutation({
    mutationFn: ({ payload, id, type }) => {
      return API_SERVICE.put(`projects/${projectId}/${type}/${id}`, payload);
    }
  });
};

export const useModifyDocumentName = (projectId, isExternal = false) => {
  return useMutation({
    mutationFn: ({ payload, id }) => {
      return isExternal
        ? API_SERVICE.put(
            `external_documents/${projectId}/update_document?document_id=${id}`,
            payload
          )
        : API_SERVICE.put(`projects/${projectId}/documents/${id}`, payload);
    }
  });
};
export const useModifyDocuments = (projectId) => {
  return useMutation({
    mutationFn: ({ payload, id, action = 'create' }) => {
      if (action === 'delete') {
        return API_SERVICE.delete(`projects/${projectId}/documents/${payload.id}`);
      }
      return API_SERVICE.post(`projects/${projectId}/documents`, payload, true);
    }
  });
};

export const useModifyUploadFolder = (projectId) => {
  return useMutation({
    mutationFn: ({ payload, id, action = 'create' }) => {
      if (action === 'delete') {
        return API_SERVICE.delete(`projects/${projectId}/documents/${payload.id}`);
      }
      return API_SERVICE.post(`projects/${projectId}/folders/upload_directory`, payload, true);
    }
  });
};

export const useModifyExternalUpload = (parentId, id) => {
  return useMutation({
    mutationFn: ({ payload, action = 'create' }) => {
      if (action === 'delete') {
        return API_SERVICE.delete(`external_documents/${id}/documents/${payload.id}`);
      }
      return API_SERVICE.post(
        `external_documents/${id}/upload_directory?parent_id=${parentId}`,
        payload,
        true
      );
    }
  });
};

export const useModifyExternalFileUpload = (id, parentId) => {
  return useMutation({
    mutationFn: ({ payload, action = 'create' }) => {
      if (action === 'delete') {
        return API_SERVICE.delete(`external_documents/${id}?document_id=${payload.id}`);
      }
      return API_SERVICE.post(
        `external_documents/${id}/create_document?parent_id=${parentId}`,
        payload,
        true
      );
    }
  });
};

export const useModifyExternalFolder = (externalId, parentId) => {
  return useMutation({
    mutationFn: ({ payload, id, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.put(
          `external_documents/${externalId}/update_folder?folder_id=${id}`,
          payload
        );
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`external_documents/${externalId}?folder_id=${payload.id}`);
      }
      return API_SERVICE.post(
        `external_documents/${externalId}/create_folder?parent_id=${parentId}`,
        payload
      );
    }
  });
};

export const useShareFolder = (projectId, id) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(
        `projects/${projectId}/folders/${id}/external_document_shares`,
        payload
      );
    }
  });
};

export const useShareDocument = (projectId, id) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(
        `projects/${projectId}/documents/${id}/external_document_shares`,
        payload
      );
    }
  });
};

export const useGetExternalDocumentsData = (pagination, filters, id, parentId, sort) => {
  return useQuery({
    queryKey: ['external_document_list', id, pagination, filters, parentId, sort],
    queryFn: async () => {
      const data = await API_SERVICE.get(`external_documents/${id}`, {
        page: pagination.page,
        per_page: pagination.per_page,
        filters,
        parent_id: parentId,
        sort
      });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useGetExternalUsersList = (projectId, parentId, type) => {
  return useQuery({
    queryKey: [`external_users_list`, projectId, parentId, type],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/${type}/${parentId}/external_document_shares/list_external_users`
      );
      return data;
    },
    enabled: !!projectId && !!parentId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useGetFolderViewDetails = (projectId, parentId, type) => {
  return useQuery({
    queryKey: [`view_doc_details`, projectId, parentId, type],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/${type}/${parentId}/view_doc_details?document_type=${type}`
      );
      return data;
    },
    enabled: !!projectId && !!parentId && !!type,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetExternalUsersDataList = (
  projectId,
  parentId,
  pagination,
  filters,
  selectedParent,
  sort
) => {
  return useQuery({
    queryKey: [
      `external_users_data_list`,
      projectId,
      parentId,
      pagination,
      filters,
      selectedParent,
      sort
    ],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/external_document_shares/${parentId}/list_folders_and_files`,
        {
          page: pagination.page,
          per_page: pagination.per_page,
          filters,
          parent_id: selectedParent,
          sort
        }
      );
      return data;
    },
    enabled: !!projectId && !!parentId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useGetSharedListDropdownData = (projectId, id, type) => {
  return useQuery({
    queryKey: [`shared_list_base_data`, projectId, id, type],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/${type}/${id}/external_document_shares/base_data`
      );
      return data;
    },
    enabled: !!id && !!projectId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useGetExternalResponseUserViewDetail = (projectId, userId) => {
  return useQuery({
    queryKey: [`external_response_user_view_detail`, projectId, userId],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/external_document_shares/${userId}`
      );
      return data;
    },
    enabled: !!userId && !!projectId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
