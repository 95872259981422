import { RightOutlined } from '@ant-design/icons';
import { Breadcrumb, Space } from 'antd';
import React from 'react';

const ParentsHistory = ({ parents = [], setParents, handleHomeClick }) => {
  const parentsLength = parents.length;
  const handleParents = (id) => {
    setParents((prev) => {
      const index = prev.findIndex((e) => e.id === id);
      return prev.slice(0, index + 1);
    });
  };
  if (!parentsLength) return null;
  return (
    <Breadcrumb separator={<RightOutlined />}>
      <Breadcrumb.Item
        onClick={() => {
          setParents([]);
          if (handleHomeClick) {
            handleHomeClick();
          }
        }}
        style={{ cursor: 'pointer' }}>
        <Space>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <g clipPath="url(#clip0_12827_21005)">
              <path
                d="M5 12H3L12 3L21 12H19"
                stroke="#5C5C5C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 12V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V12"
                stroke="#5C5C5C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 12H14V16H10V12Z"
                stroke="#5C5C5C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_12827_21005">
                <rect width="24" height="24" rx="4" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div>Home</div>
        </Space>
      </Breadcrumb.Item>
      {parents?.map((item, index) => (
        <Breadcrumb.Item
          style={{ cursor: 'pointer' }}
          onClick={() => parentsLength - 1 !== index && handleParents(item.id)}>
          {item.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default ParentsHistory;
