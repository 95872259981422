/* eslint-disable no-unused-expressions */
import { notification } from 'antd';
import React from 'react';
import errorIcon from '../fonts/t-error.svg';

// eslint-disable-next-line import/prefer-default-export
export const showNotification = (type, message, description) => {
  notification[type]({
    message: message,
    // description: description,
    icon:
      type === 'error' ? (
        <img src={errorIcon} alt="success" />
      ) : (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 12.7686L10 17.7686L20 7.76855"
            stroke="#009E1F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    className: `toast-class ${type}`,
    duration: 3,
    placement: 'bottomLeft'
  });
};

export const showErrors = (response, setError, showInfo = false) => {
  if (response.hasOwnProperty('errors')) {
    showNotification('error', response?.errors?.toString());
  } else if (response.hasOwnProperty('error')) {
    showInfo ? showNotification('error', response?.error) : setError(response?.error);
  }
};
