/* eslint-disable camelcase */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_SERVICE } from '../CommonApi';

export const useGetProjectLocationData = (projectId, filters, page, per_page) => {
  return useQuery({
    queryKey: [`project_location`, projectId, filters, page, per_page],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${projectId}/project_locations`, {
        filters,
        page,
        per_page
      });
      return data;
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useGetProjectLocationQRCodeData = (locationId, qrId) => {
  return useQuery({
    queryKey: [`project_location_qr_code`, locationId, qrId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`project_locations/${locationId}/qr_codes/${qrId}`);
      return data;
    },
    enabled: !!locationId && !!qrId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useModifyProjectLocation = (projectId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, action = 'create', id }) => {
      if (action === 'update') {
        return API_SERVICE.put(`projects/${projectId}/project_locations/${id}`, payload, true);
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`projects/${projectId}/project_locations/${payload.id}`);
      }
      return API_SERVICE.post(`projects/${projectId}/project_locations`, payload, true);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ['project_location', projectId]
      });
    }
  });
};

export const useGetParentLocationData = (projectId) => {
  return useQuery({
    queryKey: [`parent_location`, projectId],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/project_locations?view_type=basic_data`
      );
      return data;
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useGetChildLocationData = (projectId, parentId, query) => {
  return useQuery({
    queryKey: [`child_location`, projectId, parentId, query],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/project_locations/${parentId}/sub_locations`,
        query
      );
      return data;
    },
    enabled: !!projectId && !!parentId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useGetLocationData = (projectId, id) => {
  return useQuery({
    queryKey: [`location_data`, projectId, id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${projectId}/project_locations/${id}`);
      return data;
    },
    enabled: !!projectId && !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useGetLocationArchiveList = (pagination, filters, id, sort) => {
  return useQuery({
    queryKey: ['location_archive_list', id, pagination, filters, sort],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${id}/project_locations/archive_list`, {
        page: pagination.page,
        per_page: pagination.per_page,
        filters,
        sort
      });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useRestoreArchiveLocation = (projectId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.patchRequest(
        `projects/${projectId}/project_locations/${payload.id}/restore`,
        payload,
        false
      );
    }
  });
};

export const useGetLocationBulkQRCodeList = (id) => {
  return useQuery({
    queryKey: ['location_bulk_qr_list', id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${id}/project_locations?view_type=qr_code_list`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true
  });
};

export const useLocationBulkUpload = (projectId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(
        `projects/${projectId}/project_locations/import`,
        payload,
        true
      );
    }
  });
};

export const useGetLocationRelatedItems = (pagination, filters, id, projectId, sort) => {
  return useQuery({
    queryKey: ['location_related_item_list', id, projectId, pagination, filters, sort],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `projects/${projectId}/project_locations/${id}/related_items`,
        {
          page: pagination.page,
          per_page: pagination.per_page,
          filters,
          sort
        }
      );
      return data;
    },
    enabled: !!id && !!projectId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useDeleteLocationAttachments = (projectId, locationId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.delete(
        `projects/${projectId}/project_locations/${locationId}/remove_file/${payload.id}`
      );
    }
  });
};
