import styled from 'styled-components';

const HeaderWrapper = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  background: #ffffff;
  justify-content: space-betweem;
  align-items: center;
  padding: 0px 20px;
  gap: 12px;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #d3d3d3;
  justify-content: space-between;
  .crumbs {
    flex: 1;
    .crumbs-item {
      background: #f6f6f6;
      display: flex;
      align-items: center;
      border-radius: 4px;
      color: #3b3b3c;
      height: 44px;
      .p-name {
        padding: 0px 12px;
        text-transform: uppercase;
      }
    }
    .icon {
      background: #f6f6f6;
      height: 100%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 13px 11px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: #ececec;
      }

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  .header-menu {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;

    li {
      margin-left: 12px;
      cursor: pointer;

      .icon {
        background: #f6f6f6;
        font-size: 25px;
        border-radius: 50%;
        color: #909090;
        padding: 10px;
        display: block;
        width: 45px;
        text-align: center;

        &:hover {
          background: #ececec;
        }
      }

      .avatar-img .ant-avatar-lg {
        width: 45px;
        height: 45px;
      }
    }
  }

  .profile {
    align-items: center;
    display: flex;
    gap: 12px;

    .details {
      display: flex;
      flex-direction: column;
      text-align: right;

      h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: #080808;
        margin: 0;
      }

      p {
        margin: 0;
        color: #fc8543;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .app {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px;
    background: #f6f6f6;
    border-radius: 50%;

    &:hover {
      background: #ececec;
    }

    svg {
      height: 25px;
      width: 25px;
      fill: #909090;
    }
  }
`;

export default HeaderWrapper;
