const getSubmittalGeneralSetting = (projectId, submittalSettingId) =>
  `projects/${projectId}/submittal_settings/${submittalSettingId}`;

const updateSubmittalGeneralSetting = (projectId, submittalSettingId) =>
  `projects/${projectId}/submittal_settings/${submittalSettingId}`;

const getListSubmittalTypes = (projectId) => `projects/${projectId}/submittal_types`;

const updateSubmittalType = (projectId, submittalTypeId) =>
  `projects/${projectId}/submittal_types/${submittalTypeId}`;

const createSubmittalType = (projectId) => `projects/${projectId}/submittal_types`;

const getSubmittalCustomFields = (projectId) => `projects/${projectId}/submittal_custom_fields`;

const createSubmittalCustomField = (projectId) => `projects/${projectId}/submittal_custom_fields`;

const deleteSubmittalCustomField = (projectId, customFieldId) =>
  `projects/${projectId}/submittal_custom_fields/${customFieldId}`;

const updateSubmittalCustomFields = (projectId, customFieldId) =>
  `projects/${projectId}/submittal_custom_fields/${customFieldId}`;

const getListWorkflows = (projectId) => `projects/${projectId}/submittal_workflows`;

const deleteSubmittalWorkflow = (projectId, workflowId) =>
  `projects/${projectId}/submittal_workflows/${workflowId}`;

const createSubmittalWorkflow = (projectId) => `projects/${projectId}/submittal_workflows`;

const updateSubmittalWorkflow = (projectId, workflowId) =>
  `projects/${projectId}/submittal_workflows/${workflowId}`;

const getListSubmittals = (projectId) => `projects/${projectId}/submittals`;

export default {
  getSubmittalGeneralSetting,
  updateSubmittalGeneralSetting,
  getListSubmittalTypes,
  updateSubmittalType,
  createSubmittalType,
  getSubmittalCustomFields,
  createSubmittalCustomField,
  deleteSubmittalCustomField,
  updateSubmittalCustomFields,
  getListWorkflows,
  deleteSubmittalWorkflow,
  createSubmittalWorkflow,
  updateSubmittalWorkflow,
  getListSubmittals
};
