const getProjectDetails = (projectId) => `projects/${projectId}`;
const createProject = () => `projects`;
const updateProject = (id) => `projects/${id}`;
const getProjectEditDetails = (projectId) => `projects/${projectId}?type=form`;

export default {
  getProjectDetails,
  createProject,
  updateProject,
  getProjectEditDetails
};
