import moment from 'moment';

export default {
  setRequired: (fieldName = 'Field') => [
    {
      required: true,
      message: `${fieldName} is required`
    }
  ],
  required: [
    {
      required: true,
      message: ''
    }
  ],
  startTime: [
    {
      required: true,
      message: ''
    },
    (formInstance) => ({
      validator(_, value) {
        const endTime = formInstance.getFieldValue('end_time');
        if (!value || !endTime) return Promise.resolve();
        const isSame = value.format('h:mm A') === endTime.format('h:mm A');
        if (value.isSameOrAfter(endTime) || isSame) {
          return Promise.reject(new Error('Start time Must not be same or greater than end time'));
        }
        return Promise.resolve();
      }
    })
  ],
  endTime: [
    {
      required: true,
      message: ''
    },
    (formInstance) => ({
      validator(_, value) {
        const startTime = formInstance.getFieldValue('start_time');
        if (!value || !startTime) return Promise.resolve();
        const isSame = value.format('h:mm A') === startTime.format('h:mm A');
        if (value.isSameOrBefore(startTime) || isSame) {
          return Promise.reject(new Error('End time Must not be same or lessthan start time'));
        }
        return Promise.resolve();
      }
    })
  ],
  start: [
    {
      required: false,
      message: ''
    },
    (formInstance) => ({
      validator(_, value) {
        const endTime = formInstance.getFieldValue('end_time');
        if (!value || !endTime) return Promise.resolve();
        const isSame = value.format('h:mm A') === endTime.format('h:mm A');
        if (value.isSameOrAfter(endTime) || isSame) {
          return Promise.reject(new Error('Start time Must not be same or greater than end time'));
        }
        return Promise.resolve();
      }
    })
  ],
  end: [
    {
      required: false,
      message: ''
    },
    (formInstance) => ({
      validator(_, value) {
        const startTime = formInstance.getFieldValue('start_time');
        if (!value || !startTime) return Promise.resolve();
        const isSame = value.format('h:mm A') === startTime.format('h:mm A');
        if (value.isSameOrBefore(startTime) || isSame) {
          return Promise.reject(new Error('End time Must not be same or lessthan start time'));
        }
        return Promise.resolve();
      }
    })
  ],
  idleTime: [
    {
      required: false,
      message: ''
    },
    (formInstance) => ({
      validator(_, value) {
        const startTime = formInstance.getFieldValue('start_time');
        const endTime = formInstance.getFieldValue('end_time');
        if (!value) return Promise.resolve();
        const difference = moment.duration(endTime.diff(startTime, undefined, true));
        const isGreater = parseFloat(value.format('HH.mm')) >= difference.hours();
        if (isGreater) {
          return Promise.reject(new Error('Idle time must not be more than worked hours'));
        }
        return Promise.resolve();
      }
    })
  ],
  responseAttribute: [
    {
      required: true,
      message: ''
    },
    (formInstance) => ({
      validator(data, value) {
        const selectedFieldIndex = data?.field?.split('.')[1];
        const attributes = formInstance.getFieldValue('submittal_responses_attributes');
        const selectedAtribute = attributes[selectedFieldIndex];
        console.log(selectedFieldIndex, attributes, selectedAtribute);
        if (!value || !selectedAtribute?.user_id) return Promise.resolve();
        const isIncludes = attributes.filter(
          (e, index) =>
            e.user_id === selectedAtribute?.user_id && index !== Number(selectedFieldIndex)
        );
        if (isIncludes?.length) {
          return Promise.reject(new Error('User was already selected'));
        }
        return Promise.resolve();
      }
    })
  ],
  email: [
    {
      type: 'email',
      message: 'Please enter valid email.'
    },
    {
      required: true,
      message: 'Email field is required.'
    }
  ],
  noOfWorkers: [
    {
      required: true,
      message: ''
    },
    () => ({
      validator(_, value) {
        if (!value) {
          return Promise.reject(new Error('No. Of Workers field is required'));
        }
        if (Number(value) > 10000) {
          return Promise.reject(new Error('Must be lessthan 10000'));
        }
        return Promise.resolve();
      }
    })
  ]
};
