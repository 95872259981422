import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ACTIONS from '../actions';
import punchService from '../services/PunchList';
import { API_SERVICE } from '../services/CommonApi';

const initialState = {
  punchList: {
    data: []
  },
  punchListFilters: [],
  punchListStatus: false,
  deletePunchStatus: false,
  punchListError: '',
  closedPunchlist: ''
};
export const getViewPunchList = createAsyncThunk(
  ACTIONS.GET_PUNCH_LIST_ACTION,
  async (arg, thunkAPI) => {
    try {
      const payload = arg.filters
        ? { page: arg.page, per_page: 5, filters: arg.searchData, sort: arg.sortData }
        : {};
      return API_SERVICE.get(`${punchService.getPunchList(arg.projectId)}`, payload);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deletePunchList = createAsyncThunk(ACTIONS.DELETE_PUNCHLIST, async (arg, thunkAPI) => {
  try {
    // thunkAPI.dispatch(getViewPunchList(arg.projectId));
    return await API_SERVICE.delete(
      `${punchService.viewPunchList(arg.projectId, arg.punchListId)}`
    );
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const closedPunchList = createAsyncThunk(ACTIONS.MARK_AS_CLOSED, async (arg, thunkAPI) => {
  try {
    const result = await API_SERVICE.patch(
      `${punchService.markAsClosed(arg.projectId, arg.punchListId)}`
    );
    // thunkAPI.dispatch(getViewPunchList(arg.projectId));
    return result;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const PunchListSlice = createSlice({
  name: 'PUNCH',
  initialState,
  reducers: {
    resetPunchListStatus: (state, action) => {
      state.punchListStatus = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getViewPunchList.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.punchList = {
          data: payload.data.data,
          ...payload.headers
        };
      }
    });
  }
});

export const { resetPunchListStatus } = PunchListSlice.actions;
export default PunchListSlice.reducer;
