import { Col, Row, Form, Input, Button } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import frame from '../../images/Frame.svg';
import group from '../../images/Group.svg';
import APICONST from '../../constants';

const Login = () => {
  const navigate = useNavigate();
  const [errorMessage, setError] = useState(null);
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!'
    }
  };

  const onFinish = async (values) => {
    setError(null);
    const url = `${APICONST.LOGIN}`;

    const params = {
      user: {
        email: values.email,
        password: values.password,
        push_token: '',
        platform_name: 'web'
      }
    };

    await axios
      .post(url, params)
      .then((r) => {
        if (r.status === 200) {
          Cookies.set('auth-token', r.data.auth_token, { expires: 1 });
          Cookies.set('company_id', r.data.user.company.unique_reference_id, { expires: 1 });
          Cookies.set('company_name', r.data.user.company.name, { expires: 1 });
          Cookies.set('user', r.data.user.full_name, { expires: 1 });
          Cookies.set('userInfo', JSON.stringify(r.data.user, { expires: 1 }));
          Cookies.set('user_id', r.data.user.unique_reference_id, { expires: 1 });
          navigate('/dashboard');
        }
      })
      .catch((err) => setError(err.response?.data?.error));
  };
  return (
    <div className="login">
      <Row>
        <Col span={16}>
          <img src={frame} className="login-banner" />
        </Col>
        <Col span={8} className="login-content">
          <img className="group-img" src={group} />
          <h2>Log in to your Account</h2>
          <Form
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
            validateMessages={validateMessages}>
            <Form.Item
              name="email"
              label="User Name/Email"
              rules={[{ required: true, message: 'User name/email is required' }]}>
              <Input
                placeholder="User Name"
                className={`${
                  errorMessage?.user_authentication ? 'ant-input ant-input-status-error' : ''
                }`}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: 'Password is required' }]}>
              <Input.Password
                placeholder="Password"
                style={{
                  display: errorMessage?.user_authentication ? 'inline-flex' : ''
                }}
                className={`${
                  errorMessage?.user_authentication ? 'ant-input ant-input-status-error' : ''
                }`}
              />
            </Form.Item>
            <Form.Item>
              <p style={{ paddingBottom: '5px', textAlign: 'right' }}>
                <a onClick={() => navigate('/forgot_password')}>Forget your password ?</a>
              </p>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ height: '42px' }}>
                Login
              </Button>
              <p style={{ padding: '10px 0' }}>
                Don't have an account ? <a onClick={() => navigate('/signup')}>Click Here</a>
              </p>
              {errorMessage && (
                <p>
                  <ExclamationCircleOutlined style={{ color: '#ff4d4f', marginRight: '5px' }} />
                  <span className="ant-form-item-explain-error" style={{ fontSize: '12px' }}>
                    {errorMessage?.user_authentication}
                  </span>
                </p>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
export default Login;
