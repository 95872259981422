import styled from "styled-components";
import { Layout, Row } from "antd";
const { Header } = Layout;

export const StyledHeader = styled(Header)`
    background-color: #fcf2ec;
    position: sticky;
    top:  0;
    z-index: 2;
    width: 100%;
    height: 80px;
    padding: 0 24px;

    @media screen and (max-width: 1125px) {
        /* Mobile View CSS */
        .is-not-mobile {
            display: none;
        }
    }
    
    @media screen and (min-width: 1125px) {
        /* Desktop View CSS */
        .is-mobile {
            display: none;
        }
    }

    .ant-menu-item-selected::after, .ant-menu-item:hover::after, .ant-menu-item:visited::after{
        display: none;
    }

    .ant-menu-horizontal > .ant-menu-item::after {
        display: none !important;
    }

    .ant-menu-title-content {
        font-weight: bold;
        font-size: 16px;
    }
`

export const HeaderRow = styled(Row)`
    max-width: 1400px;
    padding: 0 24px;
`