/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Typography, Input, Checkbox, Select, Modal, Form } from 'antd';

import {
  HolderOutlined,
  EditOutlined,
  CopyOutlined,
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
  SettingOutlined,
  EyeInvisibleOutlined
} from '@ant-design/icons';
import { ReactComponent as ViewIcon } from '../../fonts/view.svg';
import { ReactComponent as DeleteIcon } from '../../fonts/delete-custom.svg';
import { ReactComponent as CalendarIcon } from '../../fonts/calendar.svg';
import { ReactComponent as TextInputIcon } from '../../fonts/Text_input.svg';
import { ReactComponent as DropdownIcon } from '../../fonts/Dropdown.svg';
import { ReactComponent as RequiredIcon } from '../../fonts/required.svg';
import { ReactComponent as EditIcon } from '../../fonts/Edit.svg';
import { ReactComponent as AddIcon } from '../../fonts/add-orange.svg';

const { Text } = Typography;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const CancelButton = styled(Button)`
  background: #ececec;
`;

export const SettingPageWrapper = styled.div`
  padding-top: 8px;
  padding-left: 24px;
  .settings-form {
    .ant-form-item-label {
      flex-basis: 40%;
      text-align: left;
    }
  }
  .settings-container {
    & > div {
    &:first-child {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      border-bottom: none;      
    }
    &:last-child {
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }
}
`;

export const Divider = styled.div`
  margin-top: 18px;
  border-top: 1px solid #d3d3d3;
`;

const FlexBox = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  &.flex-1 {
    flex: 1;
  }
`;

const TypeConfigWrapper = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
  width: 100%;

  .ant-input {
    font-weight: 600;
  }
  justify-content: space-between;
`;

const TypeConfigDivider = styled.div`
  margin: 6px 0px;
  border-bottom: 1px solid #ececec;
`;

const TypeConfigActionWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

const CustomFieldWrapper = styled.div`
  display: flex;
  height: ${(props) => (props.isEditing ? '105px' : '58px')};
  border-bottom: 1px solid #ececec;
  align-items: center;
  padding-left: 30px;
`;

const FieldNameWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #080808;
  flex: 1 1 0;
`;

const FieldTypeContainer = styled.div`
  border-radius: 24px;
  background-color: #f6f6f6;
  padding: 6px 12px;
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #909090;
`;

const ValueWrapper = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #fc8543;
  text-decoration: underline;
  cursor: pointer;
`;

const EditingCustomFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0px 24px 30px;
  border-bottom: 1px solid #ececec;
  justify-content: space-between;
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-basis: 33.33%;
  justify-content: center;
`;

const ConfirmButton = styled(Button)`
  color: #009e1f;
  border-color: #009e1f;
`;

const CloseButton = styled(Button)`
  color: #ff3030;
  border-color: #ff3030;
`;

const EditingWrapper = styled.div`
  height: 62px;
  display: flex;
  justify-content: space-between;
  padding: 15px 32px;
`;

const EditingActionWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export function SectionTitle({ title, onCancel, onUpdate, enableAction = true, allow_to_edit_settings }) {
  return (
    <TitleWrapper>
      <Typography.Title level={4}>{title}</Typography.Title>
      {enableAction && allow_to_edit_settings && (
        <ButtonWrapper>
          <CancelButton onClick={onCancel} type="text">
            <Text strong>Cancel</Text>
          </CancelButton>
          <Button onClick={onUpdate} type="primary">
            Update
          </Button>
        </ButtonWrapper>
      )}
    </TitleWrapper>
  );
}

export function TypeConfig({ config, isVisble, isEditing, onEdit, onConfirm, onCancel, onDelete }) {
  const [value, setValue] = useState(config.title);
  const handleOnInputChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <>
      {isEditing ? (
        <EditingWrapper>
          <Input
            onChange={handleOnInputChange}
            value={value}
            style={{ flexBasis: '66%' }}
            placeholder="Enter Value"
          />
          <EditingActionWrapper>
            <ConfirmButton icon={<CheckOutlined />} onClick={() => onConfirm(config.id, value)} />
            <CloseButton icon={<CloseOutlined />} onClick={onCancel} />
          </EditingActionWrapper>
        </EditingWrapper>
      ) : (
        <div>
          <TypeConfigWrapper>
            <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
              <HolderOutlined style={{ fontSize: '16px' }} />
              {config.title}
            </div>
            <TypeConfigActionWrapper>
              <EditIcon
                style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                onClick={() => onEdit(config.id)}
              />
              <ViewIcon style={{ cursor: 'pointer' }} />
              <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => onDelete(config.id)} />
            </TypeConfigActionWrapper>
          </TypeConfigWrapper>
          <TypeConfigDivider />
        </div>
      )}
    </>
  );
}

export function CustomField({ isEditing, field, onDelete, onEdit, onCopy, onView, permissions, allow_edit }) {
  const {
    edit_custom_field = false,
    delete_custom_field = false,
    clone_custom_field = false,
    enable_disable_custom_field = false
  } = permissions;
  const [isModalOpen, setModelOpen] = useState(false);
  const getIcon = (type) => {
    switch (type) {
      case 'date':
        return (
          <FieldTypeContainer>
            <CalendarIcon />
            <span>Date format</span>
          </FieldTypeContainer>
        );
      case 'text':
        return (
          <FieldTypeContainer>
            <TextInputIcon />
            <span>Text Input</span>
          </FieldTypeContainer>
        );
      case 'dropdown':
        return (
          <FieldTypeContainer>
            <DropdownIcon />
            <span>Dropwdown</span>
          </FieldTypeContainer>
        );
      default:
        return null;
    }
  };

  const handleValuesView = () => {
    setModelOpen(true);
  };

  const handleCopy = () => {
    if (onCopy) {
      onCopy(field);
    }
  };

  const valuesAmount = field.input_options?.length;

  return (
    <>
      <tr data-row-key="1" className="ant-table-row ant-table-row-level-0">
        <td className="ant-table-cell">
          <DropdownValuesModal
            isOpen={isModalOpen}
            onCancel={() => setModelOpen(false)}
            isPreview
            values={field.input_options}
          />
          <FlexBox className="flex-1">
            {/* <HolderOutlined style={{ fontSize: '16px' }} /> */}
            <FieldNameWrapper>
              {field.name}
              {field.is_required && <RequiredIcon style={{ marginLeft: '6px' }} />}
            </FieldNameWrapper>
          </FlexBox>
        </td>
        <td className="ant-table-cell">
          <FlexBox className="flex-1">
            {getIcon(field.input_type)}

            {valuesAmount > 0 && (
              <ValueWrapper onClick={handleValuesView}>{`${valuesAmount} Values`}</ValueWrapper>
            )}
          </FlexBox>
        </td>
        <td className="ant-table-cell">
          <FlexBox style={{ justifyContent: 'center' }} className="flex-1">
            {(edit_custom_field || allow_edit) && <Button onClick={() => onEdit(field.id)} type="text" icon={<EditOutlined />} />}
            {(clone_custom_field || allow_edit) && <Button type="text" onClick={handleCopy} icon={<CopyOutlined />} />}
            {(enable_disable_custom_field || allow_edit) && <Button
              // disabled={!field.is_visible}
              type="text"
              onClick={() => onView(field)}
              icon={!field.is_visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            />}
            {(delete_custom_field || allow_edit) && <Button onClick={() => onDelete(field.id)} type="text" icon={<DeleteIcon />} />}
          </FlexBox>
        </td>
      </tr>
      {/* <CustomFieldWrapper isEditing={isEditing}>
      <DropdownValuesModal
        isOpen={isModalOpen}
        onCancel={() => setModelOpen(false)}
        isPreview
        values={field.input_options}
      />
      <FlexBox className="flex-1">
        <FieldNameWrapper>
          {field.name}
          {field.is_required && <RequiredIcon style={{ marginLeft: '6px' }} />}
        </FieldNameWrapper>
      </FlexBox>
      <FlexBox className="flex-1">
        {getIcon(field.input_type)}

        {valuesAmount > 0 && (
          <ValueWrapper onClick={handleValuesView}>{`${valuesAmount} Values`}</ValueWrapper>
        )}
      </FlexBox>
      <FlexBox style={{ justifyContent: 'center' }} className="flex-1">
        <Button onClick={() => onEdit(field.id)} type="text" icon={<EditOutlined />} />
        <Button type="text" onClick={handleCopy} icon={<CopyOutlined />} />
        <Button
          type="text"
          onClick={() => onView(field)}
          icon={!field.is_visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        />
        <Button onClick={() => onDelete(field.id)} type="text" icon={<DeleteIcon />} />
      </FlexBox>
    </CustomFieldWrapper> */}
    </>
  );
}

const DropdownValuesWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fc8543;
  text-decoration: underline;
  gap: 6px;
  cursor: pointer;
`;

const AddValueButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  gap: 6px;
  color: #fc8543;
  cursor: pointer;
`;

const DropdownValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
`;

const DropdownValuesModal = ({ isOpen, onOk, onCancel, values, isPreview }) => {
  const [dropdownValues, setDropdownValues] = useState(values);
  const handleOnConfirmAdd = () => {
    if (onOk) onOk(dropdownValues);
  };

  const handleOnCancelAdd = () => {
    setDropdownValues(values);
    if (onCancel) onCancel();
  };

  const handleAddValue = () => {
    const newData = [...dropdownValues, ''];
    setDropdownValues(newData);
  };

  // eslint-disable-next-line consistent-return
  const handleChange = (value, index) => {
    if (isPreview) return false;
    const newData = [...dropdownValues];
    newData[index] = value;
    setDropdownValues(newData);
  };

  const handleDelete = (index) => {
    const newData = [...dropdownValues];
    newData.splice(index, 1);
    setDropdownValues(newData);
  };
  const extraProps = {};
  if (isPreview) {
    extraProps.footer = null;
  }
  return (
    <Modal
      title="Dropdown Value"
      open={isOpen}
      {...extraProps}
      onCancel={handleOnCancelAdd}
      onOk={handleOnConfirmAdd}>
      {dropdownValues.map((value, index) => (
        <DropdownValueWrapper>
          {/* <HolderOutlined /> */}
          <Input
            value={value}
            onChange={(e) => {
              handleChange(e.target.value, index);
            }}
          />
          {!isPreview && (
            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => handleDelete(index)} />
          )}
        </DropdownValueWrapper>
      ))}
      {!isPreview && (
        <AddValueButton onClick={handleAddValue}>
          <AddIcon />
          Add Value
        </AddValueButton>
      )}
    </Modal>
  );
};

export function EditingCustomField({ data, onCancel, onConfirm }) {
  const [inputValue, setInputValue] = useState(data?.name || '');
  const [isRequired, setIsRequired] = useState(data?.is_required || false);
  const [fieldType, setFieldType] = useState(data?.input_type || 'text');
  const [isModalOpen, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [dropdownValues, setDropdownValues] = useState(data?.input_options || []);

  const handleOnConfirm = () => {
    if(inputValue !== '') {
      const payload = {
        name: inputValue,
        input_type: fieldType,
        input_options: fieldType === 'dropdown' ? dropdownValues : [],
        is_required: isRequired
      };
      if (data?.id) {
        onConfirm({ id: data?.id, payload });
      } else {
        onConfirm({ payload });
      }
    } else setError(true)
    
  };

  const handleAddDropdownValues = (values) => {
    setDropdownValues(values);
    setOpenModal(false);
  };

  return (
    <>
      <tr data-row-key="1" className="ant-table-row ant-table-row-level-0">
        <td className="ant-table-cell">
          <FlexBox>
          <div className='d-flex flex-row position-relative'>
            <Input
              className={error && 'ant-input-status-error'}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              style={{ width: 'auto' }}
              placeholder="Custom Field Name"
            />
            {
              error && <div style={{ position: 'absolute', bottom: 0 }} className="ant-form-item-explain-error">Please enter field name</div>
            }
          </div>
            
            <Checkbox
              checked={isRequired}
              onChange={(e) => {
                setIsRequired(e.target.checked);
              }}>
              Required Field
            </Checkbox>
          </FlexBox>
        </td>
        <td className="ant-table-cell">
          {/* <FlexBox> */}
          <Select
            showSearch
            placeholder="Field Type"
            optionFilterProp="children"
            value={fieldType}
            onChange={(value) => {
              setFieldType(value);
            }}
            options={[
              {
                value: 'text',
                label: 'Text Input'
              },
              {
                value: 'dropdown',
                label: 'Dropdown'
              },
              {
                value: 'date',
                label: 'Date Format'
              }
            ]}
          />
          {fieldType === 'dropdown' && (
            <>
              <DropdownValuesWrapper
                onClick={() => {
                  setOpenModal(true);
                }}>
                <SettingOutlined />
                <span>Dropdown Values</span>
              </DropdownValuesWrapper>
              <DropdownValuesModal
                isOpen={isModalOpen}
                onCancel={() => setOpenModal(false)}
                onOk={handleAddDropdownValues}
                values={dropdownValues}
              />
            </>
          )}
          {/* </FlexBox> */}
        </td>
        <td className="ant-table-cell">
         <ActionWrapper>
            <ConfirmButton onClick={handleOnConfirm} icon={<CheckOutlined />} />
            <CloseButton onClick={onCancel} icon={<CloseOutlined />} />
          </ActionWrapper>
        </td>
      </tr>
      {/* <EditingCustomFieldWrapper>
        <FlexBox>
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{ width: 'auto' }}
            placeholder="Custom Field Name"
          />
          <Checkbox
            checked={isRequired}
            onChange={(e) => {
              setIsRequired(e.target.checked);
            }}>
            Required Field
          </Checkbox>
        </FlexBox>
        <FlexBox>
          <Select
            showSearch
            placeholder="Field Type"
            optionFilterProp="children"
            value={fieldType}
            onChange={(value) => {
              setFieldType(value);
            }}
            options={[
              {
                value: 'text',
                label: 'Text Input'
              },
              {
                value: 'dropdown',
                label: 'Dropdown'
              },
              {
                value: 'date',
                label: 'Date Format'
              }
            ]}
          />
          {fieldType === 'dropdown' && (
            <>
              <DropdownValuesWrapper
                onClick={() => {
                  setOpenModal(true);
                }}>
                <SettingOutlined />
                <span>Dropdown Values</span>
              </DropdownValuesWrapper>
              <DropdownValuesModal
                isOpen={isModalOpen}
                onCancel={() => setOpenModal(false)}
                onOk={handleAddDropdownValues}
                values={dropdownValues}
              />
            </>
          )}
        </FlexBox>
        <ActionWrapper>
          <ConfirmButton onClick={handleOnConfirm} icon={<CheckOutlined />} />
          <CloseButton onClick={onCancel} icon={<CloseOutlined />} />
        </ActionWrapper>
      </EditingCustomFieldWrapper> */}
    </>
  );
}
