const addDrawingArea = (projectId) => `projects/${projectId}/drawing_areas`;
const getDrawingAreaList = (projectId) => `projects/${projectId}/drawing_areas`;
const getEditArea = (projectId, areaId) => `projects/${projectId}/drawing_areas/${areaId}`;
const updateDrawingArea = (projectId, areaId) => `projects/${projectId}/drawing_areas/${areaId}`;
const deleteDrawingArea = (projectId, areaId) => `projects/${projectId}/drawing_areas/${areaId}`;
const getDrawingList = (projectId) => `projects/${projectId}/drawings`;
const getDrawingSetList = (projectId) => `projects/${projectId}/drawings/set_drawings`;
const getDrawingUploadLog = (projectId) => `projects/${projectId}/drawing_uploads`;
const uploadDrawingApproval = (projectId, id) =>
  `projects/${projectId}/drawing_uploads/${id}/approve_drawings`;
const pendingDrawingApproval = (projectId, id) =>
  `projects/${projectId}/drawing_uploads/${id}/pending_for_approval`;
const getDefinedProperties = (projectId, id) =>
  `projects/${projectId}/drawing_uploads/${id}/define_properties`;
const loadDrawingSet = (companyId, id) =>
  `companies/${companyId}/drawing_disciplines/${id}/load_drawing_sets`;
const drawingVersionSummary = (id) => `drawing_versions/${id}/summary`;
const saveCoordinates = (projectId, id) =>
  `projects/${projectId}/drawing_uploads/${id}/save_pdf_coordinates`;
const pdfAutoScanProcess = (projectId, id) =>
  `projects/${projectId}/drawing_uploads/${id}/process_auto_scan`;
const saveSheetDetails = (id) => `drawing_versions/${id}/save_sheet_details`;
const processOCRExtraction = (id) => `drawing_versions/${id}/process_ocr_extraction`;
const saveAnnotations = (id) => `drawing_versions/${id}/save_annotations`;
const loadAnnotations = (id) => `drawing_versions/${id}/load_annotations`;

export default {
  getDrawingAreaList,
  getDrawingList,
  getEditArea,
  updateDrawingArea,
  deleteDrawingArea,
  addDrawingArea,
  getDrawingUploadLog,
  loadDrawingSet,
  getDrawingSetList,
  uploadDrawingApproval,
  drawingVersionSummary,
  pendingDrawingApproval,
  pdfAutoScanProcess,
  saveCoordinates,
  saveSheetDetails,
  processOCRExtraction,
  saveAnnotations,
  loadAnnotations,
  getDefinedProperties
};
