import { CheckOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

export default function PricingPlans() {
    return (
        <div className='pricing-container'>
            <div>
                <h1 className='pricing-title'>PRICING</h1>
                <p className='pricing-subtext'>Find the right plan<br /><span style={{fontSize:"38px"}}>for your needs</span></p>
            </div>
            <p style={{textAlign:'center', fontSize: "22px"}}>Welcome to the beginning of a transformative experience! We're thrilled to introduce ProjectLoop, a groundbreaking solution designed with your needs and aspirations in mind. We believe wholeheartedly in the power and potential of our product to make a significant difference in your life—so much so, that we're offering two exclusive introductory pricing options that you won't see ever again.</p>
            <div className='pricing-plan-container'>
                
                <div className='plan-card'>
                    
                    <div className='plan-head-container'>
                        <div className='plan-title'>Early Adopter - Monthly</div>
                        <div className='plan-description'>We know we are new! So we are offering an AMAZING deal, get a FIXED-RATE for the first year that we will never offer again. Get this rate when you sign up by July 30th, 2024.</div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$1,000</div>
                            <div className='plan-pricing-text'>
                                <p> CAD / Month + Tax<br /></p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=Early Adopter - Monthly">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Get <u>unlimited</u> access for <u>5 Projects sites</u>:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Drawings
                            </li>
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punch Lists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                            <li>
                                <CheckOutlined /> Users
                            </li>
                            <li>
                                <CheckOutlined /> Subcontractors
                            </li>
                            <li>
                                <CheckOutlined /> Documents
                            </li>
                            <li>
                                <CheckOutlined /> Company Directory
                            </li>
                            <li>
                                <CheckOutlined /> Mobile App Acces
                            </li>
                            
                        </ul>
                        <p style={{marginTop: '20px', fontSize:"10px"}}>
                            Terms & Conditions apply, you are getting unlimited access to a state of the art beta platform. The discount as applied is considered
                            a significant reduction against the normal pricing model.  Up to 90% discount in some cases.  We believe in our product so much that
                            we use it internally daily to run our businesses. This is a limited time offer, while ProjectLoop may offer discounts in the future they
                            will never match the reduction you see here.  Please compare to our competitors.
                            <br />
                            The plan is based on the above price for unlimited* use on the product suite for the term of up to 1 year.  This is allowed on up to 5
                            project sites in the system.  If you need an additional site, you can purchase unlimited use on each additional project site for $1999/mo.
                        </p>
                    </div>
                </div>


                <div className='plan-card'>
                    
                    <div className='plan-head-container'>
                        <div className='plan-title'>Early Adopter - Annual</div>
                        <div className='plan-description'>We know we are new! So we are offering an AMAZING deal, get a FIXED-RATE for the first year that we will never offer again. Get this rate when you sign up by July 30th, 2024.</div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$12,000</div>
                            <div className='plan-pricing-text'>
                                <p> CAD / Year + Tax<br /></p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=enterprise">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Get <u>unlimited</u> access for <u>5 Projects sites</u>:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Drawings
                            </li>
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punch Lists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                            <li>
                                <CheckOutlined /> Users
                            </li>
                            <li>
                                <CheckOutlined /> Subcontractors
                            </li>
                            <li>
                                <CheckOutlined /> Documents
                            </li>
                            <li>
                                <CheckOutlined /> Company Directory
                            </li>
                            <li>
                                <CheckOutlined /> Mobile App Acces
                            </li>
                            <li>
                                <CheckOutlined /> 20% off SafetyLoop when bundled
                            </li>

                        </ul>
                        <p style={{marginTop: '20px', fontSize:"10px"}}>
                            Terms & Conditions apply, you are getting unlimited access to a state of the art beta platform. The discount as applied is considered
                            a significant reduction against the normal pricing model.  Up to 90% discount in some cases.  We believe in our product so much that
                            we use it internally daily to run our businesses. This is a limited time offer, while ProjectLoop may offer discounts in the future they
                            will never match the reduction you see here.  Please compare to our competitors.
                            <br />
                            The plan is based on the above price for unlimited* use on the product suite for the term of up to 1 year.  This is allowed on up to 5
                            project sites in the system.  If you need an additional site, you can purchase unlimited use on each additional project site for $1999/mo.
                        </p>
                    </div>
                </div>

                {/* <div className='plan-card'>
                    <div className='plan-head-container'>
                        <div className='plan-title'>Early Adopter - Annual</div>
                        <div className='plan-description'>We know we are new! So we are offering an AMAZING deal, get a set rate for the first year that we will never offer again. </div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$12,000</div>
                            <div className='plan-pricing-text'>
                                <p> CAD / Year + Tax<br /></p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=professional">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Get unlimited access to:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Drawings*
                            </li>
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punchlists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            <li>
                                <CheckOutlined /> Asset Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                        </ul>
                    </div>
                </div> */}
                
            
                {/* <div className='plan-card'>
                    <div className='plan-head-container'>
                        <div className='plan-title'>Professional</div>
                        <div className='plan-description'>For organizations seeking advanced insights and enhanced collaboration to elevate their business performance.</div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$50</div>
                            <div className='plan-pricing-text'>
                                <p>/Seat/Month<br />When billed monthly</p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=professional">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Get unlimited access to:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Drawings
                            </li>
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punchlists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            <li>
                                <CheckOutlined /> Asset Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='plan-card'>
                    <div className='plan-head-container'>
                        <div className='plan-title'>Professional</div>
                        <div className='plan-description'>For organizations seeking advanced insights and enhanced collaboration to elevate their business performance.</div>
                        <div className='plan-pricing'>
                            <div className='plan-price'>$50</div>
                            <div className='plan-pricing-text'>
                                <p>/Seat/Month<br />When billed monthly</p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=professional">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>Get unlimited access to:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Drawings
                            </li>
                            <li>
                                <CheckOutlined /> RFIs
                            </li>
                            <li>
                                <CheckOutlined /> Submittals
                            </li>
                            <li>
                                <CheckOutlined /> Daily Log
                            </li>
                            <li>
                                <CheckOutlined /> Punchlists
                            </li>
                            <li>
                                <CheckOutlined /> Locations
                            </li>
                            <li>
                                <CheckOutlined /> Asset Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Time Tracking
                            </li>
                            <li>
                                <CheckOutlined /> Actions
                            </li>
                            <li>
                                <CheckOutlined /> Forms
                            </li>
                        </ul>
                    </div>
                </div> */}
                {/* <div className='plan-card'>
                    <div className='plan-head-container'>
                        <div className='plan-title'>Enterprise</div>
                        <div className='plan-description'>For enterprises seeking advanced insights and enhanced collaboration to elevate their business performance.</div>
                        <div className='plan-pricing'>
                            
                            <div className='plan-pricing-text'>
                                <p>Sign up and we will contact you during the free trial along with a custom quote.</p>
                            </div>
                        </div>
                    </div>
                    <Link to="/checkout?plan=enterprise">
                        <Button type="primary" block size='large'>
                            Get Started
                        </Button>
                    </Link>
                    <div className='plan-helper-text'>All the benefits of Professional, and:</div>
                    <div className='plan-features'>
                        <ul>
                            <li>
                                <CheckOutlined /> Access to Custom Reports/Analytics
                            </li>
                            <li>
                                <CheckOutlined /> Dedicated Support Plans
                            </li>
                            <li>
                                <CheckOutlined /> Annual Saving Plans
                            </li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
