import { combineReducers } from 'redux';
import rfiViewSlice from './rfi/rfiViewSlice';
import RFISlice from './rfi/RFISlice';
import projectSlice from './projectSlice';
import CreateSubmittalSlice from './Submittal/CreateSlice';
import submittalSlice from './Submittal/submittalSlice';
import ViewSubmittal from './Submittal/ViewSlice';
import PunchListSlice from './PunchListSlice';
import punchlistSlice from './punchlist/punchlistSlice';
import drawingSlice from './drawing/drawingSlice';
import projectDocumentSlice from './ProjectDocument/ProjectDocument';
import LoginSlice from './LoginSlice';
import DirectorySlice from './DirectorySlice';

// eslint-disable-next-line import/prefer-default-export
export const rootReducer = combineReducers({
  RFI: rfiViewSlice,
  project: projectSlice,
  createSubmittal: CreateSubmittalSlice,
  submittal: submittalSlice,
  RFISlice: RFISlice,
  viewSubmittal: ViewSubmittal,
  PunchListSlice: PunchListSlice,
  punchList: punchlistSlice,
  drawing: drawingSlice,
  projectDocument: projectDocumentSlice,
  signUp: LoginSlice,
  directory: DirectorySlice
});
