import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Wrapper } from '../DailyLogsNew/components/NoData';
import { DocIcons } from './DocumentIcons';

const NoData = ({ handleShareLink }) => {
  return (
    <>
      <Wrapper autoWidth>
        {DocIcons.noData}
        <>
          <h2 style={{ fontWeight: 700, fontSize: '20px' }}>No share link has been created yet.</h2>
          <p
            style={{
              color: '#909090'
            }}>
            If you want to create click on the share link
          </p>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            key="2"
            className="pl-primary-btn"
            onClick={handleShareLink}>
            Share Link
          </Button>
        </>
      </Wrapper>
    </>
  );
};

export default NoData;
