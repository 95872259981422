import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ACTIONS from '../../actions';
import submittal from '../../services/Submittal';
import { API_SERVICE } from '../../services/CommonApi';

const initialState = {
  generalSetting: {},
  generalSettingUpdated: false,

  submittalTypes: [],
  submittalCustomFields: [],
  submittalWorkflows: [],
  submittals: {
    data: []
  }
};

export const getGeneralSetting = createAsyncThunk(
  ACTIONS.SUBMITTAL_GET_GENERAL_SETTING,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.get(
        `${submittal.getSubmittalGeneralSetting(arg.projectId, arg.submittalSettingId)}`
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateGeneralSetting = createAsyncThunk(
  ACTIONS.SUBMITTAL_UPDATE_GENERAL_SETTING,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.put(
        submittal.updateSubmittalGeneralSetting(arg.projectId, arg.submittalSettingId),
        arg.payload
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getSubmittalTypes = createAsyncThunk(
  ACTIONS.SUBMITTAL_GET_SUBMITTAL_TYPES,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.get(`${submittal.getListSubmittalTypes(arg.projectId)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateSubmittalType = createAsyncThunk(
  ACTIONS.SUBMITTAL_UPDATE_SUBMITTAL_TYPE,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.put(
        submittal.updateSubmittalType(arg.projectId, arg.submittalTypeId),
        arg.payload
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteSubmittalType = createAsyncThunk(
  ACTIONS.SUBMITTAL_DELETE_SUBMITTAL_TYPE,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.delete(submittal.updateSubmittalType(arg.projectId, arg.submittalTypeId));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createSubmittalType = createAsyncThunk(
  ACTIONS.SUBMITTAL_CREATE_SUBMITTAL_TYPE,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.post(
        submittal.createSubmittalType(arg.projectId, arg.submittalTypeId),
        arg.payload
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getSubmittalCustomFields = createAsyncThunk(
  ACTIONS.SUBMITTAL_GET_CUSTOM_FIELDS,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.get(`${submittal.getSubmittalCustomFields(arg.projectId)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createSubmittalCustomFields = createAsyncThunk(
  ACTIONS.SUBMITTAL_CREATE_CUSTOM_FIELDS,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.post(
        `${submittal.createSubmittalCustomField(arg.projectId)}`,
        arg.payload
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteSubmittalCustomFields = createAsyncThunk(
  ACTIONS.SUBMITTAL_DELETE_CUSTOM_FIELDS,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.delete(
        submittal.deleteSubmittalCustomField(arg.projectId, arg.submittalCustomFieldId)
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateSubmittalCustomFields = createAsyncThunk(
  ACTIONS.SUBMITTAL_UPDATE_CUSTOM_FIELDS,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.put(
        submittal.updateSubmittalCustomFields(arg.projectId, arg.submittalCustomFieldId),
        arg.payload
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getSubmittalWorkflows = createAsyncThunk(
  ACTIONS.SUBMITTAL_GET_LIST_WORKFLOWS,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.get(`${submittal.getListWorkflows(arg.projectId)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteSubmittalWorkflow = createAsyncThunk(
  ACTIONS.SUBMITTAL_DELETE_WORKFLOW,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.delete(submittal.deleteSubmittalWorkflow(arg.projectId, arg.workflowId));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createSubmittalWorkflow = createAsyncThunk(
  ACTIONS.SUBMITTAL_CREATE_WORKFLOW,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.post(`${submittal.createSubmittalWorkflow(arg.projectId)}`, arg.payload);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateSubmittalWorkflow = createAsyncThunk(
  ACTIONS.SUBMITTAL_UPDATE_WORKFLOW,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.put(
        submittal.updateSubmittalWorkflow(arg.projectId, arg.workflowId),
        arg.payload
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getListSubmittals = createAsyncThunk(
  ACTIONS.SUBMITTAL_GET_LIST_SUBMITTALS,
  async (arg, thunkAPI) => {
    const payload = arg.filters ? { page: arg.page, per_page: 5, filters: arg.searchData } : {};
    try {
      return API_SERVICE.get(`${submittal.getListSubmittals(arg.projectId)}`, payload);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const SubmittalSlice = createSlice({
  name: 'submittal',
  initialState,
  reducers: {
    resetStatus: () => initialState,
    resetRequestStatus: (state) => {
      return {
        ...state,
        generalSettingUpdated: false
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getGeneralSetting.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        return { ...state, generalSetting: payload?.data?.data };
      }
      return { ...state };
    });
    builder.addCase(updateGeneralSetting.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        return { ...state, generalSetting: payload?.data?.data, generalSettingUpdated: true };
      }
      return { ...state };
    });
    builder.addCase(getSubmittalTypes.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        return { ...state, submittalTypes: payload?.data?.data };
      }
      return { ...state };
    });
    builder.addCase(updateSubmittalType.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        const index = state.submittalTypes?.findIndex(
          (item) => item?.id === payload?.data?.data?.id
        );
        const newData = [...state.submittalTypes];
        newData[index] = { ...payload?.data?.data };
        return { ...state, submittalTypes: newData };
      }
      return { ...state };
    });
    builder.addCase(deleteSubmittalType.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        return { ...state };
      }
      return { ...state };
    });
    builder.addCase(createSubmittalType.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        const newData = [...state.submittalTypes];
        newData.push(payload?.data?.data);
        return { ...state, submittalTypes: newData };
      }
      return { ...state };
    });
    builder.addCase(getSubmittalCustomFields.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        return { ...state, submittalCustomFields: payload?.data?.data };
      }
      return { ...state };
    });
    builder.addCase(createSubmittalCustomFields.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        const newData = [...state.submittalCustomFields];
        newData.push(payload?.data?.data);
        return { ...state, submittalCustomFields: newData };
      }
      return { ...state };
    });
    builder.addCase(deleteSubmittalCustomFields.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        return { ...state };
      }
      return { ...state };
    });
    builder.addCase(updateSubmittalCustomFields.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        const index = state.submittalCustomFields?.findIndex(
          (item) => item?.id === payload?.data?.data?.id
        );
        const newData = [...state.submittalCustomFields];
        newData[index] = { ...payload?.data?.data };
        return { ...state, submittalCustomFields: newData };
      }
      return { ...state };
    });
    builder.addCase(getSubmittalWorkflows.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        return { ...state, submittalWorkflows: payload?.data?.data };
      }
      return { ...state };
    });
    builder.addCase(deleteSubmittalWorkflow.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        return { ...state };
      }
      return { ...state };
    });
    builder.addCase(createSubmittalWorkflow.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        const newData = [...state.submittalWorkflows];
        newData.push(payload?.data?.data);
        return { ...state, submittalWorkflows: newData };
      }
      return { ...state };
    });
    builder.addCase(updateSubmittalWorkflow.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        const index = state.submittalWorkflows?.findIndex(
          (item) => item?.id === payload?.data?.data?.id
        );
        const newData = [...state.submittalWorkflows];
        newData[index] = { ...payload?.data?.data };
        return { ...state, submittalWorkflows: newData };
      }
      return { ...state };
    });
    builder.addCase(getListSubmittals.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.submittals = {
          ...state.submittals,
          data: payload?.data?.data,
          ...payload.headers
        };
        // state.submittals = payload?.data?.data;
      }
    });
  }
});

export const { resetStatus, resetRequestStatus } = SubmittalSlice.actions;
export default SubmittalSlice.reducer;
