import React from 'react';
import HomePageHeroSection from './Components/HomePageHeroSection';
import FeaturesSection from './Components/FeaturesSection';
import ParallaxSection from './Components/ParallaxSection';
import DemoSection from './Components/DemoSection';
import FeatureHighlightSection from './Components/FeatureHighlightSection';
import image1 from "../../images/0_1.webp";
import image2 from "../../images/0_4.png";
import image3 from "../../images/0_0.webp";


export default function Website() {
	return (
		<>
			<HomePageHeroSection />
			<FeaturesSection
				headerText={"Risk Management"}
				subText={""}
				featureHighlights={["ProjectLoop seamlessly integrates contractors, subcontractors, vendors, and suppliers into a unified project management ecosystem, streamlining the coordination and execution of tasks. This integration facilitates complete oversight, allowing for efficient tracking and management of your project workflows in real time.",
					"With ProjectLoop, operational efficiency and compliance with project standards are easily achievable. Our platform is custom-built for project management professionals, simplifying complex project coordination tasks. This means less time spent on administrative work and more time focusing on achieving project milestones.",
					]}
				sectionBackground='dark'
				sectionAlign="image-right"
				featureImageSrc={image2}
			/>
			<FeaturesSection
				headerText={"Streamlined Communication and Collaboration "}
				subText={""}
				featureHighlights={["Facilitate communication and collaboration among team members, stakeholders, and clients. We provide the tools for sharing documents, exchanging messages, tracking progress, and resolving issues, promoting transparency and accountability throughout the project lifecycle.",
					
					]}
				featureImageSrc={image3}
			/>
			<FeaturesSection
				headerText={"Effective Task and Time Management "}
				subText={""}
				featureHighlights={[" Enable users to manage tasks efficiently by assigning priorities, deadlines, and dependencies. It helps in tracking task progress, monitoring resource utilization, and identifying issues, allowing users to optimize workflow and ensure timely completion of deliverables.",
					
					]}
				sectionBackground='dark'
				sectionAlign="image-right"
				featureImageSrc={image1}
			/>
			<FeatureHighlightSection />
			<ParallaxSection />
			<DemoSection mergeTop={false} />
		</>
	)
}
