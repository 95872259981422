import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import axios from 'axios';
import { API_SERVICE } from '../CommonApi';
import APICONST from '../../constants';
import showNotification from '../../components/ShowNotification';

export const useGetCompanyCustomField = (id) => {
  return useQuery({
    queryKey: ['comp-cf', id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/project_custom_fields`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useModifyCompanyCustomField = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.putRequest(
          `companies/${id}/project_custom_fields/${payload.id}`,
          payload
        );
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`companies/${id}/project_custom_fields/${payload.id}`);
      }
      return API_SERVICE.postRequest(`companies/${id}/project_custom_fields`, payload);
    },
    onSuccess: () => {
      return Promise.all([
        showNotification({ message: 'Updated Successfully' }),
        queryClient.invalidateQueries({ queryKey: ['comp-cf', id] })
      ]);
    }
  });
};

export const useGetDrawingDicipline = (pagination, filters, id) => {
  return useQuery({
    queryKey: ['comp-dd', id, pagination, filters],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `companies/${id}/drawing_disciplines`,
        pagination
          ? {
              active: true
            }
          : {}
      );
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetDrawingPreview = (id, arg = {}) => {
  return useQuery({
    queryKey: ['comp-draw-preview', id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`drawing_versions/${id}`, { ...arg });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetActionTypes = (id) => {
  return useQuery({
    queryKey: ['action-type', id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/action_types`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useModifyActionType = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.putRequest(`companies/${id}/action_types/${payload.id}`, payload);
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`companies/${id}/action_types/${payload.id}`);
      }
      return API_SERVICE.postRequest(`companies/${id}/action_types`, payload);
    },
    onSuccess: () => {
      return Promise.all([
        showNotification({ message: 'Updated Successfully' }),
        queryClient.invalidateQueries({ queryKey: ['action-type', id] })
      ]);
    }
  });
};

export const useModifyDrawingDicipline = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.putRequest(`companies/${id}/drawing_disciplines/${payload.id}`, payload);
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`companies/${id}/drawing_disciplines/${payload.id}`);
      }
      return API_SERVICE.postRequest(`companies/${id}/drawing_disciplines`, payload);
    },
    onSuccess: (res, { action }) => {
      return Promise.all([
        showNotification({
          message: `Record ${
            action === 'update' ? 'Updated' : action === 'delete' ? 'Deleted' : 'Added'
          } Successfully`
        }),
        queryClient.invalidateQueries({ queryKey: ['comp-dd', id] })
      ]);
    }
  });
};

export const useGetPunchListCategories = (id) => {
  return useQuery({
    queryKey: ['comp-plc', id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/punch_list_categories`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useModifyPunchListCategories = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.putRequest(
          `companies/${id}/punch_list_categories/${payload.id}`,
          payload
        );
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`companies/${id}/punch_list_categories/${payload.id}`);
      }
      return API_SERVICE.postRequest(`companies/${id}/punch_list_categories`, payload);
    },
    onSuccess: () => {
      return Promise.all([
        showNotification({ message: 'Updated Successfully' }),
        queryClient.invalidateQueries({ queryKey: ['comp-plc', id] })
      ]);
    }
  });
};

export const useModifyPunchListType = (id, companyId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.putRequest(
          `punch_list_categories/${id}/punch_list_types/${payload.id}`,
          payload
        );
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`punch_list_categories/${id}/punch_list_types/${payload.id}`);
      }
      return API_SERVICE.postRequest(`punch_list_categories/${id}/punch_list_types`, payload);
    },
    onSuccess: () => {
      return Promise.all([
        showNotification({ message: 'Updated Successfully' }),
        queryClient.invalidateQueries({ queryKey: ['comp-plc', companyId] })
      ]);
    }
  });
};

// eslint-disable-next-line default-param-last
export const useGetCompanyList = (pagination, filters, id) => {
  return useQuery({
    queryKey: ['companies', id, pagination, filters],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/contractor_companies`, {
        page: pagination?.page,
        per_page: pagination?.per_page,
        filters
      });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

// eslint-disable-next-line default-param-last
export const useGetCompanyUsersList = (pagination, filters, id) => {
  return useQuery({
    queryKey: ['company_users', id, pagination, filters],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/contractor_users`, {
        page: pagination?.page,
        per_page: pagination?.per_page,
        filters
      });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

// eslint-disable-next-line default-param-last
export const useGetContractorCompanyUsersListForProject = (page, filters, id, directoryId) => {
  return useQuery({
    queryKey: ['contractor_company_users', id, directoryId, page, filters],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `companies/${id}/contractor_companies/${directoryId}/list_users`,
        {
          page,
          per_page: 5,
          filters
        }
      );
      return data;
    },
    enabled: !!id && !!directoryId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useUpdateCompanyStatus = (companyId) => {
  return useMutation({
    mutationFn: ({ payload, id }) => {
      return API_SERVICE.putRequest(
        `companies/${companyId}/contractor_companies/${id}/update_status`,
        payload,
        false
      );
    }
  });
};

export const useUpdateCompanyUserStatus = (companyId) => {
  return useMutation({
    mutationFn: ({ payload, id }) => {
      return API_SERVICE.putRequest(
        `companies/${companyId}/contractor_users/${id}/update_status`,
        payload,
        false
      );
    }
  });
};

export const useDeleteCompany = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.delete(`companies/${companyId}/contractor_companies/${payload.id}`);
    }
  });
};

export const useDeleteCompanyUser = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.delete(`companies/${companyId}/contractor_users/${payload.id}`);
    }
  });
};

export const useAddCompany = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(`companies/${companyId}/add_contractor`, payload);
    }
  });
};

export const useAddUser = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(`companies/${companyId}/add_user`, payload);
    }
  });
};

export const useGetCompanyDropdownData = (id) => {
  return useQuery({
    queryKey: [`company_base_data`, id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/directory/options_data`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetCompanyRolesDropdownData = (id) => {
  return useQuery({
    queryKey: [`company_role_base_data`, id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/roles/base_data`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetCompaniesList = () => {
  return useQuery({
    queryKey: [`companies_list`],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies`);
      return data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetCompanyProjectsList = (companyId) => {
  return useQuery({
    queryKey: [`company_project_list`, companyId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects?company_id=${companyId}`);
      return data;
    },
    enabled: !!companyId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
export const useGetDataForTemplateWidget = (companyId) => {
  return useQuery({
    queryKey: [`template_widget_data`, companyId],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `companies/${companyId}/form_templates/base_data?custom_form_elements=true`
      );
      return data;
    },
    enabled: !!companyId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
export const useGetCompanyProjectsDropDown = (companyId, payload = {}) => {
  return useQuery({
    queryKey: [`company_project_list_dropdown`, companyId],
    queryFn: async () => {
      const data = companyId
        ? await API_SERVICE.get(`companies/${companyId}/list_projects`, payload)
        : {};
      return data;
    },
    enabled: !!companyId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetCompanyData = (companyId, directoryId) => {
  return useQuery({
    queryKey: [`company_data`, companyId, directoryId],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `companies/${companyId}/contractor_companies/${directoryId}`
      );
      return data;
    },
    enabled: !!companyId && !!directoryId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetCompanyUserData = (companyId, directoryId) => {
  return useQuery({
    queryKey: [`company_user_data`, companyId, directoryId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${companyId}/contractor_users/${directoryId}`);
      return data;
    },
    enabled: !!companyId && !!directoryId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useModifyCompany = (companyId, directoryId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.putRequest(
        `companies/${companyId}/contractor_companies/${directoryId}`,
        payload,
        true
      );
    }
  });
};

export const useModifyUser = (companyId, directoryId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.putRequest(
        `companies/${companyId}/contractor_users/${directoryId}`,
        payload,
        true
      );
    }
  });
};

export const useGetCurrentCompanyData = (companyId) => {
  return useQuery({
    queryKey: [`current_company_data`, companyId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${companyId}`);
      return data;
    },
    enabled: !!companyId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetCurrentCompanyUserData = (companyId, userId) => {
  return useQuery({
    queryKey: [`current_company_user_data`, companyId, userId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${companyId}/users/${userId}`);
      return data;
    },
    enabled: !!companyId && !!userId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetContractorCompanyUserData = (companyId, userId) => {
  return useQuery({
    queryKey: [`current_company_user_data`, companyId, userId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${companyId}/contractor_users/${userId}`);
      return data;
    },
    enabled: !!companyId && !!userId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useModifyCurrentCompanyUser = (companyId, userId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.putRequest(`companies/${companyId}/users/${userId}`, payload, true);
    }
  });
};

// eslint-disable-next-line default-param-last
export const useGetCurrentCompanyUsersList = (pagination, filters, id) => {
  return useQuery({
    queryKey: ['current_company_users', id, pagination, filters],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/users`, {
        page: pagination?.page,
        per_page: pagination?.per_page,
        filters
      });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useModifyCurrentCompany = (companyId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.putRequest(`companies/${companyId}`, payload, true);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['current_company_data', companyId] });
    }
  });
};

export const useDeleteCurrentCompanyUser = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.delete(`companies/${companyId}/users/${payload.id}`);
    }
  });
};

export const useUpdateCurrentCompanyUserStatus = (companyId) => {
  return useMutation({
    mutationFn: ({ payload, id }) => {
      return API_SERVICE.putRequest(
        `companies/${companyId}/users/${id}/update_status`,
        payload,
        false
      );
    }
  });
};

export const useUpdateCurrentCompanyUserRole = (companyId) => {
  return useMutation({
    mutationFn: ({ payload, id }) => {
      return API_SERVICE.putRequest(
        `companies/${companyId}/users/${id}/update_role`,
        payload,
        false
      );
    }
  });
};

export const useCurrentCompanyAddUser = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(`companies/${companyId}/users`, payload);
    }
  });
};

export const useAssignToProject = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(
        `companies/${companyId}/users/${payload.userId}/assign_projects`,
        payload
      );
    }
  });
};

export const useCrewAssignToProject = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(
        `companies/${companyId}/crews/${payload.userId}/assign_to_projects`,
        payload
      );
    }
  });
};

export const useDeleteAssignedProject = (companyId, userId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.putRequest(
        `companies/${companyId}/users/${userId}/remove_project_membership`,
        payload
      );
    }
  });
};

export const useGetSignupDropdownData = () => {
  return useQuery({
    queryKey: [`company_signup_data`],
    queryFn: async () => {
      return axios.get(`${APICONST.BASE_URL}companies/base_data`, {}).then((r) => {
        return r;
      });
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
