import React from 'react';
import Report from "../../../../images/report.svg";
import QRCode from "../../../../images/QRCode.svg";
import Complaint from "../../../../images/complaint.svg";
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

export default function FeatureHighlightSection() {
  return (
    <div className="feature-highlight-section">
      <ScrollAnimation
        animateIn="animate__fadeIn"
        duration={1.5}
        animateOnce={true}
        delay={0}
      >
        <div className="feature-highlight-container">
          <div style={{ maxWidth: "650px" }}>
            <h2 className="feature-highlight-text">Meeting your Needs</h2>
          </div>
          <div className="feature-highlight-grid">
            <div className="feature-card">
              <img className='image' src={Report} />
              <h2 className="title">Risk Management</h2>
              <p className='sub-text'>Identifying, assess, and mitigate risks. By proactively managing risks, teams can anticipate potential issues, develop contingency plans, and take preventive measures to avoid disruptions or delays, providing peace of mind knowing that they are prepared for unexpected challenges.</p>
            </div>
            <div className="feature-card">
              <img className='image' src={QRCode} />
              <h2 className="title">Streamlined Communication and Collaboration</h2>
              <p className='sub-text'>Facilitate communication and collaboration among team members, stakeholders, and clients. We provide the tools for sharing documents, exchanging messages, tracking progress, and resolving issues, promoting transparency and accountability throughout the project lifecycle.
              </p>
            </div>
            <div className="feature-card">
              <img className='image' src={Complaint} />
              <h2 className="title">Effective Task and Time Management </h2>
              <p className='sub-text'>Enable users to manage tasks efficiently by assigning priorities, deadlines, and dependencies. It helps in tracking task progress, monitoring resource utilization, and identifying issues, allowing users to optimize workflow and ensure timely completion of deliverables.</p>
            </div>
          </div>
          <Link to="/features" style={{ marginTop: 60 }}>
            <Button size="large" shape="round" type='default' className="book-demo-btn">Explore all features</Button>
          </Link>
        </div>
      </ScrollAnimation>
    </div>
  )
}
