import React, { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, Radio, Row, Switch } from 'antd';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Wrapper } from '../../Pipeline/style';
import { FieldGreyWrapper } from '../../DailyLogsNew/components/Forms/commonStyles';
import formRules from '../../DailyLogsNew/components/Forms/formRules';
import showNotification from '../../../components/ShowNotification';
import { useShareDocument, useShareFolder } from '../../../services/ProjectDoucment/hooks';

const ShareLinkForm = ({ details, setShareLink, refetch }) => {
  const { projectId } = useParams();
  const { mutate: shareDocument, isShareDocument } = useShareDocument(projectId, details.id);
  const { mutate: shareFolder, isShareFolder } = useShareFolder(projectId, details.id);
  const [isAddUser, setAddUser] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [users, addUsers] = useState([]);
  const [form] = Form.useForm();
  const handleAddUser = () => {
    // eslint-disable-next-line consistent-return
    form.validateFields(['recipient_name', 'recipient_email']).then((values) => {
      console.log(values);
      const isEmailExist = users.some(
        (u) => u?.recipient_email?.toLowerCase() === values?.recipient_email?.toLowerCase()
      );
      if (isEmailExist) {
        return showNotification({
          type: 'error',
          message: 'User already exist. Please add new user'
        });
      }
      if (!values?.recipient_name || !values?.recipient_email) {
        return showNotification({
          type: 'error',
          message: 'Please enter the name/email'
        });
      }
      addUsers((prev) => [
        ...prev,
        {
          recipient_name: values.recipient_name,
          recipient_email: values.recipient_email
        }
      ]);
      form.resetFields(['recipient_name', 'recipient_email']);
    });
  };
  const handleRemoveUser = (email) => {
    const newUsers = users.filter((u) => u.recipient_email !== email);
    addUsers(newUsers?.length ? newUsers : []);
  };
  // eslint-disable-next-line consistent-return
  const onFinish = (values) => {
    if (!users.length) {
      return showNotification({ type: 'error', message: 'Please add external users' });
    }
    setIsDisabled(true);
    const payload = {
      external_document_share: {
        reason: values.reason,
        body: values.body,
        is_link_expiring: values.is_link_expiring,
        recipient_users: users
      }
    };
    if (values.is_link_expiring) {
      payload.external_document_share.link_expiry_duration = values.link_expiry_duration;
    }
    if (details.type === 'folders') {
      shareFolder(
        {
          payload
        },
        {
          onSuccess: () =>
            Promise.all([
              refetch && refetch(),
              setIsDisabled(false),
              setShareLink(false),
              showNotification({ message: 'Folder shared successfully.' })
            ]),
          onError: (data) => {
            const { error, errors } = data?.response?.data || {};
            Promise.all([
              setIsDisabled(false),
              showNotification({
                type: 'error',
                message: errors?.toString() || error || 'Error while generating the share link'
              })
            ]);
          }
        }
      );
    } else {
      shareDocument(
        {
          payload
        },
        {
          onSuccess: () =>
            Promise.all([
              refetch && refetch(),
              setShareLink(false),
              showNotification({ message: 'Document shared successfully.' })
            ]),
          onError: (data) => {
            const { error, errors } = data?.response?.data || {};
            Promise.all([
              setIsDisabled(false),
              showNotification({
                type: 'error',
                message: errors?.toString() || error || 'Error while generating the share link'
              })
            ]);
          }
        }
      );
    }
  };
  return (
    <Wrapper>
      <Form layout="vertical" form={form} name="shareLinkForm" onFinish={onFinish}>
        <div className="ant-col ant-form-item-label">
          <label htmlFor="shareLinkForm_body" className="ant-form-item-required" title="Body">
            To
          </label>
        </div>
        <FieldGreyWrapper>
          <Button
            onClick={() => setAddUser(true)}
            style={{ alignSelf: 'flex-start', padding: 0 }}
            type="link"
            icon={<PlusOutlined />}>
            Add External Users
          </Button>
          <Divider style={{ margin: '10px 0 10px 0' }} />
          {isAddUser && (
            <Row justify="space-between" align="top" style={{ margin: 0, gap: 16 }}>
              <Form.Item style={{ flex: 1 }} name="recipient_name" label="">
                <Input placeholder="Enter the name" />
              </Form.Item>
              <Form.Item
                style={{ flex: 1 }}
                name="recipient_email"
                label=""
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter valid email.'
                  }
                ]}>
                <Input placeholder="Email address" type="email" />
              </Form.Item>
              <Button
                style={{ color: '#009e1f', borderColor: '#009e1f' }}
                onClick={handleAddUser}
                icon={<CheckOutlined />}
              />
              <Button
                style={{ color: ' #ff3030', borderColor: '#ff3030' }}
                onClick={() => {
                  form.resetFields(['recipient_name', 'recipient_email']);
                  setAddUser(false);
                }}
                icon={<CloseOutlined />}
              />
            </Row>
          )}
          {users.length > 0 && (
            <Row style={{ margin: 0, paddingTop: 16 }} gutter={[10, 10]}>
              {users.map((user, i) => {
                return (
                  <Col
                    span={12}
                    style={{ paddingRight: 0, paddingLeft: i % 2 === 0 ? '0' : '10px' }}
                    key={user.recipient_email}>
                    <Card style={{ borderRadius: 5 }} bodyStyle={{ padding: 12 }}>
                      <Row align="middle" style={{ margin: 0, gap: 8 }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none">
                          <rect y="-0.000976562" width="36" height="36" rx="8" fill="#ECECEC" />
                          <path
                            d="M27.2223 20V22.7491C22.4737 23.4557 20.7079 27.4144 20.0001 30.9965C19.9734 31.1381 23.8885 26.8989 27.2223 26.8728V29.622L33 24.811L27.2223 20Z"
                            fill="#5C5C5C"
                          />
                          <path
                            d="M13 11.3024C13 8.3739 15.374 6 18.3024 6C21.2308 6 23.6048 8.37399 23.6048 11.3024C23.6048 14.2308 21.2308 16.6048 18.3024 16.6048C15.374 16.6048 13 14.2308 13 11.3024Z"
                            fill="#909090"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18 18.3945C15.1896 18.3945 12.6342 19.2646 10.6519 20.2361C9.17807 20.9584 8.38867 22.3057 8.38867 23.697C8.38867 25.0884 9.17807 26.4356 10.6519 27.1579C12.6344 28.1293 15.1896 28.9995 18 28.9995C18.485 28.9995 18.9625 28.9736 19.4308 28.9257C20.3722 25.7052 22.3268 22.7983 26.3012 21.807L26.2087 20.7878C25.9547 20.5785 25.6675 20.3926 25.3481 20.2361C23.3655 19.2647 20.8103 18.3945 18 18.3945Z"
                            fill="#909090"
                          />
                        </svg>
                        <div style={{ flex: 1 }}>
                          <p>{user.recipient_name}</p>
                          <label>{user.recipient_email}</label>
                        </div>
                        <svg
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleRemoveUser(user.recipient_email)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none">
                          <rect
                            width="24"
                            height="24"
                            transform="translate(0 -0.000976562)"
                            fill="white"
                          />
                          <path
                            d="M12 20.999C16.9706 20.999 21 16.9696 21 11.999C21 7.02846 16.9706 2.99902 12 2.99902C7.02944 2.99902 3 7.02846 3 11.999C3 16.9696 7.02944 20.999 12 20.999Z"
                            stroke="#FF3030"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 11.999H15"
                            stroke="#FF3030"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </FieldGreyWrapper>
        <Form.Item label="Reason for sharnig" name="reason" rules={formRules.setRequired('Reason')}>
          <Input.TextArea placeholder="Enter the reason" rows={5} />
        </Form.Item>
        <Form.Item label="Body" name="body" rules={formRules.setRequired('Body')}>
          <Input.TextArea placeholder="Enter the body" rows={5} />
        </Form.Item>
        <FieldGreyWrapper>
          <Row style={{ margin: 0, gap: 10 }} align="middle">
            <Form.Item name="is_link_expiring" valuePropName="checked">
              <Switch />
            </Form.Item>
            <div>
              <p>Link Expiration</p>
              <label>Default is never expire, if change to expiration dates only apply.</label>
            </div>
          </Row>
          <Form.Item noStyle dependencies={['is_link_expiring']} shouldUpdate>
            {({ getFieldValue }) => {
              const isExpiry = getFieldValue('is_link_expiring');
              return (
                isExpiry && (
                  <>
                    <Divider style={{ margin: '10px 0 10px 0' }} />
                    <Form.Item initialValue={1} name="link_expiry_duration" label="">
                      <Radio.Group defaultValue={1}>
                        <Radio value={1}>Expire in 30 days</Radio>
                        <Radio value={2}>Expire in 60 days</Radio>
                        <Radio value={3}>Expire in 90 days</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </>
                )
              );
            }}
          </Form.Item>
        </FieldGreyWrapper>
        <Row justify="end" style={{ marginTop: 16 }}>
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            disabled={isDisabled}
            loading={isShareDocument || isShareFolder}>
            Send Link
          </Button>
        </Row>
      </Form>
    </Wrapper>
  );
};

export default ShareLinkForm;
