/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 64px);
   .ant-table-thead > tr > th {
      background-color: #ECECEC/;
      }
   .grey-box {
    padding: 16px !important;
    background: #f6f6f6;
    border: 1px solid #ececec;
    border-radius: 4px;
    .form-group-container {
      .ant-form-item {
        margin-bottom: 10px;
      }
    }
 }
  .radio-item {
    display: flex;
    align-items: center;
    span:last-child {
      display: flex;
      align-items: center;
    }
  }
  .diff-label {
    font-size: 14px;
    font-weight: 700;
    color: #5C5C5C;
  }
  .loc-attach-wrap {
    > .ant-col {
      margin: 15px 0;
    }
  }

  .scanner {
    max-width: 100%;
  }
  .pro-loc-wrap {
    max-width: 700px;

    .ant-image {
      width: 100%;
      margin-bottom: 10px;
    }

    h4 {
      color: #909090;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 15px;
    }
    .ant-divider-horizontal {
      border-top-color: #D3D3D3;
    }

    .count-label {
      background: #ECECEC;
      padding: 3px 10px;
      border-radius: 24px;
      color: #909090;
      font-size: 14px;
      line-height: 18px;
    }
  }
    .checkbox-grey-label {
      background: #909090;
      font-size: 12px !important;
      font-weight: 500;
      color: #fff !important;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      margin-bottom: 0 !important;
    }
      .checkbox-grey-label {
        padding: 5px 10px !important;
      }

    .checkbox-item {
    flex: 1 1 25%; /* Grow, shrink, and set the basis to 25% */
    box-sizing: border-box; /* Ensures padding and borders are included in the width */
    // padding: 10px;
    // border: 0.5px solid #D3D3D3;
  }

  .verticalLine {
    position: absolute;
    right: 15px;
    height: 100%;
    width: 10px;
    background-color: #fff;
  }

  .loc-info-wrap {
    display: flex;
    > svg {
      width: 30px;
      margin-right: 8px;
    }
    .ant-image {
      width: 100%;
    }
  }

  .loc-info {
    h3 {
      color: #080808;
      text-transform: capitalize;
      margin-bottom: 7px;
    }

    label {
      background: #ECECEC;
      padding: 5px;
      color: #909090;
      text-transform: uppercase;
      border-radius: 7px;
    }
  }


  .loc-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color:#909090;
    margin-top: 10px;
  }
}

.hide {
  display: none;
}
.rotate {
  transform: rotate(180deg);
}

.file-name {
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: elipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
}

.file-info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    color:#909090;
    font-size: 12px;
    line-height: 16px;
  }

  a {
    text-decoration: underline;
    font-size: 14px;
    line-height: 24px;
    color: #FC8543;
    text-transform: capitalize;
  }
}

.p-140 {
  padding: 32px 140px;
}
  .p-60 {
  padding: 32px 60px;
}
.plrb-140 {
  padding: 0px 140px 32px 140px;
}
.plrt-140 {
  padding: 32px 140px 0px 140px;
}
.plr-140 {
  padding: 0px 140px;
}
  .plr-60 {
  padding: 0px 60px;
}
.p-12 {
  padding: 12px;
}
.no-border {
  border-right: 0;
}
.p-18 {
  padding: 18px;
}
.p-24 {
  padding: 24px;
}
.m-140 {
  margin: 32px 140px;
}
.mb-24 {
  margin-bottom: 24px;
}
.bg-white {
  background: white;
}
.bg-grey {
  background: #f6f6f6;
}
.w-100 {
  width: 100%;
}
.over-hdn {
  overflow: hidden;
}
.over-scrl {
  overflow: scroll;
}
.no-margin-lr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.fs-20 {
  font-size: 20px;
  line-height: 32px;
}
.fs-32 {
  font-size: 32px;
  line-height: 48px;
}
.fs-14 {
  font-size: 14px;
  line-height: 18px;
}
.fs-18 {
  font-size: 18px !important;
  line-height: 20px;
}
.fs-12 {
  font-size: 12px;
  line-height: 16px;
}
.fc-grey {
  color: #909090;
}
.fc-black {
  color: #080808;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700;
}
.flex-row {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.item-center {
  align-items: center;
}
.space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.fc-primary {
  color: #fc8543;
}
.bg-lite-primary {
  background: rgb(255, 235, 224);
}
.text-underline {
  text-decoration: underline;
}
.gap-16 {
  gap: 16px;
}
.no-margin {
  margin: 0;
}
.ant-radio-wrapper {
  span {
    color: #080808;
  }
}
.ant-form-item-label {
  > label {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #909090;
  }
}
.no-item-margin {
  margin: 0;
}
.text-center {
  text-align: center;
}
.br-10 {
  border-radius: 10px;
}
.ant-upload.ant-upload-drag {
  border: 1px solid #d9d9d9;
  background: transparent;
}
.ant-upload-drag-container {
  width: 100%;
}
.ant-upload-list {
  margin-bottom: 24px;
}
.collapse {
  &.view {
    margin-bottom: 32px;
  }
  .ant-collapse-header {
    display: flex;
    align-items: center;
    padding: 0;
    span {
      padding: 0;
      display: flex !important;
    }
  }
  .ant-collapse-content-box {
    padding: 0;
  }
}
label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0025em;
  color: #909090;
  margin-bottom: 5px;
}
.b-r-grey {
  border-right: 1px solid #d3d3d3;
  margin-right: 10px;
}
.br-grey {
  border: 1px solid #d3d3d3;
}
.br-right-grey {
  border-right: 1px solid #ECECEC;
}
.p-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.role-item {
  border-bottom: 1px solid #ECECEC;
  margin: 0;
  .ant-form-item-label {
  &:last-child {
      border-right: 1px solid #ECECEC;
  }
    text-align: left;
    width: 28.56666667%;
    padding: 18px;
    border-left: 1px solid #ECECEC;
  }
  .ant-radio-wrapper {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 25%;
    padding: 18px;
    height: 73px;
    flex-wrap: no-wrap;
    &:last-child {
      padding-right: 10px;
      span:last-child {
        justify-content: flex-end;
        flex: 1;
      }
    }
    &:not(:last-child) {
      border-right: 1px solid #ECECEC;
    }
  }
  .ant-radio-group {
    width: 100%;
    display: flex;
  }
}
.check-group {
  label {
    color: #080808;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    width: 100%;
    margin-bottom: 24px;
    span:not(.ant-checkbox) {
      padding-left: 10px;
    }
  }
}
.check {
  margin-bottom: 24px;
  span:not(.ant-checkbox) {
    color: #080808;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding-left: 10px;
  }
}
.br-light-grey {
  border: 1px solid #ececec;
}
plr-25 {
  padding: 0px 25px;
}
p {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #080808;
  margin: 0;
  word-wrap: break-word;
  line-height: 18px;
  letter-spacing: -0.084px;
}
.ant-tabs-nav {
  margin-bottom: 0;
  &:before {
    border-color: #909090;
  }
}
.ant-tabs-tab {
  .ant-tabs-tab-btn {
    color: #909090;
    font-size: 14px;
    font-weight: 500;
  }
  border: 1px solid #909090 !important;
}
.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: #080808;
    font-weight: 700;
  }
  border-bottom-color: #fff !important;
}
.ant-tabs-nav-wrap {
  padding: 0px 60px 0 60px;
}
&.ptab {
  .ant-tabs-nav-wrap {
    padding: 0px 140px 0 140px;
  }
}
.w-30 {
  width: 30%;
}
.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
  &:before {
    display: none !important;
  }
  &:after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 12px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}
hr {
  border: 1px solid #ececec;
}
.dropcont {
  .ant-select-dropdown {
    width: 100% !important;
    top: 40px !important;
  }
}
.upload-radio {
  .ant-radio-button-wrapper-checked {
    background: #FFF6F0;
    svg {
      path {
        fill: #FC8543;
      }
    }
  }
}
.right-text {
  input {
    text-align: right;
    padding: 0 30px;
  }
}
.export-radio {
  font-size: 16px;
  font-weight: 700;
  margin-right: 10px;
  display: flex;
  align-items: center;
  height: 80px;
  width: 150px;
  padding: 12px 16px;
  background: #F6F6F6;
}
`;
