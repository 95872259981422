import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ACTIONS from '../../actions';
import rfis from '../../services/RFIs/rfis';
import { API_SERVICE } from '../../services/CommonApi';

const initialState = {
  rfiData: {
    data: []
  },
  page: 'rfi-list'
};
export const getRFI = createAsyncThunk(ACTIONS.RFI_TABLE_DATA, async (arg, thunkAPI) => {
  try {
    const baseObj = {
      page: arg.page,
      per_page: arg.per_page,
      filters: arg.searchData,
      sort: arg.sortData
    };
    const deleteObj = {
      ...baseObj,
      deleted: arg?.deleted
    };
    const draftObj = {
      ...baseObj,
      draft: arg?.draft
    };
    const payload = arg?.filters
      ? arg?.draft
        ? draftObj
        : arg?.deleted
        ? deleteObj
        : baseObj
      : {};
    return API_SERVICE.get(`${rfis.viewRFIs(arg.projectId)}`, payload);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const RFISlice = createSlice({
  name: 'RFI',
  initialState,
  reducers: {
    resetStatus: () => initialState,
    setPageName: (state, { payload }) => {
      state.page = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRFI.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.rfiData = {
          data: payload?.data?.data,
          ...payload.headers
        };
      }
    });
  }
});
export const { resetStatus, setPageName } = RFISlice.actions;
export default RFISlice.reducer;
