/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Badge, Popover } from 'antd';
import NotificationList from './components/List';
import { useGetNotificationCount } from '../../services/Notification/hooks';

const Notification = () => {
  const { data, refetch } = useGetNotificationCount();
  const [open, setOpen] = useState(false);
  const { unread_count = 0, isNewCount } = data?.data?.data || {};
  return (
    <Popover
      placement="bottomLeft"
      title=""
      trigger="click"
      defaultOpen={open}
      open={open}
      onOpenChange={() => setOpen((prev) => !prev)}
      destroyTooltipOnHide
      overlayStyle={{
        minWidth: '450px',
        maxWidth: '450px'
      }}
      content={
        <NotificationList setOpen={setOpen} refetchCount={refetch} isNewCount={isNewCount} />
      }>
      <Badge count={unread_count} size="small" style={{ top: 5, right: 10 }}>
        <i className="icon icon-notification" />
      </Badge>
    </Popover>
  );
};

export default Notification;
