/* eslint-disable consistent-return */
const generatePunchListURL = (projectId, punchListId, id) => {
  const base = `projects/${projectId}/punch_lists`;
  if (!punchListId && !id) {
    return base;
  }
  if (punchListId && !id) {
    return `${base}/${punchListId}`;
  }
  if (punchListId && id) {
    return `${base}/${punchListId}/punch_list_logs/${id}`;
  }
};

const getPunchList = (projectId) => generatePunchListURL(projectId);
const punchListFilters = (projectId) => `${generatePunchListURL(projectId)}/filters`;
const createPunchList = (projectId) => generatePunchListURL(projectId);
const viewPunchLists = (projectId) => generatePunchListURL(projectId);
const viewPunchList = (projectId, punchListId) => generatePunchListURL(projectId, punchListId);
const getPunchListLog = (projectId, punchListId) =>
  `projects/${projectId}/punch_lists/${punchListId}/punch_list_logs`;
const viewPunchListLog = (projectId, punchListId, id) =>
  generatePunchListURL(projectId, punchListId, id);
const getFilters = (projectId) => punchListFilters(projectId);
const addResponse = (projectId, id) =>
  `${generatePunchListURL(projectId, id)}/punch_list_responses`;
const changePunchListStatus = (projectId, id) =>
  `${generatePunchListURL(projectId, id)}/update_status`;
const createSubTask = (projectId) => `projects/${projectId}/sub_tasks`;
const updateSubTask = (projectId, id) => `projects/${projectId}/sub_tasks/${id}`;
const markAsClosed = (projectId, id) => `${generatePunchListURL(projectId, id)}/mark_as_closed`;
const createCheckList = (projectId, id) =>
  `${generatePunchListURL(projectId, id)}/check_list_items`;
const updateCheckList = (projectId, id, checklistId) =>
  `${generatePunchListURL(projectId, id)}/check_list_items/${checklistId}`;
const getSubTask = (projectId, id) => `projects/${projectId}/sub_tasks/${id}`;

export default {
  getPunchList,
  punchListFilters,
  createPunchList,
  viewPunchLists,
  getFilters,
  addResponse,
  viewPunchList,
  changePunchListStatus,
  createSubTask,
  updateSubTask,
  markAsClosed,
  getPunchListLog,
  viewPunchListLog,
  createCheckList,
  updateCheckList,
  getSubTask
};
