/* eslint-disable import/prefer-default-export */
export const ScheduleIcons = {
  reply: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1274_31811)">
        <path
          d="M13 4V8C6.425 9.028 3.98 14.788 3 20C2.963 20.206 8.384 14.038 13 14V18L21 11L13 4Z"
          stroke="#5C5C5C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1274_31811">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  download: (
    <svg
      className="download-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <rect y="0.000152588" width="24" height="24" rx="4" fill="#F6F6F6" />
      <path
        d="M3.99994 17.0002V19.0002C3.99994 19.5306 4.21065 20.0393 4.58573 20.4144C4.9608 20.7894 5.46951 21.0002 5.99994 21.0002H17.9999C18.5304 21.0002 19.0391 20.7894 19.4142 20.4144C19.7892 20.0393 19.9999 19.5306 19.9999 19.0002V17.0002"
        stroke="#FC8543"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99994 11.0002L11.9999 16.0002L16.9999 11.0002"
        stroke="#FC8543"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 4.00015V16.0002"
        stroke="#FC8543"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  schedule_logo: (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
      <g clipPath="url(#clip0_1274_31918)">
        <path
          d="M20.6413 36.75H8.75C7.82174 36.75 6.9315 36.3813 6.27513 35.7249C5.61875 35.0685 5.25 34.1783 5.25 33.25V12.25C5.25 11.3217 5.61875 10.4315 6.27513 9.77513C6.9315 9.11875 7.82174 8.75 8.75 8.75H29.75C30.6783 8.75 31.5685 9.11875 32.2249 9.77513C32.8813 10.4315 33.25 11.3217 33.25 12.25V19.25"
          stroke="#080808"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.5 31.5C24.5 33.3565 25.2375 35.137 26.5503 36.4497C27.863 37.7625 29.6435 38.5 31.5 38.5C33.3565 38.5 35.137 37.7625 36.4497 36.4497C37.7625 35.137 38.5 33.3565 38.5 31.5C38.5 29.6435 37.7625 27.863 36.4497 26.5503C35.137 25.2375 33.3565 24.5 31.5 24.5C29.6435 24.5 27.863 25.2375 26.5503 26.5503C25.2375 27.863 24.5 29.6435 24.5 31.5Z"
          stroke="#080808"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.25 5.25V12.25"
          stroke="#080808"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.25 5.25V12.25"
          stroke="#080808"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 19.25H33.25"
          stroke="#080808"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.5 28.868V31.5L33.25 33.25"
          stroke="#080808"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1274_31918">
          <rect width="42" height="42" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  reply_thread: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1274_31800)">
        <path
          d="M12.9999 4V8C6.42494 9.028 3.97994 14.788 2.99994 20C2.96294 20.206 8.38394 14.038 12.9999 14V18L20.9999 11L12.9999 4Z"
          stroke="#FC8543"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1274_31800">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  plus_white: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M13.1854 7.85214H2.81455M8 2.66669V13.0376V2.66669Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  info: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.51953C7.44365 4.51953 3.75 8.21318 3.75 12.7695C3.75 17.3259 7.44365 21.0195 12 21.0195C16.5563 21.0195 20.25 17.3259 20.25 12.7695C20.25 8.21318 16.5563 4.51953 12 4.51953ZM2.25 12.7695C2.25 7.38475 6.61522 3.01953 12 3.01953C17.3848 3.01953 21.75 7.38475 21.75 12.7695C21.75 18.1543 17.3848 22.5195 12 22.5195C6.61522 22.5195 2.25 18.1543 2.25 12.7695ZM11.25 8.76953C11.25 8.35532 11.5858 8.01953 12 8.01953H12.01C12.4242 8.01953 12.76 8.35532 12.76 8.76953C12.76 9.18374 12.4242 9.51953 12.01 9.51953H12C11.5858 9.51953 11.25 9.18374 11.25 8.76953ZM11 12.0195C10.5858 12.0195 10.25 12.3553 10.25 12.7695C10.25 13.1837 10.5858 13.5195 11 13.5195H11.25V16.7695C11.25 17.1837 11.5858 17.5195 12 17.5195H13C13.4142 17.5195 13.75 17.1837 13.75 16.7695C13.75 16.3553 13.4142 16.0195 13 16.0195H12.75V12.7695C12.75 12.3553 12.4142 12.0195 12 12.0195H11Z"
        fill="#3284FF"
      />
    </svg>
  )
};
