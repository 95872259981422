import React from 'react';
import CommonHeader from '../Header/commonHeader';
import LayoutWrapper from './styles';
import SuspenseWrapper from '../SuspenseWrapper';

const Layout = ({ children, onlyUserInfo, isSurvey }) => {
  return (
    <LayoutWrapper>
      <CommonHeader isSurvey={isSurvey} onlyUserInfo={onlyUserInfo} />
      <SuspenseWrapper>{children}</SuspenseWrapper>
    </LayoutWrapper>
  );
};

export default Layout;
