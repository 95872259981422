import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .ant-form-item {
    width: auto;
    margin-left: 20px;
  }
`;

export const FieldGreyWrapper = styled.div`
  padding: 12px;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  border: 1px solid #ececec;
  justify-content: space-between;
  margin-bottom: 20px;
  min-height: 55px;
  line-height: 30px;
  .ant-form-item {
    margin-bottom: 0;
  }
`;
export const FieldWhiteWrapper = styled(FieldGreyWrapper)`
  background: #fff;
`;

export const InlineTwoFields = styled.div`
  display: flex;
  gap: 1rem;
`;

export const FieldInline = styled.div`
  display: flex;
  .ant-form-item {
    margin: 10px;
  }
  .ant-form-item {
    display: flex;
    flex-direction: row;
  }
  .ant-form-item-row {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 10px;
    .ant-col {
      width: auto;
    }
  }
`;

export const VisitorWrapper = styled.div`
  display: flex;
  &.visitor {
    gap: 1rem;
    .sub {
      svg {
        fill: #009e1f;
      }
    }
    .close {
      svg {
        fill: #ff3030;
      }
    }
  }
`;

export const AttachWrapper = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffebe0;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const UsersCount = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #909090;
  margin-top: 8px;
`;
