const getDocumentList = (projectId, type) => `${type}/${projectId}/list_folders_and_files`;
const createFolder = (projectId, type) => `${type}/${projectId}/folders`;
const uploadDocument = (projectId, type) => `${type}/${projectId}/documents`;
const uploadDirectory = (projectId, type) => `${type}/${projectId}/folders/upload_directory`;
const showFolder = (projectId, folderId, type) => `${type}/${projectId}/folders/${folderId}`;
const moveDocument = (projectId, folderId, type) =>
  `${type}/${projectId}/documents/${folderId}/move_document`;
const moveFolder = (projectId, folderId, type) =>
  `${type}/${projectId}/folders/${folderId}/move_folder`;

export default {
  getDocumentList,
  createFolder,
  uploadDocument,
  uploadDirectory,
  showFolder,
  moveDocument,
  moveFolder
};
