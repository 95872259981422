import React from 'react';
import { Divider } from 'antd'
import { useSearchParams } from 'react-router-dom';

export default function OrderSummary() {
    
        const [searchParams] = useSearchParams();
        
        const plan = searchParams.get("plan");
    
    return (
        <div className='order-summary'>
            <h2>Order Summary</h2>
            <div className='order-summary-item'>
                <div className='summary-title'>Current Plan</div>
                <div className='summary-value' style={{ textTransform: "capitalize" }}>{plan}</div>
            </div>
            <div className='order-summary-item'>
                <div className='summary-title'>Price per seat</div>
                <div className='summary-value'>{plan == "Early Adopter - Monthly" ? "N/A" : "Custom Quote"}</div>
            </div>
            <div className='order-summary-item'>
                <div className='summary-title'>Number of active user seats*</div>
                <div className='summary-value'>{plan == "Early Adopter - Monthly" ? "Unlimited" : "Custom Quote"}</div>
            </div>
            <Divider />
            <div className='order-summary-item'>
                <div className='summary-title'><b>Total per month<br /></b></div>
                <div className='summary-value'><b>{plan == "Early Adopter - Monthly" ? "$1000 CAD + Tax" : "Custom Quote"}</b></div>
            </div>
        </div>
    )
}
