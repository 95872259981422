const saveCreateSubmittal = (projectId) => `projects/${projectId}/submittals`;
const saveViewSubmittalResponse = (projectId) => `projects/${projectId}/submittals`;
const submittalFilters = (projectId) => `projects/${projectId}/submittals/filters`;
const saveWorkflow = (projectId) => `projects/${projectId}/submittal_workflows`;
const getSubmittalWorkFlow = (projectId, id) => `projects/${projectId}/submittal_workflows/${id}`;
const viewSubmittals = (projectId, id) => `projects/${projectId}/submittals/${id}`;
const viewSubmittalsSettings = (projectId, id) => `projects/${projectId}/submittal_settings/${id}`;
const updateViewSubmittal = (projectId, id, response_id) =>
  `projects/${projectId}/submittals/${id}/submittal_responses/${response_id}`;
const submittalForwardReview = (projectId, id) =>
  `projects/${projectId}/submittals/${id}/forward_review`;
const closedSubmittal = (projectId, id) => `projects/${projectId}/submittals/${id}/mark_as_closed`;
export default {
  saveCreateSubmittal,
  saveViewSubmittalResponse,
  submittalFilters,
  saveWorkflow,
  getSubmittalWorkFlow,
  viewSubmittals,
  viewSubmittalsSettings,
  updateViewSubmittal,
  submittalForwardReview,
  closedSubmittal
};
