import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { rootReducer } from '../features';
import columnsReducer from '../features/kanban/slices/columns.slice';
import cardsReducer from '../features/kanban/slices/cards.slice';
import boardReducer from '../features/kanban/slices/borad.slice';

const store = configureStore({
  reducer: {
    root: rootReducer,
    columns: columnsReducer,
    cards: cardsReducer,
    board: boardReducer
  },
  middleware: getDefaultMiddleware({ serializableCheck: false })
});
export default store;
