/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Modal, Input, Form } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useModifyDocumentName } from '../../services/ProjectDoucment/hooks';
import showNotification from '../../components/ShowNotification';
import { DocIcons } from './DocumentIcons';

export const DocumentModel = styled.div`
  .ant-form-item-label {
    padding-bottom: 4px;

    label {
      color: #909090;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

function EditDocument({
  show,
  setIsModalOpen,
  folderId,
  refetch,
  folderRefetch,
  initialValues = null
}) {
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const { mutate, isLoading } = useModifyDocumentName(projectId);
  const isEdit = !!initialValues;

  function splitFileName(fileName) {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return [fileName, ''];
    }
    const name = fileName.slice(0, lastDotIndex);
    const extension = fileName.slice(lastDotIndex + 1);
    return [name, extension];
  }

  useEffect(() => {
    if (isEdit) {
      form.resetFields();
      const name = splitFileName(initialValues?.name)[0];
      form.setFieldsValue({
        name: name
      });
    }
  }, [initialValues]);
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const onFinish = (e) => {
    setSubmitting(true);
    const payload = {
      document: {
        name: `${e?.name}.${splitFileName(initialValues?.name)[1]}`
      }
    };
    mutate(
      { payload: payload, id: initialValues?.id },
      {
        onSuccess: (res) => {
          if (res.status === 200 || res.status === 201) {
            showNotification({
              message: `Record Updated Successfully`
            });
            setSubmitting(false);
            setIsModalOpen(false);
            form.resetFields();
            folderId ? folderRefetch() : refetch();
          }
        }
      }
    );
  };
  return (
    <>
      <Modal
        title={
          <div className="d-flex align-items-center">
            {DocIcons.edit}
            <span
              className="font-weight-700"
              style={{
                margin: '0 5px',
                fontSize: '20px',
                color: '#080808'
              }}>
              Rename File
            </span>
          </div>
        }
        open={show}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Close"
        cancelButtonProps={{
          className: 'cancel-btn'
        }}
        confirmLoading={submitting}
        okButtonProps={{
          disabled: submitting,
          loading: submitting,
          className: 'ant-btn btn-primary'
        }}
        onOk={form.submit}>
        <DocumentModel>
          <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
            <Form.Item
              name="name"
              label="File Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter file name'
                },
                {
                  max: 200,
                  message: 'Value should be less than 200 character'
                }
              ]}>
              <Input placeholder="Enter file name" />
            </Form.Item>
          </Form>
        </DocumentModel>
      </Modal>
    </>
  );
}

export default EditDocument;
