import styled from 'styled-components';

const DocTooltip = styled.div`
  width: 350px;
  padding: 15px;
  .tool-head {
    margin-bottom: 0;
    color: #080808;
    font-weight: 500;
  }
  .details {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    font-weight: 500;
    .size {
      color: #909090;
    }
  }
  .ant-descriptions-view {
    .details {
      padding-left: 0;
      padding-bottom: 5px;
    }
  }
`;

const UploadContainer = styled.div`
  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name {
    color: #ffa56e !important;
  }
`;

const UploadCustomContainer = styled(UploadContainer)`
  .ant-upload.ant-upload-drag {
    border: none;
  }
  .drag-upload-btn,
  .add-upload-btn {
    height: 40px;
    width: 100%;
    border-radius: 8px;
  }
  .drag-upload-btn {
    border: 1px dashed #d3d3d3;
    color: #5c5c5c;
    background: #f6f6f6;
  }
  .add-upload-btn {
    background: #ffebe0;
    color: #fc8543;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
`;

export { DocTooltip, UploadContainer, UploadCustomContainer };
