import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import forgotBanner from '../../images/forgotPassword.jpg';
import group from '../../images/Group.svg';
import { SignupWrapper } from './StyleComponent';
import { forgetPasswordCall, resetErrors, resetPassword } from '../../features/LoginSlice';
// eslint-disable-next-line import/no-named-as-default
import Success from './Success';
import { showErrors } from '../../utils/notificationUtils';

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [form] = Form.useForm();
  const [errorMessage, setError] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [pathName, setPathName] = useState('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setPathName(pathname.replace(/\//g, ''));
  }, [pathname]);

  const onFinishFailed = () => {
    setError(null);
  };

  const onFinish = (values) => {
    setError(null);
    setLoading(true);
    const payload = {
      user: {
        email: values.email.toLowerCase()
      }
    };
    dispatch(forgetPasswordCall({ payload }))
      .unwrap()
      .then((r) => {
        if (r.status === 200 || r.status === 201) {
          setResetSuccess(true);
          setLoading(false);
        } else {
          showErrors(r.response.data, setError);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const onSubmitPassword = (values) => {
    setError(null);
    const payload = {
      user: {
        reset_password_token: values.reset_password_token,
        password: values?.password,
        password_confirmation: values?.password_confirmation
      }
    };
    dispatch(resetPassword({ payload }))
      .unwrap()
      .then((r) => {
        if (r.status === 200 || r.status === 201) {
          setResetSuccess(true);
        } else {
          showErrors(r.response.data, setError);
          // setError(r.response.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="login">
        <SignupWrapper>
          <Row>
            <Col span={16}>
              <img src={forgotBanner} className="login-banner" alt="" />
            </Col>
            <Col span={8} className="login-content">
              {resetSuccess ? (
                <>
                  <Success resetSuccess={resetSuccess} />
                </>
              ) : null}
              {pathName === 'forgot_password' && !resetSuccess && (
                <>
                  <img className="group-img" src={group} />
                  <h2 style={{ fontWeight: '500' }}>Forgot Password</h2>
                  <p style={{ textAlign: 'center', color: '#909090' }}>
                    If you forgot your password or are having trouble logging in, we can email you a
                    password reset link.
                  </p>
                  <Form
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!'
                        },
                        { required: true, message: 'Email is required' }
                      ]}>
                      <Input
                        placeholder="Enter Email"
                        className={`${
                          errorMessage?.user_authentication
                            ? 'ant-input ant-input-status-error'
                            : ''
                        }`}
                      />
                    </Form.Item>
                    {errorMessage && (
                      <p>
                        <ExclamationCircleOutlined
                          style={{ color: '#ff4d4f', marginRight: '5px' }}
                        />
                        <span className="ant-form-item-explain-error" style={{ fontSize: '12px' }}>
                          {errorMessage}
                        </span>
                      </p>
                    )}
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        className="login-form-button"
                        style={{ height: '42px' }}>
                        Reset password
                      </Button>
                      <p style={{ padding: '10px 0', textAlign: 'center' }}>
                        <a onClick={() => navigate('/login')}>Return to Login</a>
                      </p>
                    </Form.Item>
                  </Form>
                </>
              )}
              {pathName === 'reset_password' && !resetSuccess && (
                <>
                  <img className="group-img" src={group} />
                  <h2 style={{ fontWeight: '500' }}>Reset Password</h2>
                  <p style={{ textAlign: 'center', color: '#909090' }}>
                    If you forgot your password or are having trouble logging in, we can email you a
                    password reset link.
                  </p>
                  <Form layout="vertical" autoComplete="off" onFinish={onSubmitPassword}>
                    <Form.Item
                      name="reset_password_token"
                      label="Password Token"
                      rules={[{ required: true, message: 'Password token is required' }]}>
                      <Input
                        placeholder="Enter Password Token"
                        className={`${
                          errorMessage?.user_authentication
                            ? 'ant-input ant-input-status-error'
                            : ''
                        }`}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="New Passworrd"
                      rules={[{ required: true, message: 'Password is required' }]}>
                      <Input.Password
                        placeholder="Enter Password"
                        className={`${
                          errorMessage?.user_authentication
                            ? 'ant-input ant-input-status-error'
                            : ''
                        }`}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password_confirmation"
                      label="Confirm Passworrd"
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: 'Confirm Password is required'
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('The new password that you entered do not match!')
                            );
                          }
                        })
                      ]}>
                      <Input.Password
                        placeholder="Enter Confirm Password"
                        className={`${
                          errorMessage?.user_authentication
                            ? 'ant-input ant-input-status-error'
                            : ''
                        }`}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        style={{ height: '42px' }}>
                        Reset password
                      </Button>
                      <p style={{ padding: '10px 0', textAlign: 'center' }}>
                        <a onClick={() => navigate('/login')}>Return to Login</a>
                      </p>
                      {errorMessage && (
                        <p>
                          <ExclamationCircleOutlined
                            style={{ color: '#ff4d4f', marginRight: '5px' }}
                          />
                          <span
                            className="ant-form-item-explain-error"
                            style={{ fontSize: '12px' }}>
                            {errorMessage}
                          </span>
                        </p>
                      )}
                    </Form.Item>
                  </Form>
                </>
              )}
            </Col>
          </Row>
        </SignupWrapper>
      </div>
    </>
  );
}

export default ForgotPassword;
