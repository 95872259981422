import React, { useEffect } from 'react';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, resetErrors } from '../../../../../features/LoginSlice';
import Success from './Success';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 24,
			offset: 0
		}
	}
};

function CreateAccount() {
	const dispatch = useDispatch();
	const { errors = [], registerSuccess = false } = useSelector((state) => state.root.signUp);
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const plan = searchParams.get("plan");

	useEffect(() => {
		if (plan == "enterprise" || 
			plan == "professional" || 
			plan == "Early Adopter - Monthly" ||
			plan == "Early Adopter - Annual") { }
		else {
			navigate("/pricing");
		}
	}, [plan])

	useEffect(() => {
		if (errors) {
			setTimeout(() => {
				dispatch(resetErrors());
			}, 5000);
		}
	}, [errors]);

	const onFinish = (values) => {
		const payload = {
			company: {
				name: values.name,
				users_attributes: [
					{
						email: values.email,
						first_name: values.first_name,
						last_name: values.last_name,
						username: values.username,
						password: values.password,
						password_confirmation: values.password_confirmation
					}
				]
			}
		};
		dispatch(createUser({ payload }));
	}

	return (
		<Row>
			<Col className="login-content">
				{registerSuccess ? <Success invite={false} /> : (
					<>
						<Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
							<Row gutter={[8, 8]}>
								<Col span={12}>
									<Form.Item
										name="first_name"
										label="First Name"
										rules={[{ required: true, message: 'Please input your  First name' }]}>
										<Input placeholder="First Name" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="last_name"
										label="Last Name"
										rules={[{ required: true, message: 'Please input your  Last name' }]}>
										<Input placeholder="Last Name" />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[8, 8]}>
								<Col span={24}>
									<Form.Item
										name="name"
										label="Company Name"
										rules={[{ required: true, message: 'Please input your Company name' }]}>
										<Input placeholder="Company Name" />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[8, 8]}>
								<Col span={12}>
									<Form.Item
										name="username"
										label="User Name"
										rules={[{ required: true, message: 'Please input your user name' }]}>
										<Input placeholder="User Name" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="email"
										label="Email"
										rules={[
											{
												type: 'email',
												message: 'The input is not valid E-mail!'
											},
											{
												required: true,
												message: 'Please input your E-mail!'
											}
										]}>
										<Input placeholder="Email" />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[8, 8]}>
								<Col span={12}>
									<Form.Item
										name="password"
										label="Password"
										rules={[
											{
												required: true,
												message: 'Please input your password!'
											}
										]}
										hasFeedback>
										<Input.Password placeholder="Confirm Password" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="password_confirmation"
										label="Confirm Password"
										dependencies={['password']}
										rules={[
											{
												required: true,
												message: 'Please confirm your password!'
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue('password') === value) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error('The new password that you entered do not match!')
													);
												}
											})
										]}>
										<Input.Password placeholder="Confirm Password" />
									</Form.Item>
								</Col>
							</Row>

							<Form.Item
								name="agreement"
								valuePropName="checked"
								rules={[
									{
										validator: (_, value) =>
											value
												? Promise.resolve()
												: Promise.reject(new Error('Should accept agreement'))
									}
								]}
								{...tailFormItemLayout}>
								<Checkbox>
									I have read and Agree to ProjectLoop's <Link to="/terms">Terms & Conditions</Link> and <Link to="/privacy">Privacy Policy</Link>
								</Checkbox>
							</Form.Item>
							<Form.Item className="signup-btn">
								<Button
									type="primary"
									htmlType="submit"
									className="login-form-button"
									style={{ height: '42px' }}>
									Continue to Billing
								</Button>
							</Form.Item>
						</Form>
						{errors &&
							errors.length > 0 &&
							errors.map((e, i) => {
								return (
									<>
										<div
											key={i}
											style={{ display: 'flex', flexWrap: 'nowrap' }}
											className="d-flex flex-row gap-16 item-center">
											<div
												id={i}
												className="ant-form-item-explain ant-form-item-explain-connected"
												role="alert">
												<div className="ant-form-item-explain-error">{e}</div>
											</div>
										</div>
									</>
								);
							})}
					</>
				)}
			</Col>
		</Row>
	);
}

export default CreateAccount;
