const mockCards = [
  {
    id: '2fab1909',
    category: 'bug',
    title: 'Reset password button not working',
    description: 'The button does not contain any feedback, when you click on it, it does nothing',
    status: 'In-progress',
    priority_text: 'Very High', // priority_text_text
    due_in_days: 10, // due_in_days
    hidden: false,
    created_by: 'velpradeep (User)',
    tags: ['Final Punch', 'Maintenance Work'],
    assignees: [
      {
        id: 2,
        assignee: 'velpradeep (User)',
        user_id: 1
      },
      {
        id: 3,
        assignee: 'danielcw (User)',
        user_id: 2
      },
      {
        id: 4,
        assignee: 'JacobJones (User)',
        user_id: 3
      },
      {
        id: 5,
        assignee: 'DarleneRoberston (User)',
        user_id: 4
      }
    ]
  },
  {
    id: '5be53f27',
    category: 'feature',
    title: 'Create clear filters button',
    description:
      'The user can select the filters but they should also be able to clear them without having to reload the page',
    status: 'To Do',
    priority_text: 'Critical',
    due_in_days: 18,
    hidden: false,
    created_by: 'velpradeep (User)',
    tags: ['Final Punch'],
    assignees: [
      {
        id: 2,
        assignee: 'velpradeep (User)',
        user_id: 1
      },
      {
        id: 3,
        assignee: 'danielcw (User)',
        user_id: 2
      },
      {
        id: 4,
        assignee: 'JacobJones (User)',
        user_id: 3
      },
      {
        id: 5,
        assignee: 'DarleneRoberston (User)',
        user_id: 4
      }
    ]
  },
  {
    id: '6ed5a4b0',
    category: 'infra',
    title: 'Set up the staging environment',
    description: '',
    status: 'To Do',
    priority_text: 'Medium',
    due_in_days: 25,
    hidden: false,
    created_by: 'velpradeep (User)',
    tags: ['Maintenance Work'],
    assignees: [
      {
        id: 2,
        assignee: 'velpradeep (User)',
        user_id: 1
      },
      {
        id: 3,
        assignee: 'danielcw (User)',
        user_id: 2
      },
      {
        id: 4,
        assignee: 'JacobJones (User)',
        user_id: 3
      },
      {
        id: 5,
        assignee: 'DarleneRoberston (User)',
        user_id: 4
      }
    ]
  },
  {
    id: '55cac86b',
    category: 'deploy',
    title: 'Send first deploy to prod',
    description: '',
    status: 'To Do',
    priority_text: 'High',
    due_in_days: 6,
    hidden: false,
    created_by: 'velpradeep (User)',
    assignees: [
      {
        id: 2,
        assignee: 'velpradeep (User)',
        user_id: 1
      },
      {
        id: 3,
        assignee: 'danielcw (User)',
        user_id: 2
      },
      {
        id: 4,
        assignee: 'JacobJones (User)',
        user_id: 3
      },
      {
        id: 5,
        assignee: 'DarleneRoberston (User)',
        user_id: 4
      }
    ]
  },
  {
    id: '37839097',
    category: 'deploy',
    title: 'Send first deploy to develop environment',
    description: '',
    status: 'Rejected',
    priority_text: 'Low',
    due_in_days: 10,
    hidden: false,
    created_by: 'danielcw (User)',
    tags: ['Final Punch', 'Maintenance Work'],
    assignees: [
      {
        id: 2,
        assignee: 'velpradeep (User)',
        user_id: 1
      },
      {
        id: 3,
        assignee: 'danielcw (User)',
        user_id: 2
      },
      {
        id: 4,
        assignee: 'JacobJones (User)',
        user_id: 3
      },
      {
        id: 5,
        assignee: 'DarleneRoberston (User)',
        user_id: 4
      }
    ]
  },
  {
    id: '74d031c0',
    category: 'feature',
    title: 'Create landing page',
    description: '',
    status: 'Approved',
    priority_text: 'Very High',
    due_in_days: 2,
    hidden: false,
    created_by: 'velpradeep (User)',
    assignees: [
      {
        id: 2,
        assignee: 'velpradeep (User)',
        user_id: 1
      },
      {
        id: 3,
        assignee: 'danielcw (User)',
        user_id: 2
      },
      {
        id: 4,
        assignee: 'JacobJones (User)',
        user_id: 3
      },
      {
        id: 5,
        assignee: 'DarleneRoberston (User)',
        user_id: 4
      }
    ]
  },
  {
    id: 'a04170d8',
    category: 'refactor',
    title: 'Make the onDragEnd function more efficient',
    description: '',
    status: 'To Do',
    priority_text: 'Very High',
    due_in_days: 15,
    hidden: false,
    created_by: 'velpradeep (User)',
    assignees: [
      {
        id: 2,
        assignee: 'velpradeep (User)',
        user_id: 1
      },
      {
        id: 3,
        assignee: 'danielcw (User)',
        user_id: 2
      },
      {
        id: 4,
        assignee: 'JacobJones (User)',
        user_id: 3
      },
      {
        id: 5,
        assignee: 'DarleneRoberston (User)',
        user_id: 4
      }
    ]
  },
  {
    id: '29065b36',
    category: 'deploy',
    title: 'Send first deploy to develop environment',
    description: '',
    status: 'Approved',
    priority_text: 'Low',
    due_in_days: 10,
    hidden: false,
    created_by: 'danielcw (User)',
    tags: ['Final Punch', 'Maintenance Work'],
    assignees: [
      {
        id: 2,
        assignee: 'velpradeep (User)',
        user_id: 1
      },
      {
        id: 3,
        assignee: 'danielcw (User)',
        user_id: 2
      },
      {
        id: 4,
        assignee: 'JacobJones (User)',
        user_id: 3
      },
      {
        id: 5,
        assignee: 'DarleneRoberston (User)',
        user_id: 4
      }
    ]
  },
  {
    id: '32eb3393',
    category: 'feature',
    title: 'Create light and dark theme switch',
    description: '',
    status: 'Ready For Inspection',
    priority_text: 'Very Low',
    due_in_days: 8,
    hidden: false,
    created_by: 'JacobJones (User)',
    tags: ['Final Punch', 'Maintenance Work'],
    assignees: [
      {
        id: 2,
        assignee: 'velpradeep (User)',
        user_id: 1
      },
      {
        id: 3,
        assignee: 'danielcw (User)',
        user_id: 2
      },
      {
        id: 4,
        assignee: 'JacobJones (User)',
        user_id: 3
      },
      {
        id: 5,
        assignee: 'DarleneRoberston (User)',
        user_id: 4
      }
    ]
  }
];

export default mockCards;
