import styled from 'styled-components';

const PunchListWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  .list-content-wrap {
    display: flex;
    height: calc(100vh - 132px);
  }

  .general-wrap {
    background: #fff !important;
  }
  .ant-menu-item-selected {
    svg {
      path {
        background-color: #fc8543;
        stroke: #fc8543;
      }
    }
  }
  .folder-title {
    padding: 0px 5px;
    color: rgb(8, 8, 8);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.176px;
    text-decoration-line: underline;
    text-transform: none;
  }
  .btn-danger {
    color: #ff3030 !important;
    background: #fff !important;
    border: 1px solid #ff3030 !important;
    font-weight: bold !important;
  }
  .ant-page-header {
    padding: 16px 20px 16px 16px;
  }
  .project-list-header {
    padding: 16px 20px 16px 20px;
  }
  .dir-icon {
    width: 25px !important;
    min-width: 23px !important;
  }

  .rfi-table {
    padding: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    flex-grow: 1;
    background-color: #f6f6f6;

    .ant-table-content {
      overflow-x: scroll !important;
      //max-height: calc(100vh - 280px);
    }
    .punchlistTemplate {
      .ant-table-content {
        overflow-x: hidden !important;
      }
    }
    //.ant-table-content::-webkit-scrollbar {
    //  width: 20px !important;
    //}

    .ant-table-content::-webkit-scrollbar-track {
      background: transparent;
    }

    .ant-table-content::-webkit-scrollbar-thumb {
      background-color: #333333;
      border-radius: 15px;
      border: 1px solid #333333;
    }
  }
  .ant-page-header-heading-left {
    .ant-breadcrumb li {
      font-size: 24px;
      .last-child {
        color: #080808;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 133.333% */
        letter-spacing: -0.456px;
      }
    }
  }
  .settings-page-wrapper {
    margin: 20px;
  }
  .list-header {
    width: 100%;
  }

  .settings-tab {
    flex: 1;
    overflow-y: scroll;
  }
  .name-group {
    background: #fff;
    display: flex;
    align-items: center;
  }
  .picked {
    background: #fff8f0 !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      color: #5c5c5c;
    }
  }
  .view-tab {
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-nav {
      margin: 0;
    }
    .ant-tabs-tab {
      margin: 0 0 0 20px;
      padding: 0;
    }
    .ant-tabs-nav::before {
      border: 0;
    }
  }
  .cal-wrp {
    padding: 30px;
    width: 100%;
    height: inherit;
    overflow: scroll;
    .ant-picker-calendar-header {
      padding: 12px;
    }
  }
  &.logs {
    .ant-page-header-heading-left {
      flex: 1;
      .ant-page-header-heading-sub-title {
        margin: 0 auto;
      }
    }
  }
  th.ant-table-cell {
    background: #ececec !important;
  }
  .ant-table-column-title {
    .ant-row-middle {
      color: #080808;
      font-weight: 500;
    }
  }
  .ant-table-pagination-right {
    .ant-pagination-prev {
      order: 2;
    }
    .ant-pagination-item {
      order: 3;
    }
    .ant-pagination-next {
      order: 4;
    }
    .ant-pagination-options {
      order: 1;
      flex: 1;
      margin: 0;
    }
  }
`;

export default PunchListWrapper;
