import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './theme/antd-customized.css';
import './App.css';
import './common.scss';
import 'simplebar-react/dist/simplebar.min.css';
import AppRoutes from './routes/index';
import ScrollToTop from './utils/ScrollToTop';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
