import React from 'react';
import { useRoutes } from 'react-router-dom';
import Cookies from 'js-cookie';
import protectedRoutes from './protected';
import publicRoutes from './public';

function AppRoutes() {
  const auth = Cookies.get('auth-token') ? 'Yes' : 'No';
  const routes = auth === 'Yes' ? protectedRoutes : publicRoutes;
  // const routes = protectedRoutes;
  const element = useRoutes([...routes]);
  if (auth === 'Yes') {
    return element;
  }
  return <>{element}</>;
}

export default AppRoutes;
