const mockColumns = [
  {
    id: 'To Do',
    title: 'To Do',
    cardsIds: ['5be53f27', '6ed5a4b0', '55cac86b', 'a04170d8']
  },
  {
    id: 'In-progress',
    title: 'In-progress',
    cardsIds: ['2fab1909']
  },
  {
    id: 'Ready For Inspection',
    title: 'Ready For Inspection',
    cardsIds: ['32eb3393']
  },
  {
    id: 'Approved',
    title: 'Approved',
    cardsIds: ['29065b36', '74d031c0']
  },
  {
    id: 'Rejected',
    title: 'Rejected',
    cardsIds: ['37839097']
  }
];

export default mockColumns;
