/* eslint-disable consistent-return */
import axios from 'axios';
import Cookies from 'js-cookie';
import APICONST from '../constants';
import showNotification from '../components/ShowNotification';

const headers = () => {
  const token = Cookies.get('auth-token');
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
};
const formHeaders = () => {
  const token = Cookies.get('auth-token');
  return {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`
  };
};
const getCSVHeader = () => {
  const token = Cookies.get('auth-token');
  return {
    Accept: 'text/csv',
    'Content-Type': 'text/csv',
    Authorization: `Bearer ${token}`
  };
};

const get = async (url, payload, csvHeader = false, responseType = '') => {
  return axios({
    method: 'get',
    headers: csvHeader ? getCSVHeader() : headers(),
    url: APICONST.BASE_URL + `${url}`,
    params: payload,
    responseType: responseType || 'json'
  })
    .then((response) => {
      const pageItems = response.headers?.['page-items'] || '';
      const totalCount = response.headers?.['total-count'] || '';
      const totalPages = response.headers?.['total-pages'] || '';
      const currentPage = response.headers?.['current-page'] || '';
      return { ...response, pagination: { pageItems, totalCount, totalPages, currentPage } };
    })
    .catch((e) => {
      if (e.message === 'Network Error') {
        throw e.message;
      } else if (e?.response?.status === 404 && e?.response?.data?.error === 'Record Not Found') {
        window.location.href = '/not-found';
      } else {
        return e;
      }
    });
};
const post = async (url, payload, formHeader = false) => {
  return axios({
    headers: formHeader ? formHeaders() : headers(),
    method: 'post',
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      if (e.message === 'Network Error') {
        console.error(e.message);
      }
      return e;
    });
};

const put = async (url, payload, formHeader = false) => {
  return axios({
    method: 'put',
    headers: formHeader ? formHeaders() : headers(),
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      if (e.message === 'Network Error') {
        console.error(e.message);
      }
      return e;
    });
};

const patch = async (url, payload, formHeader = false) => {
  return axios({
    method: 'patch',
    headers: formHeader ? formHeaders() : headers(),
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      if (e.message === 'Network Error') {
        console.error(e.message);
      }
      return e;
    });
};
const deleteCall = async (url, payload, token = '') => {
  return axios({
    method: 'delete',
    headers: headers(),
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      if (e.message === 'Network Error') {
        console.error(e.message);
      } else {
        return e;
      }
    });
};

const postRequest = async (url, payload, formHeader = false) => {
  return axios({
    headers: formHeader ? formHeaders() : headers(),
    method: 'post',
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  }).then((response) => {
    return response;
  });
};

const putRequest = async (url, payload, formHeader = false) => {
  return axios({
    method: 'put',
    headers: formHeader ? formHeaders() : headers(),
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  }).then((response) => {
    return response;
  });
};

const patchRequest = async (url, payload, formHeader = false) => {
  return axios({
    method: 'patch',
    headers: formHeader ? formHeaders() : headers(),
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  }).then((response) => {
    return response;
  });
};

// eslint-disable-next-line default-param-last
const downloadZip = async (url, fileName = 'download', loaderCallback) => {
  try {
    const token = Cookies.get('auth-token');
    const { data } = await axios({
      method: 'get',
      url: `${APICONST.BASE_URL}${url}`,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/zip',
        Accept: 'application/zip',
        Authorization: `Bearer ${token}`
      }
    });
    if (loaderCallback) {
      loaderCallback(false);
    }
    const downloadUrl = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${fileName}.zip`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    if (loaderCallback) {
      loaderCallback(false);
    }
    console.error('Error while downloading zip', error);
    showNotification({ type: 'error', message: 'Error while downloading zip' });
  }
};

const downloadDocument = async (url, fileName = 'file') => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream' // General type for binary data
      }
    });

    const contentType = response.headers.get('content-type');
    const blob = await response.blob();
    const urlObject = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = urlObject;
    link.setAttribute('download', fileName || `download.${contentType.split('/')[1]}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(urlObject); // Clean up after download
  } catch (error) {
    console.error('Error downloading the file', error);
  }
};

export const API_SERVICE = {
  post: post,
  get: get,
  put: put,
  patch: patch,
  delete: deleteCall,
  postRequest,
  putRequest,
  patchRequest,
  downloadZip,
  downloadDocument
};

export default API_SERVICE;
