import React from 'react';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import successIcon from '../../../../../images/Success.svg';

function Success() {
    const navigate = useNavigate();

    return (
        <>
            <Row align="middle" style={{ textAlign: 'center', display: 'block' }}>
                <Col>
                    <img
                        src={successIcon}
                        style={{
                            verticalAlign: 'middle'
                        }}
                    />
                    <h1 className="text-center">
                        Registration Completed Successfully
                    </h1>
                    <p className="text-center">
                        Please check your registered email for email verification
                    </p>
                    <Button
                        type="primary"
                        className="login-form-button"
                        onClick={() => navigate('/login')}
                        style={{ width: 'auto', height: '35px' }}>
                        Go to Login page
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default Success;
