import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ACTIONS from '../actions';
import { API_SERVICE } from '../services/CommonApi';
import service from '../services/Login';

const initialState = {
  registerData: {},
  registerSuccess: false,
  userData: null,
  errors: ''
};

export const createUser = createAsyncThunk(ACTIONS.SING_UP, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.post(service.createUser(), arg.payload);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const forgetPasswordCall = createAsyncThunk(
  'FORGET_PASSWORD_ACTION',
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.post(`password_resets`, arg.payload);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const resetPassword = createAsyncThunk('RESET_PASSWORD_ACTION', async (arg, thunkAPI) => {
  try {
    return API_SERVICE.patch(`password_resets/reset`, arg.payload);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetErrors: (state, { payload }) => {
      state.errors = '';
    },
    updateUserProfile: (state, { payload }) => {
      state.userData = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createUser.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.registerData = payload.data.data;
        state.registerSuccess = true;
      } else {
        state.errors = payload.response.data;
      }
    });
  }
});

export const { resetErrors, updateUserProfile } = LoginSlice.actions;
export default LoginSlice.reducer;
