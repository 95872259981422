import { put, takeEvery, delay } from 'redux-saga/effects';
import { handleActions, createAction } from 'redux-actions';

// consts
const INCREMENT = 'INCREMENT';
const DECREMENT = 'DECREMENT';
const INCREMENT_VALUE = 'INCREMENT_VALUE';
const DECREMENT_VALUE = 'DECREMENT_VALUE';

// actions

export const increment = createAction(INCREMENT);
export const decrement = createAction(DECREMENT);
export const incrementValue = createAction(INCREMENT_VALUE);
export const decrementValue = createAction(DECREMENT_VALUE);

function* incrementSaga() {
  yield delay(500);
  yield put(incrementValue());
}

function* decrementSaga() {
  yield delay(500);
  yield put(decrementValue());
}

export function* counterSaga() {
  yield takeEvery(INCREMENT, incrementSaga);
  yield takeEvery(DECREMENT, decrementSaga);
}

export default handleActions(
  {
    [INCREMENT_VALUE]: (state) => state + 1,
    [DECREMENT_VALUE]: (state) => state - 1
  },
  1 // Initial Value
);

export const RFI_TABLE_DATA = 'rfi/RFI_TABLE_DATA';
export const VIEW_RFI = 'rfi/VIEW_RFI';
export const HISTORY_RFI = 'rfi/HISTORY_RFI';
export const HISTORY_RFI_VIEW = 'rfi/HISTORY_RFI_VIEW';
export const GET_RFI = 'rfi/GET_RFI';
export const DRAFT_RFI = 'rfi/DRAFT_RFI';
export const VIEW_RFI_FILTER = 'rfi/VIEW_RFI_FILTER';
export const REOPEN_RFI = 'rfi/REOPEN';
export const REQUEST_REOPEN_RFI = 'rfi/REQUEST_REOPEN';
export const MARK_AS_CLOSED_RFI = 'rfi/CLOSED';
export const MARK_AS_DELETED_RFI = 'rfi/DELETED';
export const MARK_AS_REJECTED_RFI = 'rfi/REJECTED';
export const MARK_AS_OPEN_RFI = 'rfi/MARKS_OPEN';
export const RESTORE_DELETED_RFI = 'rfi/RESTORE';
export const FORWARD_REVIEW = 'rfi/FORWARD_REVIEW';
export const ADD_ASSIGNEE = 'rfi/ADD_ASSIGNEE';
export const MARK_AS_OFFICIAL = 'rfi/MARK_AS_OFFICIAL';
export const ADD_EDIT_RESPONSE = 'rfi/ADD_EDIT_RESPONSE';
export const SETTINGS_RESPONSE = 'rfi/SETTINGS_RESPONSE';
export const ADD_RFI_ACTION = 'rfi/ADD_RFI_ACTION';
export const UPDATE_RFI_ACTION = 'rfi/UPDATE_RFI_ACTION';
export const UPDATE_RFI_SETTINGS = 'rfi/UPDATE_RFI_SETTINGS';
export const CREATE_CUSTOM_SETTING = 'rfi/CREATE_CUSTOM_SETTING';
export const DELETE_CUSTOM_SETTING = 'rfi/DELETE_CUSTOM_SETTING';
export const GET_CUSTOM_SETTING = 'rfi/GET_CUSTOM_SETTING';

// SUBMITTAL ACTIONS
export const SUBMITTAL_GET_GENERAL_SETTING = 'submittal/GET_GENERAL_SETTING';
export const SUBMITTAL_UPDATE_GENERAL_SETTING = 'submittal/UPDATE_GENERAL_SETTING';
export const SUBMITTAL_GET_SUBMITTAL_TYPES = 'submittal/GET_SUMITTAL_TYPES';
export const SUBMITTAL_UPDATE_SUBMITTAL_TYPE = 'submittal/UPDATE_SUBMITTAL_TYPE';
export const SUBMITTAL_DELETE_SUBMITTAL_TYPE = 'submittal/DELETE_SUBMITTAL_TYPE';
export const SUBMITTAL_CREATE_SUBMITTAL_TYPE = 'submittal/CREATE_SUBMITTAL_TYPE';
export const SUBMITTAL_GET_CUSTOM_FIELDS = 'submittal/GET_CUSTOM_FIELDS';
export const SUBMITTAL_CREATE_CUSTOM_FIELDS = 'submittal/CREATE_CUSTOM_FIELDS';
export const SUBMITTAL_DELETE_CUSTOM_FIELDS = 'submittal/DELETE_CUSTOM_FIELDS';
export const SUBMITTAL_UPDATE_CUSTOM_FIELDS = 'submittal/UPDATE_CUSTOM_FIELDS';
export const SUBMITTAL_GET_LIST_WORKFLOWS = 'submittal/GET_LIST_WORKFLOWS';
export const SUBMITTAL_DELETE_WORKFLOW = 'submittal/DELETE_WORKFLOW';
export const SUBMITTAL_CREATE_WORKFLOW = 'submittal/CREATE_WORKFLOW';
export const SUBMITTAL_UPDATE_WORKFLOW = 'submittal/UPDATE_WORKFLOW';

// LISTING SUBMITTALS
export const SUBMITTAL_GET_LIST_SUBMITTALS = 'submittal/GET_LIST_SUBBMITALS';

// CREATE SUBMITTALS
export const CREATE_SUBMITTALS = 'createSubmittal/CREATE_SUBMITTALS';
export const SAVE_WORKFLOW = 'createSubmittal/SAVE_WORKFLOW';
export const DELETE_WORKFLOW = 'createSubmittal/DELETE_WORKFLOW';
export const SUBMITTAL_FILTERS = 'createSubmittal/SUBMITTAL_FILTERS';
export const GET_SUBMITTAL_WORKFLOW = 'createSubmittal/GET_SUBMITTAL_WORKFLOW';
// VIEW SUBMITTALS
export const SAVE_VIEW_SUBMITTALS = 'viewSubmittal/SAVE_VIEW_SUBMITTALS';
export const GET_VIEW_SUBMITTALS = 'viewSubmittal/GET_VIEW_SUBMITTALS';
export const CLOSED_SUBMITTALS = 'viewSubmittal/CLOSED_SUBMITTALS';
export const UPDATE_VIEW_SUBMITTALS = 'viewSubmittal/UPDATE_VIEW_SUBMITTALS';
export const VIEW_SUBMITTALS_SETTINGS_ACTION = 'viewSubmittal/GET_VIEW_SUBMITTALS_SETTINGS';
export const SUBMITTAL_FORWARD_REVIEW = 'viewSubmittal/SUBMITTAL_FORWARD_REVIEW';
// PUNCH_LIST
export const GET_PUNCH_LIST_ACTION = 'PUNCH/GET_PUNCH_LIST';
export const CHANGE_PUNCH_LIST_STATUS = 'PUNCH/CHANGE_PUNCH_LIST_STATUS';
export const GET_PUNCH_LIST_FILTERS_ACTION = 'PUNCH/GET_PUNCH_LIST_FILTERS';
export const ADD_RESPONSE = 'PUNCH/ADD_RESPONSE';
export const VIEW_PUNCHLIST = 'PUNCH/VIEW_PUNCHLIST';
export const SHOW_ALL_ACTIVITY_LOG = 'PUNCH/SHOW_ALL_ACTIVITY_LOG';
export const SHOW_ACTIVITY_LOG = 'PUNCH/SHOW_ACTIVITY_LOG';
export const CREATE_CHECKLIST = 'PUNCH/CREATE_CHECKLIST';
export const UPDATE_CHECKLIST = 'PUNCH/UPDATE_CHECKLIST';
export const DELETE_CHECKLIST = 'PUNCH/DELETE_CHECKLIST';
export const GET_SUBTASK = 'PUNCH/GET_SUBTASK';
export const UPDATE_PUNCHLIST = 'PUNCH/UPDATE_PUNCHLIST';
export const CREATE_SUBTASK = 'PUNCH/CREATE_SUBTASK';
export const UPDATE_SUBTASK = 'PUNCH/UPDATE_SUBTASK';
export const DELETE_PUNCHLIST = 'PUNCH/DELETE_PUNCH';
export const MARK_AS_CLOSED = 'PUNCH/MARK_AS_CLOSED';
// CREATE PUNCHLIST
export const CREATE_PUNCHLIST = 'punchlist/CREATE_PUNCH_LIST';
export const VIEW_PUNCHLIST_FILTER = 'punchlist/VIEW_PUNCH_LIST_FILTER';

// PROJECT
export const CREATE_PROJECT = 'project/CREATE_PROJECT';
export const VIEW_PROJECT = 'project/VIEW_PROJECT';
export const UPDATE_PROJECT = 'project/UPDATE_PROJECT';
export const EDIT_PROJECT = 'project/EDIT_PROJECT';

// BOARD
export const GET_BOARD_VIEW = 'board/GET_BOARD_VIEW';
export const GET_BOARD_VIEW_PAGE = 'board/GET_BOARD_VIEW_PAGE';

// DRAWING AREA
export const ADD_DRAWING_AREA = 'DRAWING/ADD_DRAWING_AREA';
export const VIEW_DRAWING_AREA_LIST = 'DRAWING/VIEW_DRAWING_AREA_LIST';
export const EDIT_DRAWING_AREA = 'DRAWING/EDIT_DRAWING_AREA';
export const UPDATE_DRAWING_AREA = 'DRAWING/UPDATE_DRAWING_AREA';
export const DELETE_DRAWING_AREA = 'DRAWING/DELETE_DRAWING_AREA';
export const VIEW_UPLOAD_LOG_LIST = 'DRAWING/VIEW_UPLOAD_LOG_LIST';

// PROJECT DOCUMENT
export const GET_DOCUMENT_LIST = 'PROJECT_DOCUMENT/GET_DOCUMENT_LIST';
export const CREATE_FOLDER = 'PROJECT_DOCUMENT/CREATE_FOLDER';
export const UPDATE_FOLDER = 'PROJECT_DOCUMENT/UPDATE_FOLDER';
export const DELETE_FOLDER = 'PROJECT_DOCUMENT/DELETE_FOLDER';
export const UPLOAD_DOCUMENT = 'PROJECT_DOCUMENT/UPLOAD_DOCUMENT';
export const UPLOAD_DIRECTORY = 'PROJECT_DOCUMENT/UPLOAD_DIRECTORY';
export const SHOW_FOLDER = 'PROJECT_DOCUMENT/SHOW_FOLDER';
export const MOVE_FOLDER = 'PROJECT_DOCUMENT/MOVE_FOLDER';

// LOGIN
export const SING_UP = 'LOGIN/SING_UP';
