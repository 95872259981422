import React from 'react';

const GreyBoxComponent = ({ children, ...props }) => {
  return (
    <>
      <div className="IndividualsSection" style={props.style}>
        {props?.title && <h3 className="section-title">{props?.title}</h3>}
        <div className="grey-box">
          <div className={`form-group-container ${props?.className || ''}`}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default GreyBoxComponent;
