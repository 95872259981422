import React from 'react'
import CreateAccount from './CreateAccount'
import OrderSummary from './OrderSummary'

export default function Checkout() {
    return (
        <div className='pricing-page-wrapper'>
            <div className='pricing-container'>
                <div>
                    <h1 className='pricing-title'>CHECKOUT</h1>
                    <p className='pricing-subtext mb-10'>Create account</p>
                    <p className='checkout-subtext'>Welcome early adopter!  We cannot thank you enough</p>
                </div>
                <div className='checkout-container'>
                    <CreateAccount />
                    <OrderSummary />
                </div>
            </div>

        </div>
    )
}
