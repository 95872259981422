/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { Button, Modal, Col, Form, Row, Upload, Empty } from 'antd';
import { useParams } from 'react-router-dom';
import NoImage from '../../../images/NoDoc.svg';
import { UploadContainer } from '../StyledComponents';
import { constructDirectoryJson, jsonToFormData } from '../../../utils/helper';
import showNotification from '../../../components/ShowNotification';
import { useModifyExternalUpload } from '../../../services/ProjectDoucment/hooks';
import { DocIcons } from '../DocumentIcons';
import { AttachmentItem } from '../../Schedule/componants/UploadForm';

function ExternalUpload({ isFolderUpload, setFolderUpload, parentId, refetch }) {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [fileList, setFileList] = useState([]);
  const [folderNames, setFolderNames] = useState([]);
  const { mutate, isLoading } = useModifyExternalUpload(parentId, id);

  const UploadProps = {
    name: 'file',
    multiple: true,
    directory: true,
    response: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const maxSize = 10; // Set your maximum file size limit in MB
      // if (file.size / 1024 / 1024 > maxSize) {
      //   showNotification({ type: 'error', message: `File must be smaller than ${maxSize}MB` });
      //   return false;
      // }
      const relativePathParts = file.webkitRelativePath.split('/');
      setFolderNames(relativePathParts[0]);
      const isDuplicate = fileList.some((existingFile) => existingFile.name === file.name);
      if (isDuplicate) {
        return showNotification({ type: 'error', message: `${file.name} is already in the list.` });
      }
      setFileList((prev) => [...prev, file]);
      return false;
    },
    fileList
  };

  const handleFileInputChange = (filesData) => {
    const files = Array.from(filesData);
    const directoryJson = constructDirectoryJson(files);
    console.log(directoryJson);
  };

  const onFinish = () => {
    const payload = {};
    const folders = [];
    if (fileList?.length) {
      fileList.forEach((file, i) => {
        folders.push({ file });
      });
      // }
      // if (fileList && fileList.length > 0) {
      //   fileList
      //     .filter((e) => e.originFileObj)
      //     .map((v, i) => {
      //       if (v.originFileObj) folders.push({ file: v.originFileObj });
      //     });
      const obj = {
        folder_name: folderNames,
        elements: folders
      };
      payload.elements = [obj];
      const formData = jsonToFormData(payload);
      mutate(
        { payload: formData, action: 'create' },
        {
          // eslint-disable-next-line consistent-return
          onSuccess: (res) => {
            if (res.status === 200 || res.status === 201) {
              return Promise.all([
                showNotification({
                  message: `Record Created Successfully`
                }),
                setFolderUpload(false),
                form.resetFields(),
                refetch()
              ]);
            }
          }
        }
      );
    } else {
      form.resetFields();
      showNotification({ type: 'error', message: 'Please upload a folder' });
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setFolderUpload(false);
  };
  return (
    <>
      <Modal
        title={
          <div className="d-flex align-items-center">
            {DocIcons.uploadBlack}
            <span
              className="font-weight-700"
              style={{ margin: '0 5px', fontSize: '20px', color: '#080808' }}>
              Upload Folder
            </span>
          </div>
        }
        open={isFolderUpload}
        onCancel={handleCancel}
        destroyOnClose
        okText="Upload"
        confirmLoading={isLoading}
        width={750}
        cancelText="Close"
        cancelButtonProps={{
          className: 'cancel-btn'
        }}
        okButtonProps={{
          disabled: isLoading,
          loading: isLoading,
          className: 'ant-btn btn-primary'
        }}
        onOk={form.submit}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off">
          <Row>
            <Col span={24}>
              <Form.Item name="files" label="">
                <UploadContainer>
                  <Upload.Dragger
                    className="drag-and-drop"
                    itemRender={(_originNode, file, list, action) => (
                      <AttachmentItem item={file} action={action} />
                    )}
                    {...UploadProps}
                    accept="image/*,.pdf,.doc,.docx">
                    <Row gutter={16} className="p-12 no-margin-lr flex-row item-center">
                      <Col span={11} style={{ height: 42 }} className="bg-lite-primary text-center">
                        <Button
                          className="upload-btn d-flex align-items-center font-weight-700"
                          icon={DocIcons.uploadDark}
                          size="large"
                          type="link">
                          <span style={{ marginLeft: 5 }}>Upload Folder</span>
                        </Button>
                      </Col>
                      <Col
                        span={2}
                        className="fc-grey fs-20 d-flex align-items-center"
                        style={{ justifyContent: 'center' }}>
                        Or
                      </Col>
                      <Col span={11} className="drag" style={{ height: 42 }}>
                        <Button
                          size="large"
                          className="w-100 bg-grey drag-and-drop-btn font-weight-700">
                          Drag File Here
                        </Button>
                      </Col>
                    </Row>
                  </Upload.Dragger>
                </UploadContainer>
                {fileList.length > 0 ? null : (
                  <>
                    <div className="d-flex align-items-center" style={{ padding: '5px 0' }}>
                      {DocIcons.clip} <span style={{ marginRight: 5 }}>Attachment file</span>
                    </div>
                    <Empty
                      style={{ paddingTop: '20px' }}
                      image={NoImage}
                      imageStyle={{
                        height: 60
                      }}
                      description={<span>No Attachment</span>}
                    />
                  </>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default ExternalUpload;
