import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_SERVICE } from '../CommonApi';

export const useGetAllNotificationData = (userId, filters) => {
  return useInfiniteQuery({
    queryKey: ['notification_logs', userId, filters],
    queryFn: async ({ pageParam = 1 }) => {
      const data = await API_SERVICE.get(`notification_logs`, {
        page: pageParam,
        per_page: 15,
        filters
      });
      return data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    cacheTime: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.pagination?.currentPage
        ? Number(lastPage.pagination.currentPage) + 1
        : undefined;
    }
  });
};

export const useUpdateAllMarkAsRead = () => {
  return useMutation({
    mutationFn: () => {
      return API_SERVICE.putRequest(`notification_logs/mark_as_read_all`);
    }
  });
};
export const useUpdateMarkAsRead = () => {
  return useMutation({
    mutationFn: ({ id }) => {
      return API_SERVICE.putRequest(`notification_logs/${id}/mark_as_read`);
    }
  });
};
export const useModifyNotificationSettings = (compId, id) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.putRequest(
        `companies/${compId}/user_notification_preferences/${id}`,
        payload
      );
    }
  });
};
export const useGetNotificationSettingsData = (compId, id) => {
  return useQuery({
    queryKey: [`notification-settings`, compId, id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${compId}/user_notification_preferences/${id}`);
      return data;
    },
    enabled: !!compId && !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetNotificationCount = () => {
  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueryData(['notification-count']);
  return useQuery({
    queryKey: [`notification-count`],
    queryFn: async () => {
      const data = await API_SERVICE.get(`notification_logs/unread_notice_count`);
      if (typeof cacheData !== 'undefined') {
        const oldData = cacheData?.data?.data;
        if (oldData?.unread_count !== data?.data?.data?.unread_count) {
          data.data.data.isNewCount = true;
        } else {
          data.data.data.isNewCount = false;
        }
      }
      return data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 60000
  });
};
