import React from 'react';
import { DocIcons } from './DocumentIcons';
import { Wrapper } from '../DailyLogsNew/components/NoData';

const DeleteSharedLink = () => {
  return (
    <>
      <Wrapper autoWidth>
        {DocIcons.shared_delete_link}
        <>
          <h2 style={{ fontWeight: 700, fontSize: '20px' }}>
            This shared folder link has been deleted or this link has expired.
          </h2>
          <p
            style={{
              color: '#909090'
            }}>
            Please request a new link or contact your admin
          </p>
        </>
      </Wrapper>
    </>
  );
};

export default DeleteSharedLink;
