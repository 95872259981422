/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { Button, Modal, Col, Form, Row, Upload, Empty } from 'antd';
import { useParams } from 'react-router-dom';
import NoImage from '../../../images/NoDoc.svg';
import { UploadContainer } from '../StyledComponents';
import showNotification from '../../../components/ShowNotification';
import { useModifyExternalFileUpload } from '../../../services/ProjectDoucment/hooks';
import { AttachmentItem } from '../../Schedule/componants/UploadForm';
import { DocIcons } from '../DocumentIcons';

const ExternalFileUpload = ({ isFileUpload, setFileUpload, refetch, parentId }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [fileList, setFileList] = useState([]);
  const { mutate, isLoading } = useModifyExternalFileUpload(id, parentId);

  const UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const maxSize = 10; // Set your maximum file size limit in MB
      // if (file.size / 1024 / 1024 > maxSize) {
      //   showNotification({ type: 'error', message: `File must be smaller than ${maxSize}MB` });
      //   return false;
      // }
      const isDuplicate = fileList.some((existingFile) => existingFile.name === file.name);
      if (isDuplicate) {
        return showNotification({ type: 'error', message: `${file.name} is already in the list.` });
      }
      setFileList((prev) => [...prev, file]);
      return false;
    },
    fileList
  };

  const onFinish = () => {
    const formData = new FormData();
    if (fileList?.length) {
      fileList.forEach((file, i) => {
        formData.append(`files[${i}]`, file);
      });

      mutate(
        { payload: formData, action: 'create' },
        {
          onSuccess: (res) => {
            if (res.status === 200 || res.status === 201) {
              Promise.all([
                showNotification({
                  message: `Record Created Successfully`
                }),
                form.resetFields(),
                setFileUpload(false),
                refetch()
              ]);
            }
          }
        }
      );
    } else {
      form.resetFields();
      showNotification({ type: 'error', message: 'Please upload a file' });
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setFileUpload(false);
  };

  return (
    <>
      <Modal
        title={
          <div className="d-flex align-items-center">
            {DocIcons.uploadBlack}
            <span
              className="font-weight-700"
              style={{ margin: '0 5px', fontSize: '20px', color: '#080808' }}>
              Upload File
            </span>
          </div>
        }
        open={isFileUpload}
        onCancel={handleCancel}
        okText="Upload"
        confirmLoading={isLoading}
        destroyOnClose
        width={750}
        cancelText="Close"
        cancelButtonProps={{
          className: 'cancel-btn'
        }}
        okButtonProps={{
          disabled: isLoading,
          loading: isLoading,
          className: 'ant-btn btn-primary'
        }}
        onOk={form.submit}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off">
          <Row>
            <Col span={24}>
              <Form.Item name="files" label="">
                <UploadContainer>
                  <Upload.Dragger
                    className="drag-and-drop"
                    itemRender={(_originNode, file, list, action) => (
                      <AttachmentItem item={file} action={action} />
                    )}
                    {...UploadProps}
                    accept="image/*,.pdf,.doc,.docx">
                    <Row gutter={16} className="p-12 no-margin-lr flex-row item-center">
                      <Col span={11} style={{ height: 42 }} className="bg-lite-primary text-center">
                        <Button
                          className="upload-btn d-flex align-items-center font-weight-700"
                          icon={DocIcons.uploadDark}
                          size="large"
                          type="link">
                          <span style={{ marginLeft: 5 }}>Upload File</span>
                        </Button>
                      </Col>
                      <Col
                        span={2}
                        className="fc-grey fs-20 d-flex align-items-center"
                        style={{ justifyContent: 'center' }}>
                        Or
                      </Col>
                      <Col span={11} className="drag" style={{ height: 42 }}>
                        <Button
                          size="large"
                          className="w-100 bg-grey drag-and-drop-btn font-weight-700">
                          Drag File Here
                        </Button>
                      </Col>
                    </Row>
                  </Upload.Dragger>
                </UploadContainer>
                {fileList.length > 0 ? null : (
                  <>
                    <div className="d-flex align-items-center" style={{ padding: '5px 0' }}>
                      {DocIcons.clip} <span style={{ marginRight: 5 }}>Attachment file</span>
                    </div>
                    <Empty
                      style={{ paddingTop: '20px' }}
                      image={NoImage}
                      imageStyle={{
                        height: 60
                      }}
                      description={<span>No Attachment</span>}
                    />
                  </>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ExternalFileUpload;
