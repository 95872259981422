/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import { Button, Dropdown, Image, Input, Modal, Row, Select, Space, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DocIcons } from './DocumentIcons';
import showNotification from '../../components/ShowNotification';
import {
  useModifyDocuments,
  useModifyFolder,
  useModifyMarkAsPrivatePublic
} from '../../services/ProjectDoucment/hooks';
import { setFolderData } from '../../features/ProjectDocument/ProjectDocument';
import { ReactComponent as Filter } from '../../fonts/filter.svg';
import { fallbackImage } from '../DailyLogsNew/components/Preview/commonStyles';
import { ActionItem } from '../Schedule/componants/List';
import ShareLinkForm from './components/ShareLinkForm';
import EditDocument from './EditDocument';
import { API_SERVICE } from '../../services/CommonApi';

const { Search } = Input;

export const ImageComponent = ({ record, isLink = false }) => {
  const [isView, setView] = useState(false);
  return (
    <>
      {isLink ? (
        <a href="#" onClick={() => setView(true)} className="folder-title">
          {record?.name}
        </a>
      ) : (
        <Button
          className="btn-light font-weight-700"
          type="primary"
          // size="small"
          style={{ fontSize: '14px', height: '32px' }}
          onClick={() => setView(true)}>
          Open
        </Button>
      )}
      <Image
        className="attach"
        style={{
          display: 'none'
        }}
        src={record?.preview_url}
        fallback={fallbackImage}
        preview={{
          onVisibleChange: (value) => {
            setView(value);
          },
          visible: isView,
          src: record?.download_url
        }}
      />
    </>
  );
};

const List = ({
  selectId,
  folderRefetch,
  refetch,
  filterState,
  sortState,
  setSortState,
  setFilterState,
  setShowFolder,
  folderId,
  onClickFolder,
  handleOnFilterChange,
  list = [],
  baseData = {}
}) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, contextHolderModal] = Modal.useModal();
  const [shareLink, setShareLink] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [docModel, setDocModel] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const { submitted_users_list = [], updated_users_list = [] } = baseData;
  const { mutate: deleteDocMutate } = useModifyDocuments(projectId);
  const { mutate: deleteFolderMutate } = useModifyFolder(projectId);
  const { mutate: privatePublicMutute } = useModifyMarkAsPrivatePublic(projectId);
  const submittedUserOption = [
    { label: 'All', value: 'All', active: true },
    ...submitted_users_list.map((e) => ({ ...e, label: e.key, value: e.value }))
  ];
  const updatedUsersOption = [
    { label: 'All', value: 'All', active: true },
    ...updated_users_list.map((e) => ({ ...e, label: e.key, value: e.value }))
  ];
  const onConfirm = (values) => {
    const mutationFn = values?.document_type ? deleteDocMutate : deleteFolderMutate;
    mutationFn(
      { payload: { id: values?.id }, action: 'delete' },
      {
        onSuccess: (res) => {
          if (res.status === 200 || res.status === 201) {
            dispatch(setFolderData({}));
            showNotification({ message: 'Record Deleted Successfully' });
            selectId ? folderRefetch() : refetch();
          }
        }
      }
    );
  };
  const onChangePrivatePublic = (record) => {
    privatePublicMutute(
      {
        payload: { is_private: !record?.is_private },
        id: record?.id,
        type: record?.document_type ? 'documents' : 'folders'
      },
      {
        onSuccess: (res) => {
          if (res.status === 200 || res.status === 201) {
            showNotification({
              message: `Record Updated Successfully`
            });
            folderId ? folderRefetch() : refetch();
          }
        }
      }
    );
  };

  const onDownloadFolder = (record) => {
    if (record.document_type) {
      API_SERVICE.downloadDocument(record.download_url, record.name);
    } else {
      setDownloading(true);
      API_SERVICE.downloadZip(
        `projects/${projectId}/${record?.document_type ? 'documents' : 'folders'}/${
          record?.id
        }/download_as_zip`,
        record.name,
        setDownloading
      );
    }
  };

  const onDeleteData = (values) => {
    dispatch(setFolderData(values));
    modal.confirm({
      className: `${values?.shared ? 'custom-confirm-modal' : ''}`,
      title: 'Delete Confirmation',
      icon: values?.shared ? DocIcons.confirm_delete : <ExclamationCircleOutlined />,
      content: values?.shared
        ? `Do you want to delete “${values.name}” ${
            values?.document_type ? '' : 'Folder'
          } ? This was shared with external users and their access will be lost upon deletion.`
        : `Do you want to delete ${values.name} ${values?.document_type ? '' : 'Folder'}?`,
      okText: 'Yes, Delete',
      okButtonProps: {
        style: { height: '40px', fontWeight: '700' }
      },
      onOk: () => {
        onConfirm(values);
      },
      cancelButtonProps: {
        className: 'cancel-btn'
      },
      cancelText: 'Cancel'
    });
  };
  const rowOptions = (item, record) => {
    const options = [];
    item.allow_edit &&
      options.push({
        label: (
          <ActionItem
            onClick={(e) => {
              e.preventDefault();
              record?.document_type ? onEditDocument(record) : onEditData(record);
            }}>
            {DocIcons.rename}
            Rename
          </ActionItem>
        ),
        key: '1'
      });
    item.allow_view &&
      options.push({
        label: (
          <ActionItem
            // eslint-disable-next-line consistent-return
            onClick={(e) => {
              e.preventDefault();
              onDownloadFolder(record);
            }}>
            {DocIcons.download}
            Download
          </ActionItem>
        ),
        key: '2'
      });
    item?.allow_to_share_link &&
      options.push({
        label: (
          <ActionItem
            onClick={(e) => {
              e.preventDefault();
              setShareLink({
                type: !record?.document_type ? 'folders' : 'documents',
                id: record.id
              });
            }}>
            {DocIcons.share}
            Share Link
          </ActionItem>
        ),
        key: '3'
      });

    item?.allow_to_mark_as_public_or_private &&
      options.push({
        label: (
          <ActionItem
            onClick={(e) => {
              onChangePrivatePublic(record);
              e.preventDefault();
            }}>
            {DocIcons[record?.is_private ? 'public' : 'private']}
            {`Mark as ${record?.is_private ? 'public' : 'private'}`}
          </ActionItem>
        ),
        key: '4'
      });

    item.allow_delete &&
      options.push({
        label: (
          <ActionItem
            style={{ color: '#D92929' }}
            onClick={(e) => {
              e.preventDefault();
              onDeleteData(record);
            }}>
            {DocIcons.delete}
            Delete
          </ActionItem>
        ),
        key: '5'
      });
    return options;
  };

  const columns = [
    {
      title: (
        <>
          <Row align="middle" justify="space-between" wrap={false}>
            <div>Name</div>
            <Filter />
          </Row>
          <Row>
            <Search
              id="name"
              value={filterState?.name}
              style={{ width: '100%' }}
              onSearch={(value) => handleOnFilterChange(value, 'name')}
              placeholder="Search"
              allowClear
              onClick={(e) => e.stopPropagation()}
            />
          </Row>
        </>
      ),
      filteredValue: filterState?.name,
      dataIndex: 'name',
      // ellipsis: true,
      sortOrder: sortState.attribute !== 'name' ? 'asc' : sortState.direction,
      onHeaderCell: (col) => ({
        onClick: () => {
          setSortState((pre) => ({
            ...pre,
            attribute: 'name',
            direction: pre.attribute !== 'name' ? 'asc' : col.sortOrder === 'asc' ? 'desc' : 'asc'
          }));
        }
      }),
      sortDirections: ['asc', 'desc', 'asc'],
      key: 'name',
      render: (name, record) => (
        <div
          className={`${record?.document_type ? '' : 'ps-auto'} d-flex align-items-center`}
          onClick={() =>
            record?.document_type ? null : record.allow_view && onClickFolder(record)
          }>
          {record?.document_type ? <>{DocIcons[record?.document_type]}</> : DocIcons.folder}{' '}
          <div className="font-weight-500 folder-title td-ellipse">
            {record?.document_type ? (
              record?.name.endsWith('.pdf') ? (
                <a
                  style={{ color: '#080808' }}
                  target="_blank"
                  href={record.download_url}
                  rel="noreferrer">
                  {name}
                </a>
              ) : (
                <>
                  <ImageComponent record={record} isLink />
                </>
              )
            ) : name.length > 25 ? (
              <Tooltip placement="topLeft" title={name}>
                {name}
              </Tooltip>
            ) : (
              name
            )}
          </div>
          {record?.is_private ? DocIcons.lock : null}
        </div>
      )
    },
    {
      title: (
        <>
          <Row align="middle" justify="space-between" wrap={false}>
            <div>Created By</div>
            <Filter />
          </Row>
          <Row>
            <Select
              id="created_by_id"
              showSearch
              optionFilterProp="label"
              defaultValue="All"
              value={filterState.created_by_id || 'All'}
              onChange={(e) => handleOnFilterChange(e, 'created_by_id')}
              style={{ width: '100%' }}
              onClick={(e) => e.stopPropagation()}
              options={submittedUserOption}
            />
          </Row>
        </>
      ),
      filteredValue: filterState?.created_by_id,
      dataIndex: 'created_by_id',
      sortOrder: sortState.attribute !== 'created_by_id' ? 'asc' : sortState.direction,
      onHeaderCell: (col) => ({
        onClick: () => {
          setSortState((pre) => ({
            ...pre,
            attribute: 'created_by_id',
            direction:
              pre.attribute !== 'created_by_id' ? 'asc' : col.sortOrder === 'asc' ? 'desc' : 'asc'
          }));
        }
      }),
      sortDirections: ['asc', 'desc', 'asc'],
      key: 'created_by_id',
      render: (value, data) => (
        <>
          <p className="document">
            <span className="title">{data.created_by}</span>
          </p>
          <p className="document">
            <span>{data?.created_at}</span>
          </p>
        </>
      )
    },
    {
      title: (
        <>
          <Row align="middle" justify="space-between" wrap={false}>
            <div>Updated By</div>
            <Filter />
          </Row>
          <Row>
            <Select
              id="updated_by_id"
              showSearch
              optionFilterProp="label"
              defaultValue="All"
              value={filterState.updated_by_id || 'All'}
              onChange={(e) => handleOnFilterChange(e, 'updated_by_id')}
              style={{ width: '100%' }}
              onClick={(e) => e.stopPropagation()}
              options={updatedUsersOption}
            />
          </Row>
        </>
      ),
      dataIndex: 'updated_by_id',
      filteredValue: filterState?.updated_by_id,
      sortOrder: sortState.attribute !== 'updated_by_id' ? 'asc' : sortState.direction,
      onHeaderCell: (col) => ({
        onClick: () => {
          setSortState((pre) => ({
            ...pre,
            attribute: 'updated_by_id',
            direction:
              pre.attribute !== 'updated_by_id' ? 'asc' : col.sortOrder === 'asc' ? 'desc' : 'asc'
          }));
        }
      }),
      sortDirections: ['asc', 'desc', 'asc'],
      key: 'updated_by',
      render: (value, data) => (
        <>
          <p className="document">
            <span className="title"> {data?.updated_by || '-'}</span>
          </p>
          <p className="document">
            <span>{data?.updated_at}</span>
          </p>
        </>
      )
    },
    {
      title: 'Action',
      width: '180px',
      key: 'action',
      render: (_, record) => (
        <>
          <Space align="end">
            <div className="">
              <Button
                className="font-weight-700"
                onClick={() =>
                  navigate(
                    `/projects-document/${projectId}/view/${record.id}?type=${
                      record?.document_type ? 'documents' : 'folders'
                    }`
                  )
                }
                style={{
                  fontSize: '14px',
                  background: '#f6f6f6',
                  color: '#5C5C5C',
                  border: 'none',
                  height: '32px',
                  padding: '8px 16px'
                }}>
                View Detail
              </Button>
              {/* <img className="doc-image" src={docInfo} alt="" /> */}
              {/* </Popover> */}
            </div>
            <div>
              {record?.document_type && record.allow_view ? (
                record?.name.endsWith('.pdf') ? (
                  <a target="_blank" href={record.download_url} rel="noreferrer">
                    <Button
                      className="btn-light font-weight-700"
                      type="primary"
                      style={{ fontSize: '14px', height: '32px' }}>
                      Open
                    </Button>
                  </a>
                ) : (
                  <>
                    <ImageComponent record={record} />
                  </>
                )
              ) : (
                <>
                  {record.allow_view && (
                    <Button
                      className="btn-light font-weight-700"
                      type="primary"
                      onClick={() => onClickFolder(record)}
                      style={{ fontSize: '14px', height: '32px' }}>
                      Open
                    </Button>
                  )}
                </>
              )}
            </div>
            <Dropdown
              overlayClassName="more-dropdown-wrap"
              placement="bottomRight"
              menu={{
                items: rowOptions(_, record)
              }}>
              <Button
                className="d-flex align-items-center"
                style={{
                  borderRadius: '4px',
                  background: '#ECECEC',
                  border: 'none',
                  justifyContent: 'space-evenly'
                }}
                icon={DocIcons.action}
              />
            </Dropdown>
          </Space>
        </>
      )
    }
  ];

  const handleOnChange = (pagination) => {
    setFilterState((prev) => ({
      ...prev,
      page: pagination.current,
      per_page: pagination.pageSize || 10
    }));
  };

  const onEditData = (values) => {
    dispatch(setFolderData(values));
    setShowFolder(true);
  };
  const onEditDocument = (values) => {
    setDocumentData(values);
    setDocModel(true);
  };

  // if (isLoading) {
  //   return <NoData onlySpinner />;
  // }

  return (
    <>
      {contextHolderModal}
      {!!shareLink?.id && (
        <Modal
          title={<span>{DocIcons.share} Share Link</span>}
          open={!!shareLink?.id}
          width="60%"
          footer={null}
          destroyOnClose
          closable
          closeIcon={<CloseOutlined onClick={() => setShareLink(false)} />}>
          <ShareLinkForm details={shareLink} setShareLink={setShareLink} />
        </Modal>
      )}
      {docModel && (
        <EditDocument
          show={docModel}
          setIsModalOpen={() => setDocModel(false)}
          initialValues={documentData}
          folderId={folderId}
          refetch={refetch}
          folderRefetch={folderRefetch}
        />
      )}
      <Table
        columns={columns}
        dataSource={list || []}
        loading={isDownloading}
        onChange={handleOnChange}
        pagination={{
          current: filterState.page,
          pageSize: filterState?.per_page,
          total: list.length,
          showSizeChanger: true
        }}
      />
    </>
  );
};

export default List;
