import React, { useState, useRef } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import HeroVid from '../../../images/hero-vid-hd.mov';
import HeroVid2 from '../../../images/stevepl.mp4';

const subTexts = [
  'Empowering your projects with precision and care.',
  'Innovating solutions, delivering results.',
  'Transform your workflow with cutting-edge technology.',
  'Efficiency and quality in every task.',
  'Your success is our commitment.',
  'Stay ahead of the curve with ProjectLoop.',
  'Harness the full potential of your projects.',
  'Where technology meets productivity.',
  'Create. Innovate. Succeed.',
  'Leading the way in project management.'
];

export default function HomePageHeroSection() {
  const randomSubtext = subTexts[Math.floor(Math.random() * subTexts.length)];
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (videoRef?.current?.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="hero-section-container">
      <video id="vid" className="hero-video" src={HeroVid} type="video/mov" autoPlay muted loop />
      <div
        className="text-content"
        style={{
          marginLeft: '-50px',
          paddingLeft: '260px',
          width: '100%',
          background: 'linear-gradient(to right, rgba(0, 0, 0, 0.6), transparent)',
          padding: '55px'
        }}>
        <h2 className="hero-text">Welcome to ProjectLoop</h2>
        <p className="hero-subtext2">{randomSubtext}</p>
        <div className="hero-content">{/* Additional text content could go here */}</div>
        <div className="hero-button-row">
          <a href="#">
            <Button type="primary" size="large" shape="round" className="book-demo-btn">
              Book A Demo
            </Button>
          </a>
          <Link to="/features">
            <Button className="all-features-btn">See all features</Button>
          </Link>
        </div>
      </div>
      <div className="text-content">
        {/* <div className="right-content">
                    <video className="side-video" src={HeroVid2} type="video/mov" autoPlay="false" muted loop />
                </div> */}
        <div className="video-container" onClick={togglePlay}>
          {/* <video ref={videoRef} className="side-video" src={HeroVid2} type="video/mov" loop controls width="200" height="100" /> */}
        </div>
      </div>
    </div>
  );
}

// import React from 'react';
// import { Button } from 'antd';
// import { Link } from 'react-router-dom';
// import HeroVid from '../../../images/hero-vid.mp4'

// import "./styles.scss";

// export default function HomePageHeroSection() {
//     return (
//         <div className="hero-section-container">
//             <div className="text-content">
//                 <h2 className="hero-text">Welcome to ProjectLoop</h2>
//                 <p className="hero-subtext2">Your Cutting-Edge Construction Management Platform.</p>
//                 <div className="hero-content">
//                     <p className="hero-content-text">
//                         Explore a revolutionary approach to construction management with ProjectLoop's advanced software, blending innovation with decades of expertise in the field. Embark on a journey into a new era of construction efficiency with our state-of-the-art platform and seamlessly integrated ecosystem.
//                         <br />
//                         <br />
//                         With over many decades of experience in advanced construction management systems, we've pioneered a solution to transform project oversight.
//                         <br />
//                         <br />
//                         At ProjectLoop, we take pride in simplifying construction processes, providing a comprehensive software suite supported by a robust ecosystem. From streamlined project and user management to real-time monitoring, our tailored solutions are crafted to elevate efficiency standards across the construction industry.
//                     </p>
//                 </div>
//                 <div className="hero-button-row">
//                     <a href="#">
//                         <Button type="primary" size='large' shape='round' className="book-demo-btn">Book A Demo</Button>
//                     </a>
//                     <Link to="/features">
//                         <Button className="all-features-btn">See all features</Button>
//                     </Link>
//                 </div>
//             </div>
//             <div className="image-content">
//                 <video id='vid' src={HeroVid} type="video/mp4" autoPlay muted loop />
//             </div>
//         </div>
//     )
// }
